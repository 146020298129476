import {
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Field, FieldProps, Form, Formik } from "formik";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import {
    addNewCourseSeries,
    addUsersToSeries,
    removeUsersFromSeries,
    requestCourseSeries,
    requestUsersInAndOutOfSeries,
    selectCourseSeries,
    selectCourseSeriesUserIsLoading,
    selectCurrentUser,
    selectUserIsChangedBetweenCourseSeries,
    selectUsersInAndOutOfSeries,
    useAppDispatch,
    useAppSelector,
} from "store";
import { UserModel } from "store/models/user/UserModel";
import { CourseSeries } from "store/models/vocationalTraining/CourseSeries";
import "./CourseSeriesView.scss";
import * as yup from "yup";
import { AnnexesDownload } from "./AnnexesDownload";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";

export const CourseSeriesView: FC = () => {
    const dispatch = useAppDispatch();

    const currentUser = useAppSelector(selectCurrentUser);

    const courseSeries = useAppSelector(selectCourseSeries);
    const [courseName, setCourseName] = useState("");

    useEffect(() => {
        dispatch(requestCourseSeries(currentUser?.jwtToken));
    }, []);

    const seriesSelectItems = useMemo(() => {
        return courseSeries.map((series: CourseSeries) => ({
            label: series.seriesName,
            value: series.seriesId,
        }));
    }, [courseSeries]);

    const [selectedSeries, setSelectedSeries] = useState<
        number | null | undefined
    >();


    useEffect(() => {
        dispatch(
            requestUsersInAndOutOfSeries({
                seriesId: selectedSeries,
                token: currentUser?.jwtToken,
            })
        );
    }, [selectedSeries]);

    const usersInAndOutOfSeries = useAppSelector(selectUsersInAndOutOfSeries);

    const [usersGoingIn, setUsersGoingIn] = useState<UserModel[]>(
        new Array<UserModel>()
    );
    const [usersGoingOut, setUsersGoingOut] = useState<UserModel[]>(
        new Array<UserModel>()
    );
    const courseSeriesUserIsLoading = useAppSelector(selectCourseSeriesUserIsLoading);
    const userIsChangedBetweenCourseSeries = useAppSelector(selectUserIsChangedBetweenCourseSeries);

    const handleToggleLeft = (user: UserModel) => () => {
        let goingIn = usersGoingIn.indexOf(user) !== -1;
        if (goingIn) {
            setUsersGoingIn(
                usersGoingIn.filter((item) => item.userId !== user.userId)
            );
        } else {
            setUsersGoingIn([...usersGoingIn, user]);
        }
    };
    const handleToggleRight = (user: UserModel) => () => {
        let goingOut = usersGoingOut.indexOf(user) !== -1;
        if (goingOut) {
            setUsersGoingOut(
                usersGoingOut.filter((item) => item.userId !== user.userId)
            );
        } else {
            setUsersGoingOut([...usersGoingOut, user]);
        }
    };

    const handleAllRight = () => {
        dispatch(
            addUsersToSeries({
                requestData: {
                    seriesId: selectedSeries,
                    userIds: usersInAndOutOfSeries.usersOutOfSeries.map(
                        (user) => user.userId
                    ),
                },
                token: currentUser?.jwtToken,
            })
        ).then(() =>
            dispatch(
                requestUsersInAndOutOfSeries({
                    seriesId: selectedSeries,
                    token: currentUser?.jwtToken,
                })
            ));;
        setUsersGoingIn(new Array<UserModel>());
        setUsersGoingOut(new Array<UserModel>());
    };

    const handleCheckedRight = () => {
        dispatch(
            addUsersToSeries({
                requestData: {
                    seriesId: selectedSeries,
                    userIds: usersGoingIn.map((user) => user.userId),
                },
                token: currentUser?.jwtToken,
            })
        ).then(() =>
            dispatch(
                requestUsersInAndOutOfSeries({
                    seriesId: selectedSeries,
                    token: currentUser?.jwtToken,
                })
            ));;
        setUsersGoingIn(new Array<UserModel>());
        setUsersGoingOut(new Array<UserModel>());
    };

    const handleCheckedLeft = () => {
        dispatch(
            removeUsersFromSeries({
                requestData: {
                    seriesId: selectedSeries,
                    userIds: usersGoingOut.map((user) => user.userId),
                },
                token: currentUser?.jwtToken,
            })
        ).then(() =>
            dispatch(
                requestUsersInAndOutOfSeries({
                    seriesId: selectedSeries,
                    token: currentUser?.jwtToken,
                })
            ));;
        setUsersGoingIn(new Array<UserModel>());
        setUsersGoingOut(new Array<UserModel>());
    };

    const handleAllLeft = () => {
        dispatch(
            removeUsersFromSeries({
                requestData: {
                    seriesId: selectedSeries,
                    userIds: usersInAndOutOfSeries.usersOfSeries.map(
                        (user) => user.userId
                    ),
                },
                token: currentUser?.jwtToken,
            })
        ).then(() =>
            dispatch(
                requestUsersInAndOutOfSeries({
                    seriesId: selectedSeries,
                    token: currentUser?.jwtToken,
                })
            ));
        setUsersGoingIn(new Array<UserModel>());
        setUsersGoingOut(new Array<UserModel>());
    };

    const isChecked = (user: UserModel) => {
        return (
            usersGoingIn.indexOf(user) !== -1 || usersGoingOut.indexOf(user) !== -1
        );
    };

    const handleSelectSeries = (e: ChangeEvent<HTMLInputElement>) => {
        let val: string | null | undefined = e.target.value;
        if (val !== null || val !== undefined) {
            setSelectedSeries(parseInt(val, 10));
            selectCourseSeriesNameFromId(parseInt(val, 10));
            setUsersGoingIn(new Array<UserModel>());
            setUsersGoingOut(new Array<UserModel>());
        }
    };

    const customList = (users: any[], handleToggle: Function) => (

        <Paper
            elevation={4}
            sx={{
                width: "100%",
                height: "54vh",
                overflow: "auto",
            }}
        >
            {courseSeriesUserIsLoading || userIsChangedBetweenCourseSeries ? <DataLoadingComponent />
                :
                <List dense={true} component="div" role="list">
                    {users.map((user: any, index: number) => {
                        const labelId = `transfer-list-item-${index}-label`;

                        return (
                            <ListItem
                                key={index}
                                role="listitem"
                                button
                                onClick={handleToggle(user)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={isChecked(user)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    primary={`${user.lastName} ${user.firstName}`}
                                />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>}
        </Paper>
    );

    const [displayDialog, setDisplayDialog] = useState(false);

    const validationSchema = yup.object({
        name: yup.string().required("Nume invalid!"),
    });

    const selectCourseSeriesNameFromId = (id: number) => {
        seriesSelectItems.forEach(element => {
            if (element.value === id) {
                setCourseName(element.label + ":");
            }
        });
    }

    return (
        <Container
            id="course-series-view"
            maxWidth="md"
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingY: 1,
                paddingX: 1
            }}
            disableGutters
        >
            <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ paddingBottom: 1 }}
            >
                <Grid sx={{ paddingBottom: 1 }}>
                    <AnnexesDownload selectedSeries={selectedSeries} />
                </Grid>
                <Grid sx={{ paddingBottom: 1, display: 'inline-flex' }}>
                    <TextField
                        select
                        size="small"
                        name="series"
                        label="Serie curs"
                        value={selectedSeries || ""}
                        onChange={handleSelectSeries}
                        sx={{ minWidth: "120px" }}
                    >
                        {seriesSelectItems.map((item: any) => (
                            <MenuItem key={item.value} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Tooltip title="Adaugă o nouă serie">
                        <Button
                            variant="contained"
                            onClick={() => setDisplayDialog(true)}
                            sx={{ marginLeft: 1 }}
                        >
                            adaugă serie
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid
                item
                container
                flexGrow={1}
                spacing={2}
                justifyContent="space-evenly"
            >
                <Grid item sx={{ display: "flex", alignItems: "center" }} xs={12} sm={5} md={5} lg={5}>
                    <Grid container sx={{ display: "row", rowGap: 1 }} >
                        <Grid sx={{ width: "100%" }}><Typography sx={{ fontSize: { xs: "18px", md: "22px" } }} align="center" >Alte grupe/Nealocați:</Typography></Grid>
                        {customList(usersInAndOutOfSeries.usersOutOfSeries, handleToggleLeft)}
                    </Grid>

                </Grid>
                <Grid item alignSelf="center" xs={12} sm={2} md={2} lg={2}>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        sx={{
                            visibility: selectedSeries ? "visible" : "hidden",
                        }}
                    >
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllRight}
                            disabled={(usersInAndOutOfSeries.usersOutOfSeries.length === 0) || (courseSeriesUserIsLoading || userIsChangedBetweenCourseSeries)}
                            aria-label="move all right"
                        >
                            ≫
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={usersGoingIn.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={usersGoingOut.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllLeft}
                            disabled={(usersInAndOutOfSeries.usersOfSeries.length === 0) || (courseSeriesUserIsLoading || userIsChangedBetweenCourseSeries)}
                            aria-label="move all left"
                        >
                            ≪
                        </Button>
                    </Grid>
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }} xs={12} sm={5} md={5} lg={5}>
                    <Grid container sx={{ display: "row", rowGap: 1 }}>
                        <Grid sx={{ width: "100%" }}><Typography sx={{ fontSize: { xs: "18px", md: "22px" } }} align="center">{courseName || "Nume Grupă:"}</Typography></Grid>
                        {customList(usersInAndOutOfSeries.usersOfSeries, handleToggleRight)}

                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={displayDialog}
                onClose={() => setDisplayDialog(false)}
                disableScrollLock
            >
                <DialogContent>
                    <Formik
                        onSubmit={(values) => {
                            dispatch(
                                addNewCourseSeries({
                                    name: values.name,
                                    token: currentUser?.jwtToken,
                                })
                            )
                                .unwrap()
                                .then(() => {
                                    setDisplayDialog(false);
                                    dispatch(requestCourseSeries(currentUser?.jwtToken));
                                });
                        }}
                        initialValues={{
                            name: "",
                        }}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            <Grid
                                container
                                alignItems="center"
                                alignContent="center"
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    <Field name="name">
                                        {({ field, meta }: FieldProps) => (
                                            <TextField
                                                label="Nume serie curs *"
                                                name={field.name}
                                                value={field.value}
                                                fullWidth
                                                onChange={field.onChange}
                                                error={meta.touched && Boolean(meta.error)}
                                                helperText={meta.touched && meta.error}
                                                variant="outlined"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={() => setDisplayDialog(true)}
                                        type="submit"
                                    >
                                        adaugă serie
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </DialogContent>
            </Dialog>
        </Container>
    );
};
