import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { selectIsLoading, useAppSelector } from "store";

export const OverlayLoader: FC = () => {
  const isLoading = useAppSelector(selectIsLoading);
  if (isLoading) {
    return (
      <Backdrop
        open={true}
        sx={{
          color: "#fff",
          zIndex: 999999,
        }
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" color="primary.text">
            Se încarcă
          </Typography>
        </Box>
      </Backdrop >
    )
  } else {
    return (
      <></>
    )
  }

}