import { RootState } from "store";
import { GenerateRankingModel, GrantEvaluationModel, ScheduleModel, ScoreBoardUserModel } from "store/models";
import { SortModel } from "store/models/common";

export const selectPhase2TemporarySortModel = (state: RootState): SortModel => state.grantEvaluation.phase2TemporarySortModel;
export const selectPhase2FinalSortModel = (state: RootState): SortModel => state.grantEvaluation.phase2FinalSortModel;
export const selectUserPhase2TemporaryEvaluationGrids = (state: RootState): GrantEvaluationModel[] => state.grantEvaluation.userPhase2TemporaryEvaluationGrids;
export const selectUserPhase2TemporaryEvaluationGridsCount = (state: RootState): number => state.grantEvaluation.userPhase2TemporaryEvaluationGridsCount;
export const selectHasAppeal = (state: RootState): boolean => state.grantEvaluation.hasAppeal;
export const selectUserPhase2FinalEvaluationGrids = (state: RootState): GrantEvaluationModel[] => state.grantEvaluation.userPhase2FinalEvaluationGrids;
export const selectUserPhase2FinalEvaluationGridsCount = (state: RootState): number => state.grantEvaluation.userPhase2FinalEvaluationGridsCount;
export const selectScoresPhase2Intermediary = (state: RootState): ScoreBoardUserModel[] => state.grantEvaluation.scoresPhase2Intermediary;
export const selectScoresPhase2IntermediaryCount = (state: RootState): number => state.grantEvaluation.scoresPhase2IntermediaryCount;
export const selectScoresPhase2IntermediaryIsLoading = (state: RootState): boolean => state.grantEvaluation.scoresPhase2IntermediaryIsLoading;
export const selectScoresPhase2IntermediarySortModel = (state: RootState): SortModel => state.grantEvaluation.scoresPhase2IntermediarySortModel;
export const selectUserPhase2TemporaryEvaluationGridsListIsLoading = (state: RootState): boolean => state.grantEvaluation.userPhase2TemporaryEvaluationGridsListIsLoading;
export const selectUserPhase2TemporaryEvaluationGridsFileIsDownloading = (state: RootState): boolean => state.grantEvaluation.userPhase2TemporaryEvaluationGridsFileIsDownloading;
export const selectScoresPhase2Final = (state: RootState): ScoreBoardUserModel[] => state.grantEvaluation.scoresPhase2Final;
export const selectScoresPhase2FinalCount = (state: RootState): number => state.grantEvaluation.scoresPhase2FinalCount;
export const selectScoresPhase2FinalIsLoading = (state: RootState): boolean => state.grantEvaluation.scoresPhase2FinalIsLoading;
export const selectScoresPhase2FinalSortModel = (state: RootState): SortModel => state.grantEvaluation.scoresPhase2FinalSortModel;
export const selectEvaluationTemplatePhase2IsDownloading = (state: RootState): boolean => state.grantEvaluation.evaluationTemplatePhase2IsDownloading;

export const selectPhase3FinalSortModel = (state: RootState): SortModel => state.grantEvaluation.phase3FinalSortModel;
export const selectUserPhase3FinalEvaluationGrids = (state: RootState): GrantEvaluationModel[] => state.grantEvaluation.userPhase3FinalEvaluationGrids;
export const selectUserPhase3FinalEvaluationGridsCount = (state: RootState): number => state.grantEvaluation.userPhase3FinalEvaluationGridsCount;
export const selectScoresPhase3Final = (state: RootState): ScoreBoardUserModel[] => state.grantEvaluation.scoresPhase3Final;
export const selectScoresPhase3FinalCount = (state: RootState): number => state.grantEvaluation.scoresPhase3FinalCount;
export const selectScoresPhase3FinalIsLoading = (state: RootState): boolean => state.grantEvaluation.scoresPhase3FinalIsLoading;
export const selectScoresPhase3FinalSortModel = (state: RootState): SortModel => state.grantEvaluation.scoresPhase3FinalSortModel;
export const selectEvaluationTemplatePhase3IsDownloading = (state: RootState): boolean => state.grantEvaluation.evaluationTemplatePhase3IsDownloading;
export const selectGeneratedRankings = (state: RootState): GenerateRankingModel[] => state.grantEvaluation.generatedRankings;

export const selectShowPhase2IntermediaryRanking = (state: RootState): boolean => state.grantEvaluation.showPhase2IntermediaryRanking;
export const selectShowPhase2FinallRanking = (state: RootState): boolean => state.grantEvaluation.showPhase2FinallRanking;
export const selectShowPhase3Ranking = (state: RootState): boolean => state.grantEvaluation.showPhase3Ranking;

export const selectShowPhase2IntermediaryRankingV1 = (state: RootState): boolean => state.grantEvaluation.showPhase2IntermediaryRankingV1;
export const selectShowPhase2FinallRankingV1 = (state: RootState): boolean => state.grantEvaluation.showPhase2FinallRankingV1;
export const selectShowPhase3RankingV1 = (state: RootState): boolean => state.grantEvaluation.showPhase3RankingV1;

export const selectUserIsUploadingAppeal = (state: RootState): boolean => state.grantEvaluation.userIsUploadingAppeal;
export const selectInterviewSchedules = (state: RootState): ScheduleModel[] => state.grantEvaluation.interviewSchedules;
export const selectDisplayAddScheduleDialog = (state: RootState): boolean => state.grantEvaluation.displayAddScheduleDialog;
export const selectScheduleDetails = (state: RootState): ScheduleModel => state.grantEvaluation.scheduleDetails;
export const selectSchedulesListIsLoading = (state: RootState): boolean => state.grantEvaluation.schedulesListIsLoading;
export const selectDeletingSchedule = (state: RootState): boolean => state.grantEvaluation.deletingSchedule;
export const selectLast19Approved = (state: RootState): boolean => state.grantEvaluation.last19Approved;
export const selectLast19ApprovedV2 = (state: RootState): boolean => state.grantEvaluation.last19ApprovedV2;

export const selectUserPhase3EvaluationGridsListIsLoading = (state: RootState): boolean => state.grantEvaluation.userPhase3EvaluationGridsListIsLoading;
export const selectUserPhase3EvaluationGridsFileIsDownloading = (state: RootState): boolean => state.grantEvaluation.userPhase3EvaluationGridsFileIsDownloading;
