import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig } from "store";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { hideOverlay, showOverlay } from "store/slices/overlayLoaderSlice";
import { baseUrl, getAxiosErrorMessage } from "utils";
const axios = require("axios").default;

export const requestLogin = createAsyncThunk<
    any,
    { email: string; password: string },
    AppThunkConfig
>("auth/login", async (loginData, thunkAPI) => {
    try {
        thunkAPI.dispatch(showOverlay());
        let { data } = await axios.post(baseUrl + "Users/Login", loginData, {
            withCredentials: true,
        });
        thunkAPI.dispatch(
            addAppNotification({
                message:
                    data?.response?.message?.text ?? "Autentificat cu succes!",
                severity: "success",
            })
        );
        thunkAPI.dispatch(hideOverlay());
        return data.response;
    } catch (err: any) {
        thunkAPI.dispatch(hideOverlay());
        let errorMessage = getAxiosErrorMessage(err);
        if (errorMessage) {
            thunkAPI.dispatch(
                addAppNotification({
                    message: errorMessage,
                    severity: "error",
                })
            );
            return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
        } else {
            throw err;
        }
    }
});

export const requestRefreshToken = createAsyncThunk<
    any,
    string,
    AppThunkConfig
>("auth/refreshToken", async (token, thunkAPI) => {
    try {
        let { data } = await axios.post(
            baseUrl + "Users/RefreshToken",
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
            }
        );
        return data.response;
    } catch (err: any) {
        let errorMessage = getAxiosErrorMessage(err);
        if (errorMessage) {
            thunkAPI.dispatch(
                addAppNotification({
                    message: errorMessage,
                    severity: "error",
                })
            );
            return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
        } else {
            throw err;
        }
    }
});

export const requestLogout = createAsyncThunk<any, string | null | undefined, AppThunkConfig>(
    "auth/revokeToken",
    async (token, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            let { data } = await axios.post(
                baseUrl + "Users/RevokeToken",
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                }
            );
            thunkAPI.dispatch(hideOverlay());
            return data.response;
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestForgotPassword = createAsyncThunk<
    any,
    string,
    AppThunkConfig
>("auth/forgotPassword", async (email, thunkAPI) => {
    try {
        thunkAPI.dispatch(showOverlay())
        let { data } = await axios.post(
            baseUrl + "Users/ForgotPassword",
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    email,
                },
            }
        );
        thunkAPI.dispatch(hideOverlay());
        thunkAPI.dispatch(
            addAppNotification({
                message:
                    data?.response?.message?.text ?? "Email de resetare a parolei a fost trimis!",
                severity: "success",
            })
        );

        return data.response;
    } catch (err: any) {
        thunkAPI.dispatch(hideOverlay());
        let errorMessage = getAxiosErrorMessage(err);
        if (errorMessage) {
            thunkAPI.dispatch(
                addAppNotification({
                    message: errorMessage,
                    severity: "error",
                })
            );
        } else {
            throw err;
        }
    }
});

export const requestResetPassword = createAsyncThunk<
    any,
    {
        resetToken: string | null | undefined;
        password: string;
        confirmPassword: string;
    },
    AppThunkConfig
>("auth/resetPassword", async (requestData, thunkAPI) => {
    try {
        thunkAPI.dispatch(showOverlay());
        let { data } = await axios.post(
            baseUrl + "Users/ResetPassword",
            {
                token: requestData.resetToken,
                password: requestData.password,
                confirmPassword: requestData.confirmPassword,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        thunkAPI.dispatch(hideOverlay());
        thunkAPI.dispatch(
            addAppNotification({
                message:
                    data?.response?.message?.text ?? "Parolă resetată cu succes!",
                severity: "success",
            })
        );
        return data.response;
    } catch (err: any) {
        thunkAPI.dispatch(hideOverlay());
        let errorMessage = getAxiosErrorMessage(err);
        if (errorMessage) {
            thunkAPI.dispatch(
                addAppNotification({
                    message: errorMessage,
                    severity: "error",
                })
            );
        } else {
            throw err;
        }
    }
});

export const requestVerifyToken = createAsyncThunk<
    any,
    {
        token: string | null,
        password: string | null,
        confirmPassword: string | null,
    },
    AppThunkConfig
>("auth/verifyEmail", async (requestData, thunkAPI) => {
    try {
        thunkAPI.dispatch(showOverlay());
        let { data } = await axios.post(
            baseUrl + "Users/VerifyEmailAndConfiguratePassword", requestData,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        thunkAPI.dispatch(hideOverlay());
        thunkAPI.dispatch(
            addAppNotification({
                message:
                    data?.response?.message?.text ?? "Email-ul a fost verificat cu succes!",
                severity: "success",
            })
        );
        return data.response;
    } catch (err: any) {
        thunkAPI.dispatch(hideOverlay());
        let errorMessage = getAxiosErrorMessage(err);
        if (errorMessage) {
            thunkAPI.dispatch(
                addAppNotification({
                    message: errorMessage ?? "Email-ul nu a putut fi verificat",
                    severity: "error",
                })
            );
            return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
        } else {
            throw err;
        }
    }
});


export const requestUserEmailByVerificationToken = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>("auth/requestUserEmailByVerificationToken", async (token, thunkAPI) => {
    try {
        thunkAPI.dispatch(showOverlay());
        let { data } = await axios.get(baseUrl + "Users/GetUserEmailByVerificationToken", {
            withCredentials: true,
            params: {
                token: token,
            }
        });
        thunkAPI.dispatch(hideOverlay());
        return data.response;
    } catch (err: any) {
        thunkAPI.dispatch(hideOverlay());
        let errorMessage = getAxiosErrorMessage(err);
        if (errorMessage) {
            thunkAPI.dispatch(
                addAppNotification({
                    message: errorMessage,
                    severity: "error",
                })
            );
            return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
        } else {
            throw err;
        }
    }
});