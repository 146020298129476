import { Delete } from "@mui/icons-material";
import { Button, ButtonGroup, Container, Tooltip } from "@mui/material";
import {
    requestDeleteCommunication,
    requestGetReceivedCommunications,
    selectReceivedListIsLoading,
    selectCurrentUser,
    selectReceivedCommunications,
    selectReceivedCount,
    selectSortModel,
    useAppDispatch,
    useAppSelector,
} from "store";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridSortModel,
    GridValueFormatterParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import "./ReceivedView.scss";
import {
    removeReceivedCommunicationById,
    setCommunicationDetails,
} from "store/slices/communicationSlice";
import { CommunicationModel } from "store/models/communication/CommunicationModel";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { mapColumnNameToEnum } from "utils/mapColumnNametoEnum";
import { setSortModel } from "store/slices/targetGroupSlice";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import { dateTimeFormatOptions } from "utils";

export const ReceivedView: FC = () => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const sortModel = useAppSelector(selectSortModel);
    const receivedListIsLoading = useAppSelector(selectReceivedListIsLoading);

    const handleRowClick = (rowData: any) => {
        let details: CommunicationModel = {
            dateCreated: rowData.sentTime,
            userCommunicationId: rowData.userCommunicationId,
            from: rowData.senderId,
            firstName: rowData.firstName,
            lastName: rowData.lastName,
            message: rowData.message,
            subject: rowData.subject,
            files: rowData.files ?? [],
            attachements: rowData.attachements ?? [],
            to: rowData.to,
        };
        dispatch(setCommunicationDetails(details));
    };

    const handleDeleteCommunication = (rowData: any) => {
        dispatch(
            requestDeleteCommunication({
                requestData: {
                    userLoggedInId: currentUser?.userId,
                    communicationId: rowData.userCommunicationId,
                },
                token: currentUser?.jwtToken,
            })
        )
            .unwrap()
            .then(() => {
                dispatch(
                    removeReceivedCommunicationById(rowData.userCommunicationId)

                );
                let column = mapColumnNameToEnum(sortModel.columnToSortBy);
                let sort = false;
                if (sortModel.sortingOrder === "asc") sort = true;
                dispatch(
                    requestGetReceivedCommunications({
                        filter: {
                            pageNumber: currentPageNumber,
                            columnToSortBy: column,
                            sortingOrder: sort
                        },
                        token: currentUser?.jwtToken,
                    })
                );
            });
    };

    useEffect(() => {
        let column = mapColumnNameToEnum(sortModel.columnToSortBy);
        let sort = false;
        if (sortModel.sortingOrder === "asc") sort = true;
        const res = dispatch(
            requestGetReceivedCommunications({
                filter: {
                    pageNumber: currentPageNumber,
                    columnToSortBy: column,
                    sortingOrder: sort
                },
                token: currentUser?.jwtToken,
            })
        );
        return () => {
            res.abort();
        };
    }, [currentPageNumber]);

    const receivedCount = useAppSelector(selectReceivedCount);
    const received = useAppSelector(selectReceivedCommunications);
    const rows = received;

    const handlePageChange = (pageNumber: number) => {
        setCurrentPageNumber(pageNumber);
    };

    const columns: GridColDef[] = [
        {
            field: "from",
            headerName: "Expeditor",
            flex: 1,
            minWidth: 150,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.lastName + " " + params.row.firstName;
            },
        },
        { field: "subject", headerName: "Subiect", flex: 1, minWidth: 150 },
        {
            field: "sentTime",
            headerName: "Dată comunicare",
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                let date: Date = new Date(params.value as string);
                return params.value ? date.toLocaleDateString("ro-RO", dateTimeFormatOptions) : null;
            },
        },
        {
            field: "",
            headerName: "Opțiuni",
            sortable: false,
            flex: 1,
            align: "right",
            minWidth: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <Tooltip title="Șterge Comunicare">
                            <Button
                                color="error"
                                onClick={(event: SyntheticEvent) => {
                                    event.stopPropagation();
                                    handleDeleteCommunication(params.row);
                                }}
                            >
                                <Delete />
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                );
            },
        },
    ];

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length > 0) {
            dispatch(setSortModel({
                columnToSortBy: newModel[0].field,
                sortingOrder: newModel[0].sort
            }));
            let column = mapColumnNameToEnum(newModel[0].field);
            let sort = false;
            if (newModel[0].sort === "asc") sort = true;
            dispatch(
                requestGetReceivedCommunications({
                    filter: {
                        pageNumber: currentPageNumber,
                        columnToSortBy: column,
                        sortingOrder: sort
                    },
                    token: currentUser?.jwtToken,
                })
            );

        } else {
            dispatch(setSortModel({
                columnToSortBy: "createdAt",
                sortingOrder: "desc"
            }));
            dispatch(
                requestGetReceivedCommunications({
                    filter: {
                        pageNumber: currentPageNumber,
                        columnToSortBy: DataGridColumnNames.CreatedAt,
                        sortingOrder: false,
                    },
                    token: currentUser?.jwtToken,
                })
            );
        }
    };

    return (
        <Container
            id="received-view"
            maxWidth="lg"
            sx={{ height: "100%" }}
            disableGutters
        >
            <DataGrid
                components={{
                    LoadingOverlay: DataLoadingComponent
                }}
                loading={receivedListIsLoading}
                rows={rows}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
                paginationMode="server"
                rowCount={receivedCount}
                onPageChange={handlePageChange}
                sx={{
                    "& .MuiDataGrid-row:hover": {
                        background: "aliceblue",
                        cursor: "pointer",
                    },
                    height: 630,
                    boxShadow: 3,
                }}
                onRowClick={(params: GridRowParams) =>
                    handleRowClick(params.row)
                }
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                disableColumnMenu={true}
            ></DataGrid>
        </Container>
    );
};
