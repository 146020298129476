import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { reducers } from './slices';

export const configureCustomStore = () => {
  const rootReducer = combineReducers({
    ...reducers,
  });

  return configureStore({
    reducer: rootReducer,
  });
}
