import { FC } from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
import TimePicker from "@mui/lab/TimePicker";


export const FormikTimePicker: FC<any> = ({ items, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <TimePicker
      label={props.label}
      value={field.value}
      onChange={(e) => {
        helpers.setValue(e);
      }}
      mask="__:__"
      disabled={props.readOnly ?? false}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          value={field.value}
          id={field.name}
          name={field.name}
          error={meta.touched && (Boolean(meta.error) || Boolean(props.apiError))}
          helperText={meta.touched && meta.error}
        />
      )}
    />
  );
};