import {
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import "./LoginPage.scss";

import loginBackground from "assets/images/wallpaper.jpg";
import { Field, FieldProps, Form, Formik } from "formik";
import { FormikTextField } from "components";
import { selectIsLoggingIn, useAppDispatch, useAppSelector } from "store";
import { requestLogin } from "store/thunks/authenticationThunks";
import { forgotPasswordPath, homePath } from "navigation";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { setUserIsLoggingIn } from "store/slices/authSlice";

export const LoginPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('user') !== null) {
            navigate("/");
        }
    }, [])

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const isLoggingIn = useAppSelector(selectIsLoggingIn);

    const validationSchema = yup.object({
        email: yup
            .string()
            .email("Email invalid!")
            .required("Introduceti emailul!"),
        password: yup.string().required("Introduceti parola!"),
    });

    return (
        <Container
            maxWidth={false}
            sx={{
                minHeight: "inherit",
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `url("${loginBackground}")`,
            }}
            id="login-page"
        >
            <Paper
                elevation={5}
                sx={{
                    minHeight: {
                        xs: "450px",
                    },
                    width: {
                        xs: "100%",
                        sm: "450px",
                    },
                }}
            >
                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    onSubmit={(values, { setErrors }: any) => {
                        dispatch(setUserIsLoggingIn(true));
                        dispatch(requestLogin(values))
                            .unwrap()
                            .then(() => {
                                navigate(homePath);
                                dispatch(setUserIsLoggingIn(false));
                            });
                    }}
                    validationSchema={validationSchema}
                >
                    {({ values }) => (
                        <Form style={{ minHeight: "inherit" }}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                                sx={{ px: 6, py: 2, minHeight: "inherit" }}
                            >
                                <Grid item>
                                    <Typography
                                        variant="h3"
                                        color="secondary.dark"
                                        textAlign="center"
                                    >
                                        Login
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <FormikTextField
                                            id="email"
                                            name="email"
                                            label="E-mail"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Field id="password" name="password">
                                            {({
                                                field,
                                                meta,
                                                form,
                                            }: FieldProps<string, any>) => (
                                                <TextField
                                                    name={field.name}
                                                    type={
                                                        showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    fullWidth
                                                    label="Parolă"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={
                                                        (meta.touched &&
                                                            Boolean(
                                                                meta.error
                                                            )) ||
                                                        Boolean(
                                                            form.errors.apiError
                                                        )
                                                    }
                                                    helperText={
                                                        meta.touched &&
                                                        meta.error
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() =>
                                                                        setShowPassword(
                                                                            !showPassword
                                                                        )
                                                                    }
                                                                >
                                                                    {showPassword ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column-reverse",
                                        }}
                                        item
                                        container
                                    >
                                        <LoadingButton
                                            variant="contained"
                                            color="success"
                                            type="submit"
                                            sx={{ marginTop: 1 }}
                                            disabled={isLoggingIn}
                                            loading={isLoggingIn}
                                        >
                                            <Typography variant="h6">
                                                Log in
                                            </Typography>
                                        </LoadingButton>
                                        <Link
                                            component="button"
                                            onClick={() =>
                                                navigate(forgotPasswordPath, {
                                                    state: {
                                                        email: values.email,
                                                    },
                                                })
                                            }
                                        >
                                            Am uitat parola
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </Container>
    );
};
