import { Container } from "@mui/material";
import { FC, useEffect } from "react";
import {
    selectContacts,
    useAppSelector,
    useAppDispatch,
    requestGetContacts,
    selectCurrentUser,
    selectSearchedContacts,
    selectContactCount,
    selectSortModel,
    selectPageNumber,
    selectContactsListIsLoading,
    selectContactsSelectionModel
} from "store";
import "./ContactsView.scss";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { mapColumnNameToEnum } from "utils/mapColumnNametoEnum";
import { setSortModel } from "store/slices/targetGroupSlice";
import { setPageNumber, updateContactsSelectionModel } from "store/slices/communicationSlice";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";


export const ContactsView: FC<any> = ({ onContactsSelect }) => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const searchedContactsString = useAppSelector(selectSearchedContacts);
    const contactCount = useAppSelector(selectContactCount);
    const sortModel = useAppSelector(selectSortModel);
    const pageNumber = useAppSelector(selectPageNumber);
    const contactsListIsLoading = useAppSelector(selectContactsListIsLoading);
    const contactsSelectionModel = useAppSelector(selectContactsSelectionModel);

    useEffect(() => {
        let column = mapColumnNameToEnum(sortModel.columnToSortBy);
        let sort = false;
        if (sortModel.sortingOrder === "asc") sort = true;

        const res = dispatch(
            requestGetContacts({
                filter: {
                    userId: currentUser?.userId,
                    pageNumber: pageNumber,
                    columnToSortBy: column,
                    sortingOrder: sort,
                    searchString: searchedContactsString
                },
                token: currentUser?.jwtToken,
            })
        );
        return () => {
            res.abort();
        };
    }, [pageNumber]);

    useEffect(() => {
        onContactsSelect();
    }, [contactsSelectionModel]);

    const contacts = useAppSelector(selectContacts);

    const rows = contacts.map((contact) => ({
        ...contact,
        id: contact.userId,
    }));


    const handleSelectionModelChange = (contactIdsSelected: any) => {
        dispatch(updateContactsSelectionModel({
            selectedContacts: contactIdsSelected,
            pageContacts: contacts,
        }));
    };
    const handlePageChange = (page: any) => {
        dispatch(setPageNumber(page));
    }
    const columns: GridColDef[] = [
        { field: "lastName", headerName: "Nume", flex: 1, minWidth: 150 },
        { field: "firstName", headerName: "Prenume", flex: 1, minWidth: 150 },
        { field: "email", headerName: "Email", flex: 1, minWidth: 250 },
    ];

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length > 0) {
            dispatch(setSortModel({
                columnToSortBy: newModel[0].field,
                sortingOrder: newModel[0].sort
            }));
            let column = mapColumnNameToEnum(newModel[0].field);
            let sort = false;
            if (newModel[0].sort === "asc") sort = true;
            dispatch(
                requestGetContacts({
                    filter: {
                        userId: currentUser?.userId,
                        pageNumber: pageNumber,
                        columnToSortBy: column,
                        sortingOrder: sort,
                        searchString: searchedContactsString,
                    },
                    token: currentUser?.jwtToken,
                })
            );

        } else {
            dispatch(setSortModel({
                columnToSortBy: "createdAt",
                sortingOrder: "desc"
            }));
            dispatch(
                requestGetContacts({
                    filter: {
                        userId: currentUser?.userId,
                        pageNumber: pageNumber,
                        columnToSortBy: DataGridColumnNames.CreatedAt,
                        sortingOrder: false,
                        searchString: searchedContactsString,
                    },
                    token: currentUser?.jwtToken,
                })
            );
        }

    };

    return (
        <Container id="contacts-view" disableGutters sx={{ height: "100%" }}>
            <DataGrid
                components={{
                    LoadingOverlay: DataLoadingComponent,
                }}
                loading={contactsListIsLoading}
                sx={{
                    "& .MuiDataGrid-row:hover": {
                        background: "aliceblue",
                        cursor: "pointer",
                    },
                    height: 630,
                    boxShadow: 3,
                }}
                checkboxSelection
                rows={rows}
                columns={columns}
                rowCount={contactCount}
                onPageChange={handlePageChange}
                onSelectionModelChange={handleSelectionModelChange}
                selectionModel={contactsSelectionModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                disableColumnMenu={true}
                pageSize={10}
                rowsPerPageOptions={[10]}
                page={pageNumber}
                paginationMode="server"

            ></DataGrid>
        </Container>
    );
};
