import "./UserGtDocumentsView.scss";
import { Add, Delete } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, ButtonGroup, Container, Grid, Tooltip } from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridSortModel,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import { FileUploadPopout } from "components";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    deleteUserDocument,
    downloadUserDocument,
    requestUserDocuments,
    selectCurrentUser,
    selectUserDocumentsListIsLoading,
    selectSortModel,
    selectUserGTDocs,
    selectUserGTDocsRowCount,
    uploadUserDocument,
    useAppDispatch,
    useAppSelector,
    selectUserIsDownloadingFile,
} from "store";
import { UserDocumentType } from "store/models/enums/UserDocumentType";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { mapColumnNameToEnum } from "utils/mapColumnNametoEnum";
import { setSortModel } from "store/slices/targetGroupSlice";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import { dateTimeFormatOptions } from "utils";

export const UserGTDocumentsView: FC = () => {
    const rowsPerPage = 10;
    const dispatch = useAppDispatch();
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const userGtDocuments = useAppSelector(selectUserGTDocs);
    const userGtDocumentsRowCount = useAppSelector(selectUserGTDocsRowCount);
    const { userId } = useParams();
    const currentUser = useAppSelector(selectCurrentUser);
    const sortModel = useAppSelector(selectSortModel);
    const userDocumentsListIsLoading = useAppSelector(selectUserDocumentsListIsLoading);
    const userIsDownloadingFile = useAppSelector(selectUserIsDownloadingFile);
    const [displayUploadDialog, setDisplayUploadDialog] = useState<boolean>(false);

    useEffect(() => {
        let column = mapColumnNameToEnum(sortModel.columnToSortBy);
        let sort = false;
        if (sortModel.sortingOrder === "asc") sort = true;
        let promise = dispatch(
            requestUserDocuments({
                filter: {
                    userId: parseInt(userId ?? "0"),
                    userDocumentType: UserDocumentType.DosarGt,
                    pageNumber: currentPageNumber,
                    columnToSortBy: column,
                    sortingOrder: sort
                },
                token: currentUser?.jwtToken,
            })
        );

        return () => promise.abort();
    }, [currentPageNumber]);

    const handlePageChange = (pageNumber: any) => {
        setCurrentPageNumber(pageNumber);
    }

    const onFilesUpload = (
        files: Array<File>,
        removeAllFilesCallback: Function
    ) => {
        if (files.length > 0) {
            dispatch(
                uploadUserDocument({
                    requestData: {
                        userId: userId,
                        files: files,
                        userDocumentType: UserDocumentType.DosarGt,
                    },
                    token: currentUser?.jwtToken
                })
            ).then(() => {
                removeAllFilesCallback();
                let column = mapColumnNameToEnum(sortModel.columnToSortBy);
                let sort = false;
                if (sortModel.sortingOrder === "asc") sort = true;
                dispatch(
                    requestUserDocuments({
                        filter: {
                            userId: parseInt(userId ?? "0"),
                            userDocumentType: UserDocumentType.DosarGt,
                            pageNumber: 0,
                            columnToSortBy: column,
                            sortingOrder: sort
                        },
                        token: currentUser?.jwtToken,
                    })
                );
                setCurrentPageNumber(0);
            }
            );
        }
    };

    const columns: GridColDef[] = [
        { field: "fileTitle", headerName: "Nume document", flex: 1, minWidth: 150 },
        {
            field: "uploadTime",
            headerName: "Data încărcării",
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let newDate = new Date(params.value as string);
                    return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
                }
            },
        },
        {
            field: "fileSize",
            headerName: "Mărime fișier",
            flex: 1,
            minWidth: 150,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let sizeToBeDisplayed = params.value
                        .toString()
                        .substring(0, 4);
                    return sizeToBeDisplayed + " MB";
                }
            },
        },
        {
            field: "",
            headerName: "Opțiuni",
            sortable: false,
            flex: 1,
            minWidth: 150,
            align: "right",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <Tooltip title="Descarcă Document">
                            <Button
                                disabled={userIsDownloadingFile}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        downloadUserDocument({ fileId: params.row.fileId, token: currentUser?.jwtToken })
                                    );
                                }}
                            >
                                <DownloadIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Șterge Document">
                            <Button
                                disabled={userIsDownloadingFile}
                                color="error"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        deleteUserDocument({
                                            requestData: {
                                                userDocId:
                                                    params.row.userDocumentId,
                                                userDocumentType:
                                                    params.row.userDocumentType,
                                                userId: userId,
                                            },
                                            token: currentUser?.jwtToken,
                                        })
                                    ).then(() => {
                                        dispatch(
                                            requestUserDocuments({
                                                filter: {
                                                    userId: parseInt(userId ?? "0"),
                                                    userDocumentType: UserDocumentType.DosarGt,
                                                    pageNumber: currentPageNumber,
                                                    columnToSortBy: DataGridColumnNames.UploadTime,
                                                    sortingOrder: false
                                                },
                                                token: currentUser?.jwtToken,
                                            })
                                        );
                                    });;
                                }}
                            >
                                <Delete />
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                );
            },
        },
    ];

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length > 0) {
            dispatch(setSortModel({
                columnToSortBy: newModel[0].field,
                sortingOrder: newModel[0].sort
            }));
            let column = mapColumnNameToEnum(newModel[0].field);
            let sort = false;
            if (newModel[0].sort === "asc") sort = true;
            dispatch(
                requestUserDocuments({
                    filter: {
                        userId: parseInt(userId ?? "0"),
                        userDocumentType: UserDocumentType.DosarGt,
                        pageNumber: currentPageNumber,
                        columnToSortBy: column,
                        sortingOrder: sort
                    },
                    token: currentUser?.jwtToken,
                })
            );

        } else {
            dispatch(setSortModel({
                columnToSortBy: "createdAt",
                sortingOrder: "desc"
            }));
            dispatch(
                requestUserDocuments({
                    filter: {
                        userId: parseInt(userId ?? "0"),
                        userDocumentType: UserDocumentType.DosarGt,
                        pageNumber: currentPageNumber,
                        columnToSortBy: DataGridColumnNames.UploadTime,
                        sortingOrder: false
                    },
                    token: currentUser?.jwtToken,
                })
            );
        }
    };

    const handleClose = () => {
        setDisplayUploadDialog(false);
    };

    return (
        <Container
            id="user-gt-documents-view"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid
                container
                sx={{
                    minHeight: "inherit",
                    p: {
                        xs: 1,
                        md: 2,
                    },
                }}
                alignItems="stretch"
            >

                <Grid container sx={{ justifyContent: "flex-end" }}>
                    {

                        <Button variant="contained" startIcon={<Add />} color="success" onClick={() => { setDisplayUploadDialog(true) }}>
                            Adaugă
                        </Button>
                    }
                </Grid>
                {
                    displayUploadDialog &&
                    <FileUploadPopout
                        showSize={true}
                        uploadOnButtonCallback={onFilesUpload}
                        handleClose={handleClose}
                        displayDialog={displayUploadDialog} />
                }
                <Grid
                    item
                    xs={12}
                    style={{ marginTop: "10px" }}
                >
                    <DataGrid
                        components={{
                            LoadingOverlay: DataLoadingComponent
                        }}
                        loading={userDocumentsListIsLoading}
                        hideFooterPagination={userDocumentsListIsLoading}
                        rows={userGtDocuments}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        paginationMode="server"
                        rowCount={userGtDocumentsRowCount}
                        onPageChange={handlePageChange}
                        page={currentPageNumber}
                        hideFooterSelectedRowCount={true}
                        disableSelectionOnClick={true}
                        sx={{
                            "& .MuiDataGrid-row:hover": {
                                background: "aliceblue",
                                cursor: "pointer",
                            },
                            height: 630,
                            boxShadow: 3,
                        }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableColumnMenu={true}
                    ></DataGrid>
                </Grid>
            </Grid>
        </Container>
    );
};
