import {
    TextField,
    Button,
    MenuItem
} from "@mui/material";
import { ChangeEvent, FC, useState } from "react";
import {
    downloadAnexa11Archive,
    downloadAnexa13Archive,
    downloadAnexa22Archive,
    downloadUsersAcceptedToCourseAnexa,
    generateAnexa14,
    generateAnexa23,
    generateAnexa24,
    generateAnexa25,
    generateAnexa26,
    generateAnexa27,
    selectCurrentUser,
    useAppDispatch,
    useAppSelector
} from "store";
import { annexesSelectItems } from "store/models/selectItems";

export const AnnexesDownload: FC<{ selectedSeries: number | null | undefined }> = ({ selectedSeries }) => {
    const dispatch = useAppDispatch();

    const currentUser = useAppSelector(selectCurrentUser);
    const [selectedAnexa, setSelectedAnexa] = useState<number | null | undefined>();

    const handleSelectAnexa = (e: ChangeEvent<HTMLInputElement>) => {
        let val: string | null | undefined = e.target.value;
        if (val !== null || val !== undefined) {
            setSelectedAnexa(parseInt(val, 10));
        }
    };

    const checkSelectedSeriesForNull = () => {
        if (selectedSeries === null || selectedSeries === undefined) return true;
        else return false;
    }

    const handleDownloadAnexa11 = () => {
        dispatch(
            downloadAnexa11Archive({
                seriesId: selectedSeries,
                token: currentUser?.jwtToken,
            })
        );
    }
    const handleDownloadAnexa13 = () => {
        dispatch(
            downloadAnexa13Archive({
                seriesId: selectedSeries,
                token: currentUser?.jwtToken,
            })
        );
    }
    const handleDownloadAnexa14 = () => {
        dispatch(
            generateAnexa14({
                seriesId: selectedSeries,
                token: currentUser?.jwtToken,
            })
        );
    }
    const handleDownloadAnexa19 = () => {
        dispatch(
            downloadUsersAcceptedToCourseAnexa({
                seriesId: selectedSeries,
                token: currentUser?.jwtToken,
            })
        );
    }
    const handleDownloadAnexa22 = () => {
        dispatch(
            downloadAnexa22Archive({
                seriesId: selectedSeries,
                token: currentUser?.jwtToken,
            })
        );
    }
    const handleDownloadAnexa23 = () => {
        dispatch(
            generateAnexa23({
                seriesId: selectedSeries,
                token: currentUser?.jwtToken,
            })
        );
    }
    const handleDownloadAnexa24 = () => {
        dispatch(
            generateAnexa24({
                seriesId: selectedSeries,
                token: currentUser?.jwtToken,
            })
        );
    }
    const handleDownloadAnexa25 = () => {
        dispatch(
            generateAnexa25({
                seriesId: selectedSeries,
                token: currentUser?.jwtToken,
            })
        );
    }
    const handleDownloadAnexa26 = () => {
        dispatch(
            generateAnexa26({
                seriesId: selectedSeries,
                token: currentUser?.jwtToken,
            })
        );
    }
    const handleDownloadAnexa27 = () => {
        dispatch(
            generateAnexa27({
                seriesId: selectedSeries,
                token: currentUser?.jwtToken,
            })
        );
    }


    const downloadAnexa = () => {
        switch (selectedAnexa) {
            case 11:
                handleDownloadAnexa11();
                break;
            case 13:
                handleDownloadAnexa13();
                break;
            case 14:
                handleDownloadAnexa14();
                break;
            case 19:
                handleDownloadAnexa19();
                break;
            case 22:
                handleDownloadAnexa22();
                break;
            case 23:
                handleDownloadAnexa23();
                break;
            case 24:
                handleDownloadAnexa24();
                break;
            case 25:
                handleDownloadAnexa25();
                break;
            case 26:
                handleDownloadAnexa26();
                break;
            case 27:
                handleDownloadAnexa27();
                break;
            default:
                break;
        }
    }

    return (
        <div style={{ display: 'inline-flex' }}>
            <TextField
                select
                size="small"
                name="anexe"
                label="Anexă"
                value={selectedAnexa || ""}
                onChange={handleSelectAnexa}
                sx={{ minWidth: "120px" }}
            >
                {annexesSelectItems.map((item: any) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </TextField>
            <Button
                variant="contained"
                disabled={checkSelectedSeriesForNull()}
                onClick={() => downloadAnexa()}
                sx={{ marginLeft: 1 }}
            >
                Descarcă
            </Button>
        </div>
    )
}