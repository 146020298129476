import { baseSlice } from "./baseSlice";
import { targetGroupSlice } from "./targetGroupSlice";
import { authSlice } from "./authSlice";
import { communicationSlice } from "./communicationSlice";
import { appNotificationSlice } from "./appNotificationSlice";
import { vocationalTrainingSlice } from "./vocationalTrainingSlice";
import { grantContestSlice } from "./grantContestSlice";
import { overlayLoaderSlice } from "./overlayLoaderSlice";
import { grantEvaluationSlice } from "./grantEvaluationSlice";
import { manageDocumentsSlice } from "./manageDocumentsSlice";

export const reducers = {
    base: baseSlice.reducer,
    auth: authSlice.reducer,
    targetGroup: targetGroupSlice.reducer,
    communication: communicationSlice.reducer,
    notification: appNotificationSlice.reducer,
    vocationalTraining: vocationalTrainingSlice.reducer,
    grantContest: grantContestSlice.reducer,
    overlayLoader: overlayLoaderSlice.reducer,
    grantEvaluation: grantEvaluationSlice.reducer,
    manageDocuments: manageDocumentsSlice.reducer,
};
