import { Autocomplete, Chip, TextField } from "@mui/material"
import { useField } from "formik";
import { FC } from "react"
interface FormikAutocompleteProps {
  multiple: boolean,
  allOptions: Array<any>,
  value: Array<any>,
  filterSelectedOptions: boolean,
  freeSolo: boolean,
  noOptionsText: string,
  clearIcon: boolean,
  label?: string,
  id?: string,
  name: string,
  readOnly?: boolean,
  getOptionKey?: Function,
  getOptionLabel?: Function,
  onChange?: Function,
  textFieldVariant: "standard" | "filled" | "outlined",
}

export const FormikAutocomplete: FC<any> = (props) => {
  const [field, meta, helpers] = useField(props);
  const getOptionLabel = (option: any): any => {
    return props.getOptionLabel(option);
  }
  const getOptionKey = (option: any): any => {
    return props.getOptionKey(option);
  }
  return (
    <Autocomplete
      id={field.name}
      multiple={props.multiple}
      options={props.allOptions}
      freeSolo={props.freeSolo}
      clearIcon={props.clearIcon}
      noOptionsText={props.noOptionsText}
      value={props.value}

      getOptionLabel={props.getOptionLabel}
      autoHighlight
      onChange={(event, values) => {
        helpers.setValue(values)
        props.onChange(event, values)
      }}
      filterSelectedOptions={props.filterSelectedOptions}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={getOptionLabel(option)}
            color="primary"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))
      }

      renderInput={(params) => <TextField {...params}
        label={props.inputLabel}
        variant={props.textFieldVariant}
        error={meta.touched && (Boolean(meta.error) || Boolean(props.apiError))}
        helperText={meta.touched && meta.error}
      />}
      renderOption={(props, option) => {
        return (
          <li {...props} key={getOptionKey(option)}>
            {getOptionLabel(option)}
          </li>
        );
      }}
    />
  )
}