import { Chip, Container } from "@mui/material"
import { Box } from "@mui/system";
import { FormikAutocomplete } from "components/shared/formikInputs/FormikAutocomplete";
import { FC, useEffect } from "react"
import { selectCommunicationDetails, selectReceivers, useAppDispatch, useAppSelector, selectAllContacts } from "store";
import { setCommunicationDetails, setReceivers } from "store/slices/communicationSlice";

export const ContactsAutocomplete: FC<{ isReadOnly: boolean }> = ({ isReadOnly }) => {
  const dispatch = useAppDispatch();
  const comDetails = useAppSelector(selectCommunicationDetails);
  const receivers = useAppSelector(selectReceivers);
  const allContacts = useAppSelector(selectAllContacts);
  var remainingContacts: any[] = [];


  const handleChange = (event: any, values: any) => {
    if (typeof values[values.length - 1] !== 'string') {
      dispatch(setReceivers(values));
    }
    dispatch(setCommunicationDetails({
      ...comDetails,
    }))
  }

  useEffect(() => {
    if (!isReadOnly) {
      allContacts.forEach((contact: any) => {
        let isContactInReceivers = false;
        receivers.forEach((receiver: any) => {
          if (receiver.receiverId === contact.userId) isContactInReceivers = true;
        })

        if (!isContactInReceivers) {
          remainingContacts.push({
            receiverId: contact.userId,
            receiverLastName: contact.lastName,
            receiverFirstName: contact.firstName,
          })
        }
      })
    }
  });
  return (
    <Container
      maxWidth="lg"
      disableGutters
    >
      {!isReadOnly ?
        <FormikAutocomplete
          multiple={true}
          allOptions={remainingContacts}
          value={receivers}
          filterSelectedOptions={true}
          freeSolo={true}
          clearIcon={false}
          onChange={handleChange}
          noOptionsText="Niciun contact"
          textFieldVariant="standard"
          getOptionKey={(receiver: any) => receiver.receiverId}
          getOptionLabel={(receiver: any) => receiver.receiverLastName + " " + receiver.receiverFirstName}
          name="receivers"
        />
        :
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {comDetails.to?.map(
            (receiver: any, index) => (
              <Box
                sx={{
                  paddingRight: 1,
                  paddingBottom: 1,
                }}
                key={`key-${index}`}
              >
                <Chip
                  color="primary"
                  label={
                    receiver.receiverLastName +
                    " " +
                    receiver.receiverFirstName
                  }
                  variant="outlined"
                />
              </Box>
            )
          )}
        </Box>
      }

    </Container>)
}