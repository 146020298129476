import { Delete } from "@mui/icons-material";
import { Button, ButtonGroup, Container, Tooltip } from "@mui/material";
import {
	requestDeleteCommunication,
	requestGetSentCommunications,
	selectSentListIsLoading,
	selectCurrentUser,
	selectSentCommunications,
	selectSentCount,
	selectSortModel,
	useAppDispatch,
	useAppSelector,
} from "store";
import {
	DataGrid,
	GridColDef,
	GridRenderCellParams,
	GridRowParams,
	GridSortModel,
	GridValueFormatterParams,
	GridValueGetterParams,
	MuiEvent,
} from "@mui/x-data-grid";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import "./SentView.scss";
import {
	removeSentCommunicationById,
	setCommunicationDetails,
} from "store/slices/communicationSlice";
import { CommunicationModel } from "store/models/communication/CommunicationModel";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { mapColumnNameToEnum } from "utils/mapColumnNametoEnum";
import { setSortModel } from "store/slices/targetGroupSlice";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import { dateTimeFormatOptions } from "utils";

export const SentView: FC = () => {
	const dispatch = useAppDispatch();
	const currentUser = useAppSelector(selectCurrentUser);
	const received = useAppSelector(selectSentCommunications);
	const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
	const sortModel = useAppSelector(selectSortModel);
	const sentListisLoading = useAppSelector(selectSentListIsLoading);


	const sentCount = useAppSelector(selectSentCount);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPageNumber(pageNumber);
	};

	const handleRowClick = (rowData: any) => {
		let details: CommunicationModel = {
			dateCreated: rowData.sentTime,
			userCommunicationId: rowData.userCommunicationId,
			from: rowData.senderId,
			firstName: rowData.firstName,
			lastName: rowData.lastName,
			message: rowData.message,
			subject: rowData.subject,
			files: rowData.files ?? [],
			to: rowData.to,
			attachements: rowData.attachements ?? [],
		};
		dispatch(setCommunicationDetails(details));
	};

	const handleDeleteCommunication = (rowData: any) => {
		dispatch(
			requestDeleteCommunication({
				requestData: {
					userLoggedInId: currentUser?.userId,
					communicationId: rowData.userCommunicationId,
				},
				token: currentUser?.jwtToken,
			})
		)
			.unwrap()
			.then(() => {
				dispatch(
					removeSentCommunicationById(rowData.userCommunicationId)
				);
				let column = mapColumnNameToEnum(sortModel.columnToSortBy);
				let sort = false;
				if (sortModel.sortingOrder === "asc") sort = true;
				dispatch(
					requestGetSentCommunications({
						filter: {
							pageNumber: currentPageNumber,
							columnToSortBy: column,
							sortingOrder: sort
						},
						token: currentUser?.jwtToken,
					})
				);
			});
	};

	useEffect(() => {
		let column = mapColumnNameToEnum(sortModel.columnToSortBy);
		let sort = false;
		if (sortModel.sortingOrder === "asc") sort = true;
		const res = dispatch(
			requestGetSentCommunications({
				filter: {
					pageNumber: currentPageNumber,
					columnToSortBy: column,
					sortingOrder: sort
				},
				token: currentUser?.jwtToken,
			})
		);
		return () => {
			res.abort();
		};
	}, [currentPageNumber]);

	const rows = received;
	const columns: GridColDef[] = [
		{
			field: "from",
			headerName: "Expeditor",
			minWidth: 150,
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => {
				return params.row.firstName + " " + params.row.lastName;
			},
		},
		{
			field: "to",
			headerName: "Destinatar/i",
			flex: 1,
			minWidth: 150,
			valueGetter: (params: GridValueGetterParams) => {
				return params.row.to.map(
					(receiver: any) =>
						receiver.receiverLastName +
						" " +
						receiver.receiverFirstName
				);
			},
		},
		{ field: "subject", headerName: "Subiect", flex: 1, minWidth: 150 },
		{
			field: "sentTime",
			headerName: "Dată comunicare",
			flex: 1,
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams) => {
				let date: Date = new Date(params.value as string);
				return params.value ? date.toLocaleDateString("ro-RO", dateTimeFormatOptions) : null;
			},
		},
		{
			field: "",
			headerName: "Opțiuni",
			sortable: false,
			flex: 1,
			minWidth: 150,
			align: "right",
			renderCell: (params: GridRenderCellParams) => {
				return (
					<ButtonGroup variant="contained" size="small">
						<Tooltip title="Șterge Comunicare">
							<Button
								color="error"
								onClick={(event: SyntheticEvent) => {
									event.stopPropagation();
									handleDeleteCommunication(params.row);
								}}
							>
								<Delete />
							</Button>
						</Tooltip>
					</ButtonGroup>
				);
			},
		},
	];

	const handleSortModelChange = (newModel: GridSortModel) => {
		if (newModel.length > 0) {
			dispatch(setSortModel({
				columnToSortBy: newModel[0].field,
				sortingOrder: newModel[0].sort
			}));
			let column = mapColumnNameToEnum(newModel[0].field);
			let sort = false;
			if (newModel[0].sort === "asc") sort = true;
			dispatch(
				requestGetSentCommunications({
					filter: {
						pageNumber: currentPageNumber,
						columnToSortBy: column,
						sortingOrder: sort
					},
					token: currentUser?.jwtToken,
				})
			);

		} else {
			dispatch(setSortModel({
				columnToSortBy: "createdAt",
				sortingOrder: "desc"
			}));
			dispatch(
				requestGetSentCommunications({
					filter: {
						pageNumber: currentPageNumber,
						columnToSortBy: DataGridColumnNames.CreatedAt,
						sortingOrder: false,
					},
					token: currentUser?.jwtToken,
				})
			);
		}
	};

	return (
		<Container id="sent-view" sx={{ height: "100%" }} disableGutters>
			<DataGrid
				components={{
					LoadingOverlay: DataLoadingComponent
				}}
				loading={sentListisLoading}
				rows={rows}
				columns={columns}
				pageSize={20}
				rowsPerPageOptions={[20]}
				paginationMode="server"
				rowCount={sentCount}
				onPageChange={handlePageChange}
				sx={{
					"& .MuiDataGrid-row:hover": {
						background: "aliceblue",
						cursor: "pointer",
					},
					height: 630,
					boxShadow: 3,
				}}
				onRowClick={(
					params: GridRowParams,
					event: MuiEvent<React.MouseEvent>
				) => {
					event.defaultMuiPrevented = true;
					handleRowClick(params.row);
				}}
				sortingMode="server"
				onSortModelChange={handleSortModelChange}
				disableColumnMenu={true}
			></DataGrid>
		</Container>
	);
};
