import { RootState } from "store";
import { UserModel } from "store/models/user/UserModel";
import { CourseSeries } from "store/models/vocationalTraining/CourseSeries";

export const selectVocationalTrainingForms = (state: RootState): any => state.vocationalTraining.vocationalTrainingFormsResponse?.vocDocuments ?? [];
export const selectVocationalTrainingFormsRowCount = (state: RootState): any => state.vocationalTraining.vocationalTrainingFormsResponse?.rowCount ?? 0;
export const selectVocationalExamResultsDocs = (state: RootState): any => state.vocationalTraining.vocationalTrainingExamResultsResponse?.vocDocuments ?? [];
export const selectVocationalExamResultsDocsRowCount = (state: RootState): any => state.vocationalTraining.vocationalTrainingExamResultsResponse?.rowCount ?? 0;
export const selectVocationalTrainingExtraFiles = (state: RootState): any => state.vocationalTraining.vocationalTraininExtraFilesResponse?.vocDocuments ?? [];
export const selectVocationalTrainingExtraFilesRowCount = (state: RootState): any => state.vocationalTraining.vocationalTraininExtraFilesResponse?.rowCount ?? 0;
export const selectCourseSeries = (state: RootState): CourseSeries[] => state.vocationalTraining.courseSeries;
export const selectUsersInAndOutOfSeries = (
    state: RootState
): {
    usersOfSeries: UserModel[];
    usersOutOfSeries: UserModel[];
} => state.vocationalTraining.usersInAndOutOfSeries;
export const selectFormsListIsLoading = (state: RootState): boolean => state.vocationalTraining.formsListIsLoading;
export const selectExamResultsListIsLoading = (state: RootState): boolean => state.vocationalTraining.examResultsListIsLoading;
export const selectExtraFilesListIsLoading = (state: RootState): boolean => state.vocationalTraining.extraFilesListIsLoading;
export const selectVocationalFileIsDownloading = (state: RootState): boolean => state.vocationalTraining.vocationalFileIsDownloading;
export const selectVocationalFileIsUploading = (state: RootState): boolean => state.vocationalTraining.vocationalFileIsUploading;
export const selectCourseSeriesUserIsLoading = (state: RootState): boolean => state.vocationalTraining.courseSeriesUserIsLoading;
export const selectUserIsChangedBetweenCourseSeries = (state: RootState): boolean => state.vocationalTraining.userIsChangedBetweenCourseSeries;
