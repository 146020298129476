import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "store/models";

export const baseSlice = createSlice({
    name: "baseSlice",
    initialState: {
        actions: {},
        payload: 0,
        displayNavMenu: false,
    } as BaseState,
    reducers: {
        setTemplateValue(state, action: PayloadAction<number>) {
            state.payload = action.payload;
        },
        setDisplayNav(state, action: PayloadAction<boolean>) {
            state.displayNavMenu = action.payload;
        },
    },
});

export const { setTemplateValue, setDisplayNav } = baseSlice.actions;
