import { Box, Grid } from "@mui/material";
import "./FooterComponent.scss";
import pocu from "../../../assets/images/pocu.png";
import instrumente from "../../../assets/images/instrumente-structurale.png";
import guvernRo from "../../../assets/images/512px-Guvernul_Romaniei.png";
import ue from "../../../assets/images/ue.png";
import { FC } from "react";

const FooterComponent: FC = () => {
    return (
        <footer className="footer">
            <Box sx={{ backgroundColor: "primary.main", height: "100%" }}>
                <Grid
                    container
                    columns={{ xs: 12 }}
                    justifyContent="space-around"
                    alignItems="stretch"
                    sx={{ height: "100%", padding: "5px" }}
                >
                    <Grid item sx={{ height: "100%" }} xs={2}>
                        <div
                            style={{
                                height: "100%",
                                backgroundImage: `url(${pocu})`,
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}
                        ></div>
                    </Grid>
                    <Grid item sx={{ height: "100%" }} xs={2}>
                        <div
                            style={{
                                height: "100%",
                                backgroundImage: `url(${guvernRo})`,
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}
                        ></div>
                    </Grid>
                    <Grid item sx={{ height: "100%" }} xs={2}>
                        <div
                            style={{
                                height: "100%",
                                backgroundImage: `url(${ue})`,
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}
                        ></div>
                    </Grid>
                    <Grid item sx={{ height: "100%" }} xs={2}>
                        <div
                            style={{
                                height: "100%",
                                backgroundImage: `url(${instrumente})`,
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}
                        ></div>
                    </Grid>
                </Grid>
            </Box>
        </footer>
    );
};

export default FooterComponent;
