import { FC } from "react";
import { useLocation } from "react-router";
import "./ForgotPasswordPage.scss";
import loginBackground from "assets/images/wallpaper.jpg";
import * as yup from "yup";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FormikTextField } from "components";
import { Location } from "react-router-dom";
import { requestForgotPassword, useAppDispatch } from "store";

interface LocationState {
    email?: string | null;
}

export const ForgotPasswordPage: FC = () => {
    const dispatch = useAppDispatch();

    let location: Location = useLocation();
    let locationState = location.state as LocationState;
    const validationSchema = yup.object({
        email: yup
            .string()
            .email("Email invalid!")
            .required("Introduceti emailul!"),
    });

    return (
        <Container
            maxWidth={false}
            sx={{
                minHeight: "inherit",
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `url("${loginBackground}")`,
            }}
            id="login-page"
        >
            <Paper
                elevation={5}
                sx={{
                    minHeight: {
                        xs: "450px",
                    },
                    width: {
                        xs: "100%",
                        sm: "450px",
                    },
                }}
            >
                <Formik
                    initialValues={{
                        email: locationState?.email ?? "",
                    }}
                    onSubmit={(values) => {
                        dispatch(requestForgotPassword(values.email));
                    }}
                    validationSchema={validationSchema}
                >
                    <Form style={{ minHeight: "inherit" }}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            sx={{ px: 6, py: 2, minHeight: "inherit" }}
                        >
                            <Grid item>
                                <Typography
                                    variant="h3"
                                    color="secondary.dark"
                                    textAlign="center"
                                >
                                    Resetare parolă
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    textAlign="center"
                                >
                                    Trimite email de resetare a parolei
                                </Typography>
                            </Grid>
                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <FormikTextField
                                        id="email"
                                        name="email"
                                        label="E-mail"
                                    />
                                </Grid>
                                <Grid item container justifyContent="center">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        type="submit"
                                    >
                                        <Typography variant="h6">
                                            Trimite
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Paper>
        </Container>
    );
};
