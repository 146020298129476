import { Folder, Home } from "@mui/icons-material";
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { selectSelectedUser, useAppDispatch, useAppSelector } from "store";
import { AuthenticationModel } from "store/models/authModel/AuthenticationModel";
import { UserType } from "store/models/enums/UserType";
import {
    setCurrentUser,
    setIsRefreshing,
    setIsSilentRefresh,
} from "store/slices/authSlice";
import { setDisplayNav } from "store/slices/baseSlice";
import { setSelectedUser } from "store/slices/grantContestSlice";
import { requestRefreshToken } from "store/thunks/authenticationThunks";
import { FormControlComponent } from "../formControlComponent/FormControlComponent";
import "./NavigationMenuComponent.scss";

const NavigationMenuComponent: FC = () => {
    const displayNavMenu = useAppSelector((state) => state.base.displayNavMenu);
    const currentUser = useAppSelector((state) => state.auth.currentUser);
    const dispatch = useAppDispatch();
    const selectedUser = useAppSelector(selectSelectedUser);
    const handleTokenRefresh = useCallback(
        function (isSilent: boolean) {
            const localStorageToken = localStorage.getItem("user");

            if (localStorageToken) {
                if (isSilent) dispatch(setIsSilentRefresh(true));
                const localStorageTokenParsed = JSON.parse(localStorageToken);
                setCurrentUser({
                    ...currentUser,
                    jwtToken: localStorageTokenParsed,
                } as AuthenticationModel);
                dispatch(requestRefreshToken(localStorageTokenParsed));
            } else {
                dispatch(setIsRefreshing(false));
            }
        },
        [dispatch, currentUser]
    );

    const isCompanyUser = () => {
        if (currentUser?.userType === UserType.COMPANY_USER) return true;
        return false;
    }

    const handleConcursPlanDeAfaceriV1Click = () => {
        localStorage.removeItem("userId")
        if (selectedUser !== null) {
            navigate("/planafaceri/v1/" + selectedUser?.userId);
            localStorage.setItem('userId', JSON.stringify(selectedUser?.userId))
        } else {
            navigate("/planafaceri/v1/" + currentUser?.userId);
        }

    }
    const handleConcursPlanDeAfaceriClick = () => {
        localStorage.removeItem("userId")
        if (selectedUser !== null) {
            navigate("/planafaceri/" + selectedUser?.userId);
            localStorage.setItem('userId', JSON.stringify(selectedUser?.userId))
        } else {
            navigate("/planafaceri/" + currentUser?.userId);
        }
    }

    useEffect(() => {
        const interval = setInterval(handleTokenRefresh, 1000 * 60 * 14, true);
        handleTokenRefresh(false);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const navigate = useNavigate();
    return (
        <div className="nav-menu-component">
            <Drawer
                anchor="left"
                open={displayNavMenu}
                onClose={() => dispatch(setDisplayNav(false))}
            >
                <Box sx={{ width: 300, color: "primary.main" }}>
                    <List>
                        <ListItem button onClick={() => navigate("/")}>
                            <ListItemIcon>
                                <Home color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Acasă"></ListItemText>
                        </ListItem>
                        {!isCompanyUser() && <ListItem button onClick={handleConcursPlanDeAfaceriV1Click}>
                            <ListItemIcon>
                                <Folder color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Concurs plan de afaceri v1"></ListItemText>
                        </ListItem>}
                        <ListItem button onClick={handleConcursPlanDeAfaceriClick}>
                            <ListItemIcon>
                                <Folder color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Concurs plan de afaceri"></ListItemText>
                        </ListItem>
                    </List>
                    {currentUser?.userType !== UserType.COMPANY_USER ? <FormControlComponent width={300} position={"fixed"} bottom={0} userId={currentUser?.userId} token={currentUser?.jwtToken} /> : <List></List>}
                </Box>
            </Drawer>
        </div>
    );
};

export default NavigationMenuComponent;
