import { Add, Delete } from '@mui/icons-material';
import { Button, ButtonGroup, Container, Grid, Tooltip, Typography } from '@mui/material';
import DownloadIcon from "@mui/icons-material/Download";
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid';
import { FileUploadPopout } from 'components';
import { FC, useEffect, useState } from 'react';
import { deleteAnexa2Phase2Document, downloadAnexa2Phase2Document, requestAnexa2Phase2Documents, selectAnexa2Phase2DocumentsCount, selectAnexa2Phase2SortModel, selectCurrentUser, selectGrantApproved, selectUserAnexa2Phase2Documents, selectUserAnexa2Phase2DocumentsListIsLoading, selectUserIsDownloadingAnexa2Phase2File, uploadAnexa2Phase2UserDocuments, useAppDispatch, useAppSelector } from 'store';
import { DataGridColumnNames } from 'store/models/enums/DataGridColumnNames';
import { setAnexa2Phase2SortModel, setUserAnexa2Phase2Documents } from 'store/slices/grantContestSlice';
import { mapColumnNameToEnum } from 'utils/mapColumnNametoEnum';
import "./Annexe2Component.scss";
import DataLoadingComponent from 'components/shared/dataLoadingComponent/DataLoadingComponent';
import { UserType } from 'store/models/enums/UserType';
import { dateTimeFormatOptions } from 'utils';

export const Annexe2Component: FC<{ selectedUserId: number | null | undefined, isVersion2: boolean }> = ({ selectedUserId, isVersion2 }) => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const userAnexa2Phase2Documents = useAppSelector(selectUserAnexa2Phase2Documents);
    const anexa2Phase2DocumentsCount = useAppSelector(selectAnexa2Phase2DocumentsCount);
    const userIsDownloadingAnexa2Phase2File = useAppSelector(selectUserIsDownloadingAnexa2Phase2File);
    const grantApproved = useAppSelector(selectGrantApproved);
    const userAnexa2Phase2DocumentsListIsLoading = useAppSelector(selectUserAnexa2Phase2DocumentsListIsLoading);

    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);

    const rowsPerPage = 10;

    const anexa2Phase2SortModel = useAppSelector(selectAnexa2Phase2SortModel);
    const [displayUploadDialog, setDisplayUploadDialog] = useState<boolean>(false);

    const handleRequestAnexa2Docs = (userId: number | null | undefined, pageNumber: number, columnToSortBy: DataGridColumnNames, sortingOrder: boolean, token: string | undefined) => {
        dispatch(requestAnexa2Phase2Documents({
            filter: {
                userId: userId,
                pageNumber: pageNumber,
                columnToSortBy: columnToSortBy,
                sortingOrder: sortingOrder,
                isVersion2: isVersion2,
            },
            token: token,
        }))
    }

    useEffect(() => {
        setCurrentPageNumber(0);
        if (selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) {
            handleRequestAnexa2Docs(selectedUserId, 0, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);
        } else {
            dispatch(setUserAnexa2Phase2Documents({
                anexa2documents: [],
                rowCount: 0,
            }))
        }
    }, [selectedUserId])

    useEffect(() => {
        let column = mapColumnNameToEnum(anexa2Phase2SortModel.columnToSortBy);
        let sort = false;
        if (anexa2Phase2SortModel.sortingOrder === "asc") sort = true;
        if (selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) {
            handleRequestAnexa2Docs(selectedUserId, currentPageNumber, column, sort, currentUser?.jwtToken);

        }
    }, [currentPageNumber, isVersion2]);

    const columns: GridColDef[] = [
        { field: "fileTitle", headerName: "Nume document", flex: 1, minWidth: 150 },
        {
            field: "uploadTime",
            headerName: "Data încărcării",
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let newDate = new Date(params.value as string);
                    return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
                }
            },
        },
        {
            field: "fileSize",
            headerName: "Mărime fișier",
            flex: 1,
            minWidth: 150,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let sizeToBeDisplayed = params.value
                        .toString()
                        .substring(0, 4);
                    return sizeToBeDisplayed + " MB";
                }
            },
        },
        {
            field: "uploadedBy",
            headerName: "Încărcat de",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "",
            headerName: "Opțiuni",
            sortable: false,
            flex: 1,
            align: "right",
            minWidth: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <Tooltip title="Descarcă Fișier">
                            <Button
                                disabled={userIsDownloadingAnexa2Phase2File}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        downloadAnexa2Phase2Document({ fileId: params.row.fileId, token: currentUser?.jwtToken })
                                    );
                                }}
                            >
                                <DownloadIcon />
                            </Button>
                        </Tooltip>
                        {currentUser?.userType !== UserType.COMPANY_USER &&
                            <Tooltip title="Șterge Fișier">
                                <Button
                                    disabled={userIsDownloadingAnexa2Phase2File}
                                    color="error"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(
                                            deleteAnexa2Phase2Document({
                                                docId: params.row.documentId,
                                                token: currentUser?.jwtToken,
                                            })
                                        ).then(() => {
                                            handleRequestAnexa2Docs(selectedUserId, currentPageNumber, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);
                                        });

                                    }}
                                >
                                    <Delete />
                                </Button>
                            </Tooltip>}
                    </ButtonGroup>
                );
            },
        },
    ];

    const handlePageChange = (pageNumber: any) => {
        setCurrentPageNumber(pageNumber);
    }

    const onFilesUpload = (
        files: Array<File>,
        removeAllFilesCallback: Function
    ) => {
        if (files.length > 0) {
            dispatch(
                uploadAnexa2Phase2UserDocuments({
                    requestData: {
                        companyUserId: selectedUserId!,
                        files: files,
                        isVersion2: isVersion2,
                    },
                    token: currentUser?.jwtToken
                })).then(() => {
                    removeAllFilesCallback();
                    let column = mapColumnNameToEnum(anexa2Phase2SortModel.columnToSortBy);
                    let sort = false;
                    if (anexa2Phase2SortModel.sortingOrder === "asc") sort = true;
                    handleRequestAnexa2Docs(selectedUserId, 0, column, sort, currentUser?.jwtToken);
                    setCurrentPageNumber(0);
                }
                );

        }
    }

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length > 0) {
            dispatch(setAnexa2Phase2SortModel({
                columnToSortBy: newModel[0].field,
                sortingOrder: newModel[0].sort
            }));
            let column = mapColumnNameToEnum(newModel[0].field);
            let sort = false;
            if (newModel[0].sort === "asc") sort = true;
            handleRequestAnexa2Docs(selectedUserId, currentPageNumber, column, sort, currentUser?.jwtToken);

        } else {
            dispatch(setAnexa2Phase2SortModel({
                columnToSortBy: "createdAt",
                sortingOrder: "desc"
            }));
            handleRequestAnexa2Docs(selectedUserId, currentPageNumber, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);
        }
    };

    const handleClose = () => {
        setDisplayUploadDialog(false);
    };

    const canAddDoc = currentUser?.userType !== UserType.COMPANY_USER ? ((selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) ? true : false) : true;

    return (
        <Container
            id="anexa2-view"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid container sx={{ backgroundColor: "lightgrey", padding: 1 }}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", marginBottom: 1, alignItems: "center" }}>
                    <Grid container justifyContent={"flex-start"}>
                        <Typography
                            variant="h6"
                            color="white"
                            textAlign="center"
                        >
                            Anexa 2 - Bugetul planului de afaceri
                        </Typography>
                    </Grid>
                    <Grid container sx={{ justifyContent: "flex-end" }}>
                        {
                            !grantApproved && !(currentUser?.userType === UserType.COMPANY_USER) &&
                            <Button disabled={!canAddDoc} variant="contained" startIcon={<Add />} color="success" onClick={() => { setDisplayUploadDialog(true) }}>
                                Adaugă
                            </Button>
                        }
                    </Grid>
                    {
                        displayUploadDialog &&
                        <FileUploadPopout
                            showSize={true}
                            uploadOnButtonCallback={onFilesUpload}
                            uploadDisabled={!canAddDoc}
                            handleClose={handleClose}
                            displayDialog={displayUploadDialog} />
                    }
                </Grid>
                <Grid item
                    xs={12}
                    style={{ backgroundColor: "white" }}
                    id="anexa2-datagrid-container"
                >
                    <DataGrid
                        components={{
                            LoadingOverlay: DataLoadingComponent
                        }}
                        loading={userAnexa2Phase2DocumentsListIsLoading}
                        hideFooterPagination={userAnexa2Phase2DocumentsListIsLoading}
                        rows={userAnexa2Phase2Documents}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        paginationMode="server"
                        rowCount={anexa2Phase2DocumentsCount}
                        onPageChange={handlePageChange}
                        page={currentPageNumber}
                        hideFooterSelectedRowCount={true}
                        disableSelectionOnClick={true}
                        rowHeight={49}
                        sx={{
                            "& .MuiDataGrid-row:hover": {
                                background: "aliceblue",
                                cursor: "pointer",
                            },
                            height: "100%",
                            boxShadow: 3,
                        }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableColumnMenu={true}
                    ></DataGrid>

                </Grid>

            </Grid>
        </Container>
    )
}