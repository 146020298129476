export function isNotNullOrUndefined(object: any) {
  return (object !== null && object !== undefined);
}

export function isNullOrUndefined(object: any) {
  return !(object !== null && object !== undefined);
}

export function getPropertyFromValue(value: any, object: any) {
  //returns key from value or  undefined if not found
  return isNotNullOrUndefined(object) ? Object.keys(object).find(key => object[key] === value) : undefined;
}