import { Box, Button, Chip, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import "./FileUploadComponent.scss";
import { useAppDispatch } from "store";
import { addAppNotification } from "store/slices/appNotificationSlice";

interface FileUploadProps {
    onFilesChange?: Function;
    showSize?: boolean
    uploadOnButtonCallback?: Function,
    uploadDisabled?: boolean,
    fileLimit?: number,
    fontSize?: string,
    acceptedFileformats?: Array<string>,
}

export const FileUploadComponent: FC<FileUploadProps> = ({ onFilesChange, showSize, uploadOnButtonCallback, uploadDisabled, fileLimit, fontSize, acceptedFileformats }) => {
    const [files, setFiles] = useState<Array<File>>([]);
    const [acceptedFiles, setAcceptedFiles] = useState<Array<File>>([]);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (onFilesChange) onFilesChange(files);
    }, [files]);

    useEffect(() => {
        if (fileLimit !== undefined) {
            if (acceptedFiles.length + files.length <= fileLimit) {
                setFiles(prev => [...prev, ...acceptedFiles]);
            }
            else {
                displayMaximumFilesLimitReached();
            }
        } else {
            setFiles(prev => [...prev, ...acceptedFiles]);
        }
    }, [acceptedFiles])


    const onDrop = useCallback((acceptedFiles: Array<File>, fileRejections: FileRejection[]) => {
        if (fileRejections.length > 0) {
            if (fileRejections[0].errors[0].code === "file-invalid-type") {
                displayAcceptedFileformatMessage();
            }
            if (fileRejections[0].errors[0].code === "too-many-files") {
                displayMaximumFilesLimitReached();
            }


        }
        setAcceptedFiles(acceptedFiles);
    }, []);

    const displayMaximumFilesLimitReached = () => {
        dispatch(addAppNotification({
            message: `Se pot încărca doar ${fileLimit} document(e).`,
            severity: "warning"
        }));
    }

    const displayAcceptedFileformatMessage = () => {
        dispatch(addAppNotification({
            message: `Se pot încărca doar documente cu extensiile ${acceptedFileformats}.`,
            severity: "warning"
        }));
    }

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true,
        maxFiles: fileLimit,
        accept: acceptedFileformats

    });
    const hasFiles = files.length > 0;

    const removeFile = (fileToRemove: File) => {
        let newFiles: Array<File> = files.filter(
            (file) => file !== fileToRemove
        );
        setFiles(newFiles);
    };

    const openExplorerDialog = (event: any) => {
        if (event.target.className instanceof SVGAnimatedString) return;
        open();
    };

    const removeAllFiles = () => {
        setFiles([]);
    };

    return (
        <Grid sx={{ height: "100%" }}>
            <Grid sx={{ height: "90%" }}>
                <Paper
                    className="upload-file-component"
                    {...getRootProps()}
                    sx={{
                        height: "100%",
                        width: "100%",
                        overflowY: "auto",
                        ":hover": { cursor: "pointer" },
                        boxSizing: "border-box",
                        p: 1,
                    }}
                    elevation={isDragActive ? 15 : 5}
                    onClick={(e: any) => openExplorerDialog(e)}
                >
                    <input {...getInputProps()} />
                    {!hasFiles && (
                        <Grid container justifyContent="center" alignContent="center" sx={{ height: "100%" }}>
                            <Grid item>
                                {isDragActive ? (
                                    <Typography
                                        variant="h5"
                                        color="secondary"
                                        textAlign="center"
                                    >
                                        Plasați aici
                                    </Typography>
                                ) : (
                                    <Typography variant="h5" textAlign="center" sx={{ fontSize: fontSize }}>
                                        Plasați fișierele aici sau dă click pentru a selecta
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {files.map((file, index) => (
                            <Box sx={{ padding: "3px" }} key={`file-key-${index}`}>
                                <Chip
                                    label={showSize ? file.name + "  " + ((file.size / 1024) / 1024).toFixed(2) + "MB" : file.name}
                                    variant="outlined"
                                    onDelete={() => {
                                        removeFile(file);
                                    }}
                                />
                            </Box>
                        ))}

                    </Box>

                </Paper>
            </Grid>
            <Grid>
                <Box sx={{ height: "10%" }}>
                    {uploadOnButtonCallback && <Grid sx={{
                        justifyContent: "flex-end",
                        marginTop: 1,
                        display: "flex"
                    }}>
                        <Grid sx={{ paddingRight: 1 }}>
                            <Button disabled={!hasFiles} color={"error"} variant="contained" onClick={(e) => {

                                if (!uploadDisabled) removeAllFiles();
                            }}>Șterge</Button>
                        </Grid>
                        <Grid>
                            <Tooltip title="Încarcă Fișierele">
                                <Button disabled={uploadDisabled} variant="contained" color={"success"} onClick={(e) => {

                                    if (!uploadDisabled && hasFiles) uploadOnButtonCallback(files, removeAllFiles);
                                }}>Încarcă</Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    }
                </Box>
            </Grid>
        </Grid>
    );
};
