import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig } from "store";
import { DocumentType } from "store/models/enums/DocumentType";
import { AddNewEmptyDosarDeAchizitiiModel } from "store/models/manageDocuments/AddNewEmptyDosarDeAchizitiiModel";
import { AnexaRequestModel } from "store/models/manageDocuments/AnexaRequestModel";
import { EditRejectionCommentModel } from "store/models/manageDocuments/EditRejectionCommentModel";
import { GenerateAchizitiiValidationNoteModel } from "store/models/manageDocuments/GenerateAchizitiiValidationNoteModel";
import { GenerateDecontValidationNoteModel } from "store/models/manageDocuments/GenerateDecontValidationNoteModel";
import { GenerateSolicitareValidationNoteModel } from "store/models/manageDocuments/GenerateSolicitareValidationNoteModel";
import { IdModel } from "store/models/manageDocuments/IdModel";
import { RejectionModel } from "store/models/manageDocuments/RejectionModel";
import { UpdateDecontCheltuieliModel } from "store/models/manageDocuments/UpdateDecontCheltuieliModel";
import { UpdateSolicitareModificariModel } from "store/models/manageDocuments/UpdateSolicitareModificariModel";
import { UploadDeconCheltuieliModel } from "store/models/manageDocuments/UploadDeconCheltuieliModel";
import { UploadDocumentModel } from "store/models/manageDocuments/UploadDocumentModel";
import { UploadDosarAchizitieModel } from "store/models/manageDocuments/UploadDosarAchizitieModel";
import { UploadGroupOfDeconturiModel } from "store/models/manageDocuments/UploadGroupOfDeconturiModel";
import { UploadGroupOfSolicitariModel } from "store/models/manageDocuments/UploadGroupOfSolicitariModel";
import { UploadSolicitareModificariModel } from "store/models/manageDocuments/UploadSolicitareModificariModel";
import { UserIdDocumentTypeModel } from "store/models/manageDocuments/UserIdDocumentTypeModel";
import { UserIdSubcategoryModel } from "store/models/manageDocuments/UserIdSubcategoryModel";
import { ValidateAnexaModel } from "store/models/manageDocuments/ValidateAnexaModel";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { hideOverlay, showOverlay } from "store/slices/overlayLoaderSlice";
import { baseUrl, getAxiosErrorMessage } from "utils";
const axios = require("axios").default;

export const getAllCategoryDocuments = createAsyncThunk<
	any,
	{ documentCategory: DocumentType, userId: number | undefined, token: string | undefined },
	AppThunkConfig
>("manageDocuments/getAllCategoryDocuments",
	async ({ documentCategory, userId, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.get(baseUrl + "Documents/GetAllCategoryDocuments",
				{
					params: {
						documentCategory: documentCategory,
						userId: userId!,
					},
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;

		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const uploadDocument = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: UploadDocumentModel; token?: string | undefined },
	AppThunkConfig
>(
	"manageDocuments/uploadDocument",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("companyUserId", requestData.companyUserId.toString());
			requestData.fileToUpload.forEach((file: string | Blob) => {
				form.append("filesToUpload", file);
			});
			form.append("documentType", requestData.documentType.toString());
			if (requestData.subcategory.toString() !== "0")
				form.append("subcategory", requestData.subcategory.toString());
			if (requestData.monthCategory !== 0)
				form.append("monthCategory", requestData.monthCategory.toString());
			form.append("yearCategory", requestData.yearCategory.toString());
			if (requestData.notaValidareDosarId !== null)
				form.append("notaValidareDosarId", requestData.notaValidareDosarId.toString());
			if (requestData.notaValidareSolicitareId !== null)
				form.append("notaValidareSolicitareId", requestData.notaValidareSolicitareId.toString());
			if (requestData.notaValidareDecontId !== null)
				form.append("notaValidareDecontId", requestData.notaValidareDecontId.toString());

			let result = await axios.post(
				baseUrl + "Documents/UploadDocument",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message.text ??
						"Document incarcat cu succes",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const uploadDecontCheltuieli = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: UploadDeconCheltuieliModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/uploadDecontCheltuieli",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("companyUserId", requestData.companyUserId!.toString());
			if (requestData.documenteJustificative !== undefined && requestData.documenteJustificative.length !== 0) {
				requestData.documenteJustificative.forEach((file: string | Blob) => {
					form.append("documenteJustificative", file);
				});
			}

			form.append("tipCheltuiala", requestData.tipCheltuiala);
			form.append("nrDocumentJustificativ", requestData.nrDocumentJustificativ.toString());
			form.append("monthCategory", requestData.monthCategory.toString());
			form.append("ajutorMinimis", requestData.ajutorMinimis.toString());
			form.append("contributieProprie", requestData.contributieProprie.toString());
			form.append("dataDocumentJustificativ", new Date(requestData.dataDocumentJustificativ).toISOString());
			form.append("yearCategory", requestData.yearCategory.toString());
			form.append("groupOfDeconturiId", requestData.groupOfDeconturiId.toString());
			let result = await axios.post(
				baseUrl + "Documents/UploadDecontCheltuieli",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message.text ??
						"Document incarcat cu succes",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const uploadSolicitareModificari = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: UploadSolicitareModificariModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/uploadSolicitareModificari",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();

			form.append("companyUserId", requestData.companyUserId!.toString());
			requestData.files.forEach((file: string | Blob) => {
				form.append("files", file);
			});
			form.append("sectiunePlanAfaceri", requestData.sectiunePlanAfaceri);
			form.append("modificareSolicitataPlanAfaceri", requestData.modificareSolicitataPlanAfaceri);
			form.append("justificareModificarePlanAfaceri", requestData.justificareModificarePlanAfaceri);
			form.append("valoareInitialaPlanAfaceri", requestData.valoareInitialaPlanAfaceri);
			form.append("valoareModificataPlanAfaceri", requestData.valoareModificataPlanAfaceri);
			form.append("linieBuget", requestData.linieBuget);
			form.append("modificareSolicitataBuget", requestData.modificareSolicitataBuget);
			form.append("justificareModificareBuget", requestData.justificareModificareBuget);
			form.append("valoareInitialaPlanBuget", requestData.valoareInitialaPlanBuget);
			form.append("valoareModificataPlanBuget", requestData.valoareModificataPlanBuget);
			form.append("yearCategory", requestData.yearCategory.toString());
			form.append("groupOfSolicitareModificariId", requestData.groupOfSolicitareModificareId.toString());
			form.append("rejectedComment", requestData.rejectedComment);

			let result = await axios.post(
				baseUrl + "Documents/UploadSolicitareModificari",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message.text ??
						"Document incarcat cu succes",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const deleteSolicitareModificari = createAsyncThunk<
	any,
	{ requestData: IdModel, token?: string },
	AppThunkConfig
>(
	"manageDocuments/deleteSolicitareModificari",
	//signal is to be used later, now just leave it there to avoid lint warnings
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(
				baseUrl + "Documents/DeleteSolicitareModificari",
				requestData,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message?.text ??
						"Documentul a fost sters",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const downloadDocument = createAsyncThunk<
	any,
	{ requestData: IdModel, token: string | undefined },
	AppThunkConfig
>(
	"manageDocuments/downloadDocument",
	//signal is to be used later, now just leave it there to avoid lint warnings
	async ({ requestData, token }, thunkAPI) => {
		try {
			let result = await axios.get(
				baseUrl + "Documents/DownloadDocument",
				{
					params: {
						documentId: requestData.id,
					},
					responseType: "blob",
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					}
				},
			);
			let blob = new Blob([result.data], {
				type: result.headers["content-type"],
			});
			let url = window.URL.createObjectURL(blob);

			let fileLink = document.createElement("a");
			fileLink.href = url;

			let filename = "";
			let disposition = result.headers["content-disposition"];

			if (disposition && disposition.indexOf("attachment") !== -1) {
				var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				var matches = filenameRegex.exec(disposition);
				if (matches != null && matches[1]) {
					filename = matches[1].replace(/['"]/g, "");
				}
			}

			fileLink.setAttribute("download", filename);
			document.body.appendChild(fileLink);

			fileLink.click();
			fileLink.remove();

		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const getDocumentsFromUserWithDocumentType = createAsyncThunk<
	any,
	{ requestData: UserIdDocumentTypeModel, userId: number, token: string | undefined },
	AppThunkConfig
>("manageDocuments/getDocumentsFromUserWithDocumentType",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.get(baseUrl + "Documents/GetDocumentsFromUserWithDocumentType", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const getDocumentsFromUserWithSubcategory = createAsyncThunk<
	any,
	{ requestData: UserIdSubcategoryModel, userId: number, token: string | undefined },
	AppThunkConfig
>("manageDocuments/getDocumentsFromUserWithSubcategory",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.get(baseUrl + "Documents/GetDocumentsFromUserWithSubcategory", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const validateDocument = createAsyncThunk<
	any,
	{ requestData: IdModel, token?: string },
	AppThunkConfig
>(
	"manageDocuments/validateDocument",
	//signal is to be used later, now just leave it there to avoid lint warnings
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("documentId", requestData.id.toString());
			let result = await axios.post(
				baseUrl + "Documents/ValidateDocument",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message?.text ??
						"Documentul a fost validat",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const unvalidateDocument = createAsyncThunk<
	any,
	{ requestData: IdModel, token?: string },
	AppThunkConfig
>(
	"manageDocuments/unvalidateDocument",
	//signal is to be used later, now just leave it there to avoid lint warnings
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("documentId", requestData.id.toString());
			let result = await axios.post(
				baseUrl + "Documents/UnvalidateDocument",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message?.text ??
						"Documentul a fost respins",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const validateDecont = createAsyncThunk<
	any,
	{ requestData: IdModel, token?: string },
	AppThunkConfig
>(
	"manageDocuments/validateDecont",
	//signal is to be used later, now just leave it there to avoid lint warnings
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("decontId", requestData.id.toString());
			let result = await axios.post(
				baseUrl + "Documents/ValidateDecont",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message?.text ??
						"Decontul a fost validat",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const unvalidateDecont = createAsyncThunk<
	any,
	{ requestData: IdModel, token?: string },
	AppThunkConfig
>(
	"manageDocuments/unvalidateDecont",
	//signal is to be used later, now just leave it there to avoid lint warnings
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("decontId", requestData.id.toString());
			let result = await axios.post(
				baseUrl + "Documents/UnvalidateDecont",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message?.text ??
						"Decontul a fost respins",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const rejectDocument = createAsyncThunk<
	any,
	{ requestData: RejectionModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/rejectDocument",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/RejectDocument", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const unrejectDocument = createAsyncThunk<
	any,
	{ requestData: RejectionModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/unrejectDocument",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/UnrejectDocument", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const rejectDecont = createAsyncThunk<
	any,
	{ requestData: RejectionModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/rejectDecont",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/RejectDecont", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const unrejectDecont = createAsyncThunk<
	any,
	{ requestData: RejectionModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/unrejectDecont",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/UnrejectDecont", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const deleteDocument = createAsyncThunk<
	any,
	{ requestData: IdModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/deleteDocument",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/Delete", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const deleteDecont = createAsyncThunk<
	any,
	{ requestData: IdModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/deleteDecont",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/DeleteDecont", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const updateDecont = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: UpdateDecontCheltuieliModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/updateDecont",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("decontCheltuieliId", requestData.decontCheltuieliId!.toString());
			requestData.documenteJustificative.forEach((file: string | Blob) => {
				form.append("documenteJustificative", file);
			});
			requestData.documenteJustificativeDeleted
				.forEach((file, index) => {
					form.append("DocumenteJustificativeDeleted[" + index + "].fileId", file.fileId.toString());
					form.append("DocumenteJustificativeDeleted[" + index + "].fileTitle", file.fileTitle);
					form.append("DocumenteJustificativeDeleted[" + index + "].fileExtension", file.fileExtension);
				});
			form.append("tipCheltuiala", requestData.tipCheltuiala);
			form.append("nrDocumentJustificativ", requestData.nrDocumentJustificativ);
			form.append("ajutorMinimis", requestData.ajutorMinimis.toString());
			form.append("contributieProprie", requestData.contributieProprie.toString());
			form.append("dataDocumentJustificativ", new Date(requestData.dataDocumentJustificativ).toISOString());
			form.append("companyUserId", requestData.companyUserId!.toString());

			let result = await axios.post(
				baseUrl + "Documents/UpdateDecont",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message.text ??
						"Document incarcat cu succes",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const addNewEmptyDosarAchizitii = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: AddNewEmptyDosarDeAchizitiiModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/addNewEmptyDosarAchizitii",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("userId", requestData.userId!.toString());
			form.append("yearCategory", requestData.yearCategory.toString());
			form.append("dosarName", requestData.dosarName);
			let result = await axios.post(
				baseUrl + "Documents/AddNewEmptyDosarAchizitii",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message.text ??
						"Dosar creeat cu succes",
					severity: "success",
				})
			);
			thunkAPI.dispatch(hideOverlay());

			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const uploadAnexa = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: UploadDosarAchizitieModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/uploadAnexa",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("companyUserId", requestData.companyUserId.toString());
			requestData.documenteDosarAchizitie.forEach((file: string | Blob) => {
				form.append("documenteDosarAchizitie", file);
			});
			form.append("yearCategory", requestData.yearCategory.toString());
			form.append("dosarAchizitiiId", requestData.dosarAchizitiiId.toString());
			form.append("anexaNumber", requestData.anexaNumber.toString());
			let result = await axios.post(
				baseUrl + "Documents/UploadAnexa",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message.text ??
						"Anexa Incarcata cu succes",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const addNewSolicitariGroup = createAsyncThunk<
	any,
	{ requestData: UploadGroupOfSolicitariModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/addNewSolicitariGroup",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/AddNewSolicitariGroup", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const addNewGroupOfDeconturi = createAsyncThunk<
	any,
	{ requestData: UploadGroupOfDeconturiModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/addNewGroupOfDeconturi",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/AddNewGroupOfDeconturi", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const validateAnexa = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: ValidateAnexaModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/validateAnexa",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();

			form.append("dosarId", requestData.dosarId.toString());
			form.append("documentId", requestData.documentId.toString());
			form.append("anexaNumber", requestData.anexaNumber.toString());
			form.append("expertId", requestData.expertId.toString());

			let result = await axios.post(
				baseUrl + "Documents/ValidateAnexa",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message.text ??
						"Anexa validata cu succes",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const unvalidateAnexa = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: ValidateAnexaModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/unvalidateAnexa",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();

			form.append("dosarId", requestData.dosarId.toString());
			form.append("documentId", requestData.documentId.toString());
			form.append("anexaNumber", requestData.anexaNumber.toString());
			form.append("expertId", requestData.expertId.toString());

			let result = await axios.post(
				baseUrl + "Documents/UnvalidateAnexa",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message.text ??
						"Anexa respinsa",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const deleteDosarAchizitii = createAsyncThunk<
	any,
	{ requestData: IdModel, token?: string },
	AppThunkConfig
>(
	"manageDocuments/deleteDosarAchizitii",
	//signal is to be used later, now just leave it there to avoid lint warnings
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("dosarId", requestData.id.toString());
			let result = await axios.post(
				baseUrl + "Documents/DeleteDosarAchizitii",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message?.text ??
						"Documentul a fost sters",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const updateSolicitareModificari = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: UpdateSolicitareModificariModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/updateSolicitareModificari",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("solicitareModificariId", requestData.solicitareModificariId.toString());
			form.append("companyUserId", requestData.companyUserId!.toString());
			requestData.files.forEach((file: string | Blob) => {
				form.append("files", file);
			});
			requestData.alteDocumenteDeleted.forEach((file: any, index: any) => {
				form.append("AlteDocumenteDeleted[" + index + "].fileId", file.fileId.toString());
				form.append("AlteDocumenteDeleted[" + index + "].fileTitle", file.fileTitle.toString());
				form.append("AlteDocumenteDeleted[" + index + "].fileExtension", file.fileExtension.toString());
			});
			form.append("sectiunePlanAfaceri", requestData.sectiunePlanDeAfaceri);
			form.append("modificareSolicitataPlanAfaceri", requestData.modificareSolicitataPlanDeAfaceri);
			form.append("justificareModificarePlanAfaceri", requestData.justificareModificarePlanDeAfaceri);
			form.append("valoareInitialaPlanAfaceri", requestData.valoareInitialaPlanDeAfaceri);
			form.append("valoareModificataPlanAfaceri", requestData.valoareModificataPlanDeAfaceri);
			form.append("linieBuget", requestData.linieBuget);
			form.append("modificareSolicitataBuget", requestData.modificareSolicitataBuget);
			form.append("justificareModificareBuget", requestData.justificareModificareBuget);
			form.append("valoareInitialaPlanBuget", requestData.valoareInitialaPlanBuget);
			form.append("valoareModificataPlanBuget", requestData.valoareModificataPlanBuget);
			let result = await axios.post(
				baseUrl + "Documents/UpdateSolicitareModificari",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message.text ??
						"Anexa respinsa",
					severity: "success",
				})
			);
			return result.data.response;
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const validateSolicitareModificari = createAsyncThunk<
	any,
	{ requestData: IdModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/validateSolicitareModificari",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/ValidateSolicitareModificari", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const unvalidateSolicitareModificari = createAsyncThunk<
	any,
	{ requestData: IdModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/unvalidateSolicitareModificari",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/UnvalidateSolicitareModificari", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const rejectSolicitare = createAsyncThunk<
	any,
	{ requestData: RejectionModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/rejectSolicitare",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/RejectSolicitare", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const unrejectSolicitare = createAsyncThunk<
	any,
	{ requestData: RejectionModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/unrejectSolicitare",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/UnrejectSolicitare", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const deleteGroupSolicitareModificari = createAsyncThunk<
	any,
	{ requestData: IdModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/deleteGroupSolicitareModificari",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/DeleteGroupSolicitareModificari", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const deleteGroupOfDeconturi = createAsyncThunk<
	any,
	{ requestData: IdModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/deleteGroupOfDeconturi",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/DeleteGroupOfDeconturi", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const updateRejectionComment = createAsyncThunk<
	any,
	{ requestData: EditRejectionCommentModel, token: string | undefined },
	AppThunkConfig
>("manageDocuments/updateRejectionComment",
	async ({ requestData, token }, thunkAPI) => {
		try {
			thunkAPI.dispatch(showOverlay());
			let result = await axios.post(baseUrl + "Documents/UpdateRejectionComment", requestData,
				{

					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
			thunkAPI.dispatch(hideOverlay());
			return result.data.response;
		}
		catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}

);

export const anexa1Solicitare = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: AnexaRequestModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/anexa1Solicitare",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();

			form.append("groupId", requestData.groupId.toString());
			form.append("actNr", requestData.actNr.toString());

			let result = await axios.post(
				baseUrl + "Documents/Anexa1Solicitare",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message.text ??
						"Anexa respinsa",
					severity: "success",
				})
			);
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const anexa2Solicitare = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: AnexaRequestModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/anexa2Solicitare",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();

			form.append("groupId", requestData.groupId.toString());
			form.append("actNr", requestData.actNr.toString());

			let result = await axios.post(
				baseUrl + "Documents/Anexa2Solicitare",
				form,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			thunkAPI.dispatch(hideOverlay());
			thunkAPI.dispatch(
				addAppNotification({
					message:
						result?.data?.message.text ??
						"Anexa respinsa",
					severity: "success",
				})
			);
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const generateDecontValidationNote = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: GenerateDecontValidationNoteModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/generateDecontValidationNote",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("registrationNumber", requestData.registrationNumber.toString());

			form.append("registrationDate", requestData.registrationDate.toString());
			requestData.decontIds.forEach((id: number, index: number) => {
				if (id) {
					form.append(`decontsIds[${index}]`, id.toString());
				}

			})

			let result = await axios.post(
				baseUrl + "GenerateDocument/GenerateDecontValidationNote",
				form,
				{
					responseType: "blob",
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			let blob = new Blob([result.data], {
				type: result.headers["content-type"],
			});
			let url = window.URL.createObjectURL(blob);

			let fileLink = document.createElement("a");
			fileLink.href = url;

			let filename = "";
			let disposition = result.headers["content-disposition"];

			if (disposition && disposition.indexOf("attachment") !== -1) {
				var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				var matches = filenameRegex.exec(disposition);
				if (matches != null && matches[1]) {
					filename = matches[1].replace(/['"]/g, "");
				}
			}

			fileLink.setAttribute("download", filename);
			document.body.appendChild(fileLink);

			fileLink.click();
			fileLink.remove();
			thunkAPI.dispatch(hideOverlay());
		} catch (err: any) {
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const generateSolicitareValidationNote = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: GenerateSolicitareValidationNoteModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/generateSolicitareValidationNote",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("registrationNumber", requestData.registrationNumber.toString());
			form.append("actAdditionalNo", requestData.actAdditionalNo.toString());
			form.append("registrationDate", requestData.registrationDate.toString());
			requestData.modificareId.forEach((id: number, index: number) => {
				if (id) {
					form.append(`modificareId[${index}]`, id.toString());
				}
			})

			let result = await axios.post(
				baseUrl + "GenerateDocument/GenerateSolicitareValidationNote",
				form,
				{
					responseType: "blob",
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			let blob = new Blob([result.data], {
				type: result.headers["content-type"],
			});
			let url = window.URL.createObjectURL(blob);

			let fileLink = document.createElement("a");
			fileLink.href = url;

			let filename = "";
			let disposition = result.headers["content-disposition"];

			if (disposition && disposition.indexOf("attachment") !== -1) {
				var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				var matches = filenameRegex.exec(disposition);
				if (matches != null && matches[1]) {
					filename = matches[1].replace(/['"]/g, "");
				}
			}

			fileLink.setAttribute("download", filename);
			document.body.appendChild(fileLink);

			fileLink.click();
			fileLink.remove();
			thunkAPI.dispatch(hideOverlay());
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const generateAchizitiiValidationNote = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: GenerateAchizitiiValidationNoteModel; token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/generateAchizitiiValidationNote",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("nrReg", requestData.nrReg.toString());

			form.append("regDate", requestData.regDate.toString());
			form.append("dosarAchizitiiId", requestData.dosarAchizitiiId.toString());

			let result = await axios.post(
				baseUrl + "GenerateDocument/GenerateAchizitiiValidationNote",
				form,
				{
					responseType: "blob",
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			let blob = new Blob([result.data], {
				type: result.headers["content-type"],
			});
			let url = window.URL.createObjectURL(blob);

			let fileLink = document.createElement("a");
			fileLink.href = url;

			let filename = "";
			let disposition = result.headers["content-disposition"];

			if (disposition && disposition.indexOf("attachment") !== -1) {
				var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				var matches = filenameRegex.exec(disposition);
				if (matches != null && matches[1]) {
					filename = matches[1].replace(/['"]/g, "");
				}
			}

			fileLink.setAttribute("download", filename);
			document.body.appendChild(fileLink);

			fileLink.click();
			fileLink.remove();
			thunkAPI.dispatch(hideOverlay());
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);

export const generateAnexa1Solicitare = createAsyncThunk<
	// return type
	any,
	//payload type
	{ requestData: { groupId: number, actNr: string }, token?: string | null },
	AppThunkConfig
>(
	"manageDocuments/generateAnexa1Solicitare",
	async (
		{ requestData, token },
		thunkAPI
	) => {
		try {
			thunkAPI.dispatch(showOverlay());
			const form = new FormData();
			form.append("groupId", requestData.groupId.toString());

			form.append("actNr", requestData.actNr.toString());

			let result = await axios.post(
				baseUrl + "GenerateDocument/GenerateAnexa1Solicitare",
				form,
				{
					responseType: "blob",
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			let blob = new Blob([result.data], {
				type: result.headers["content-type"],
			});
			let url = window.URL.createObjectURL(blob);

			let fileLink = document.createElement("a");
			fileLink.href = url;

			let filename = "";
			let disposition = result.headers["content-disposition"];

			if (disposition && disposition.indexOf("attachment") !== -1) {
				var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				var matches = filenameRegex.exec(disposition);
				if (matches != null && matches[1]) {
					filename = matches[1].replace(/['"]/g, "");
				}
			}

			fileLink.setAttribute("download", filename);
			document.body.appendChild(fileLink);

			fileLink.click();
			fileLink.remove();
			thunkAPI.dispatch(hideOverlay());
		} catch (err: any) {
			thunkAPI.dispatch(hideOverlay());
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
				return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
			} else {
				throw err;
			}
		}
	}
);