import { RootState } from "store";
import { UserModel } from "../models/user/UserModel";

export const selectUsers = (state: RootState): UserModel[] => state.targetGroup?.users ?? [];
export const selectUsersRowCount = (state: RootState): number => state.targetGroup?.rowCount;
export const selectUserDetails = (state: RootState): UserModel => state.targetGroup?.userDetails ?? [];
export const selectUserPersonalDocs = (state: RootState): any[] => state.targetGroup?.userPersonalDocumentsResponse?.userDocuments ?? []
export const selectUserGTDocs = (state: RootState): any[] => state.targetGroup?.userGtDocumentsResponse?.userDocuments ?? [];
export const selectUserPersonalDocsRowCount = (state: RootState): number => state.targetGroup?.userPersonalDocumentsResponse?.rowCount ?? 0;
export const selectUserGTDocsRowCount = (state: RootState): number => state.targetGroup?.userGtDocumentsResponse?.rowCount ?? 0;
export const selectSortModel = (state: RootState): any => state.targetGroup?.sortModel;
export const selectUsersListIsLoading = (state: RootState): boolean => state.targetGroup?.usersListIsLoading;
export const selectUserDocumentsListIsLoading = (state: RootState): boolean => state.targetGroup?.userDocumentsListIsLoading;
export const selectUserIsDownloadingFile = (state: RootState): boolean => state.targetGroup?.userIsDownloadingFile;
export const selectUserIsUploadingFile = (state: RootState): boolean => state.targetGroup?.userIsUploadingFile;
export const selectDeletingUser = (state: RootState): boolean => state.targetGroup?.deletingUser;
export const selectDeletingUserId = (state: RootState): boolean => state.targetGroup?.deletingUserId;
export const selectUserDetailsIsLoading = (state: RootState): boolean => state.targetGroup?.userDetailsIsLoading;
export const selectSelectedTargetGroupUser = (state: RootState): any => state.targetGroup?.selectedTargetGroupUser;