import {
    Container,
    createTheme,
    Grid,
    Paper,
    responsiveFontSizes,
    Stack,
    ThemeProvider,
    Typography,
} from "@mui/material";
import {
    communicationPath,
    usersPath,
    vocationalTrainingPath,
} from "navigation";
import { FC } from "react";
import { useNavigate } from "react-router";
import { selectCurrentUser, useAppDispatch, useAppSelector } from "store";
import { UserType } from "store/models/enums/UserType";
import "./HomePage.scss";
import backgroundImage from "../../assets/images/shutterstock_736534063.jpg";
import { setSelectedTargetGroupUser } from "store/slices/targetGroupSlice";
import { mapManageDocumentsTabstoEnum } from "utils/mapManageDocumentsTabstoEnum";

export const HomePage: FC = () => {
    let theme = createTheme();
    theme = responsiveFontSizes(theme);

    const currentUser = useAppSelector(selectCurrentUser);
    const isCompanyUser = currentUser?.userType === UserType.COMPANY_USER;
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const isCompanyUserWonGrant = () => {
        if (currentUser?.userType === UserType.COMPANY_USER && !currentUser?.wonGrant) {
            return false;
        } else return true;
    }

    return (
        <Container id="home-page" maxWidth={false} disableGutters sx={{ minHeight: "inherit", backgroundImage: `url("${backgroundImage}")`, backgroundPosition: "center", backgroundSize: "cover" }}>
            <Container maxWidth="lg" sx={{ minHeight: "inherit" }}>
                <ThemeProvider theme={theme}>
                    <Grid
                        container
                        sx={{ minHeight: "inherit" }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {!isCompanyUser && <Grid item xs={12} md={6} xl={3} sx={{ p: 2 }}>
                            <Paper
                                className="home-item"
                                component={Stack}
                                direction="column"
                                justifyContent="center"
                                elevation={15}
                                sx={{
                                    "&:hover": {
                                        boxShadow: 5,
                                        cursor: "pointer",
                                    },
                                }}
                                onClick={() => { navigate(usersPath); dispatch(setSelectedTargetGroupUser(null)); }}
                            >
                                <Typography
                                    variant="h4"
                                    color="secondary"
                                    textAlign="center"
                                >
                                    Grup țintă
                                </Typography>
                            </Paper>
                        </Grid>}
                        <Grid item xs={12} md={6} xl={3} sx={{ p: 2 }}>
                            <Paper
                                className="home-item"
                                component={Stack}
                                direction="column"
                                justifyContent="center"
                                elevation={15}
                                sx={{
                                    "&:hover": {
                                        boxShadow: 5,
                                        cursor: "pointer",
                                    },
                                }}
                                onClick={() => navigate(communicationPath)}
                            >
                                <Typography
                                    variant="h4"
                                    color="secondary"
                                    textAlign="center"
                                >
                                    Comunicări
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} xl={3} sx={{ p: 2 }}>
                            <Paper
                                className="home-item"
                                component={Stack}
                                direction="column"
                                justifyContent="center"
                                elevation={15}
                                sx={{
                                    "&:hover": {
                                        boxShadow: 5,
                                        cursor: "pointer",
                                    },
                                }}
                                onClick={() => navigate(vocationalTrainingPath)}
                            >
                                <Typography
                                    variant="h4"
                                    color="secondary"
                                    textAlign="center"
                                >
                                    Formare profesională
                                </Typography>
                            </Paper>
                        </Grid>
                        {isCompanyUserWonGrant() && <Grid item xs={12} md={6} xl={3} sx={{ p: 2 }}>
                            <Paper
                                className="home-item"
                                component={Stack}
                                direction="column"
                                justifyContent="center"
                                elevation={15}
                                sx={{
                                    "&:hover": {
                                        boxShadow: 5,
                                        cursor: "pointer",
                                    },
                                }}
                                onClick={() => navigate("/manage-documents/" + mapManageDocumentsTabstoEnum() + "/" + currentUser?.userId)}
                            >
                                <Typography
                                    variant="h4"
                                    color="secondary"
                                    textAlign="center"
                                >
                                    Monitorizare
                                </Typography>
                            </Paper>
                        </Grid>}

                    </Grid>
                </ThemeProvider>
            </Container>
        </Container>
    );
};
