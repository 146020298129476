import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    requestDeleteCommunication,
    requestGetAllContacts,
    requestGetContacts,
    requestGetReceivedCommunications,
    requestGetSentCommunications,
} from "store";
import { CommunicationState } from "store/models/communication/CommunicationState";

const initialState: CommunicationState = {
    actions: {},
    contacts: [],
    contactCount: 0,
    received: [],
    receivedCount: 0,
    sentCount: 0,
    sent: [],
    sortingOrder: "",
    columnToSortBy: "",
    searchedContacts: "",
    pageNumber: 0,
    contactsListIsLoading: true,
    sentListIsLoading: true,
    receivedListIsLoading: true,
    contactsSelectionModel: [],
    communicationDetails: {
        from: null,
        to: [],
        dateCreated: "",
        subject: "",
        message: "",
        files: [],
        firstName: "",
        lastName: "",
    },
    allContacts: [],
    receivers: new Array<any>(),
    displayDialog: false,
};

export const communicationSlice = createSlice({
    name: "communicationSlice",
    initialState: initialState,
    reducers: {
        setCommunicationDetails(state, action) {
            state.communicationDetails = action.payload;
            state.displayDialog = true;
        },
        setDisplayDialog(state, action) {
            state.displayDialog = action.payload;
        },
        removeReceivedCommunicationById(state, action: PayloadAction<number>) {
            state.received = state.received.filter(
                (comm) => comm.userCommunicationId !== action.payload
            );
        },
        removeSentCommunicationById(state, action: PayloadAction<number>) {
            state.sent = state.sent.filter(
                (comm) => comm.userCommunicationId !== action.payload
            );
        },
        setCommunicationSortingOrder(state, action) {
            state.sortingOrder = action.payload;
        },
        setCommunicationColumnToSortBy(state, action) {
            state.columnToSortBy = action.payload;
        },
        setSearchedContacts(state, action) {
            state.searchedContacts = action.payload;
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setContactsListIsLoading(state, action) {
            state.contactsListIsLoading = action.payload.contactsListIsLoading;
        },
        setSentListIsLoading(state, action) {
            state.sentListIsLoading = action.payload.sentListIsLoading;
        },
        setReceivedListIsLoading(state, action) {
            state.receivedListIsLoading = action.payload.receivedListIsLoading;
        },
        setContactsSelectionModel(state, action) {
            state.contactsSelectionModel = action.payload;
        },
        setReceivers(state, action) {
            state.receivers = action.payload;
        },
        setAllContacts(state, action) {
            state.allContacts = action.payload;
        },
        updateContactsSelectionModel(state, action) {
            action.payload.selectedContacts.forEach((id: any) => {
                if (state.contactsSelectionModel.indexOf(id) < 0) {
                    state.contactsSelectionModel.push(id);
                }
            })
            var idsToRemove: any[] = [];
            action.payload.pageContacts.forEach((contact: any) => {
                if (action.payload.selectedContacts.indexOf(contact.userId) < 0) {
                    idsToRemove.push(contact.userId);
                }
            })

            idsToRemove.forEach((id) => {
                if (state.contactsSelectionModel.indexOf(id) > -1) {
                    const index = state.contactsSelectionModel.indexOf(id);
                    state.contactsSelectionModel.splice(index, 1);
                }
            })


        },
        removeContactFromSelectionModel(state, action) {
            const index = state.contactsSelectionModel.indexOf(action.payload);
            if (index > -1) {
                state.contactsSelectionModel.splice(index, 1);
            }
        }

    },
    extraReducers: (builder) => {
        builder.addCase(requestGetAllContacts.fulfilled, (state, action) => {
            if (action.payload) {
                state.allContacts = action.payload;
            }
        })
        builder.addCase(requestGetContacts.fulfilled, (state, action) => {
            if (action.payload) {
                state.contacts = action.payload.contacts;
                state.contactCount = action.payload.rowCount;
                state.contactsListIsLoading = false;
            }
        });
        builder.addCase(requestGetContacts.pending, (state, action) => {
            state.contactsListIsLoading = true;
        });
        builder.addCase(requestGetContacts.rejected, (state, action) => {
            state.contactsListIsLoading = false;
        });
        builder.addCase(
            requestGetReceivedCommunications.fulfilled,
            (state, action) => {
                if (action.payload) {
                    state.received = action.payload.communications.map(
                        (elem: any) => ({
                            ...elem,
                            id: elem.userCommunicationId,
                            to: elem.receivers,
                        })
                    );
                }
                state.receivedCount = action.payload.rowCount;
                state.receivedListIsLoading = false;
            }
        );
        builder.addCase(requestGetReceivedCommunications.pending, (state, action) => {
            state.receivedListIsLoading = true;
        });
        builder.addCase(requestGetReceivedCommunications.rejected, (state, action) => {
            state.receivedListIsLoading = false;
        });
        builder.addCase(
            requestGetSentCommunications.fulfilled,
            (state, action) => {
                if (action.payload) {
                    state.sent = action.payload.communications.map(
                        (elem: any) => ({
                            ...elem,
                            id: elem.userCommunicationId,
                            to: elem.receivers,
                        })
                    );
                    state.sentCount = action.payload.rowCount;
                    state.sentListIsLoading = false;
                }
            }
        );
        builder.addCase(requestGetSentCommunications.pending, (state, action) => {
            state.sentListIsLoading = true;
        });
        builder.addCase(requestGetSentCommunications.rejected, (state, action) => {
            state.sentListIsLoading = false;
        });
        builder.addCase(requestDeleteCommunication.pending, (state, action) => {
            state.receivedListIsLoading = true;
            state.sentListIsLoading = true;
        });
        builder.addCase(requestDeleteCommunication.rejected, (state, action) => {
            state.receivedListIsLoading = false;
            state.sentListIsLoading = false;
        });
        builder.addCase(requestDeleteCommunication.fulfilled, (state, action) => {
            state.receivedListIsLoading = false;
            state.sentListIsLoading = false;
        });
    },
});

export const {
    setDisplayDialog,
    setCommunicationDetails,
    removeReceivedCommunicationById,
    removeSentCommunicationById,
    setCommunicationColumnToSortBy,
    setCommunicationSortingOrder,
    setSearchedContacts,
    setPageNumber,
    setContactsSelectionModel,
    setReceivers,
    removeContactFromSelectionModel,
    updateContactsSelectionModel
} = communicationSlice.actions;
