import { WarningAmber } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { FC } from "react";

export const DeleteConfirmationDialog: FC<{ headerText: string, display: boolean, handleClose: Function, handleConfirm: Function, data: any }> = ({ display, handleClose, handleConfirm, data, headerText }) => {
  return (
    <Dialog
      maxWidth="xs"
      open={display}
    >
      <DialogTitle>{headerText}</DialogTitle>
      <DialogContent dividers>
        <Grid sx={{ display: "flex", alignItems: "center" }}>
          <WarningAmber sx={{ marginRight: 1 }} />
          Continuarea acestei acțiuni va duce la pierderea oricărei modificări anterioare nesalvate! Doriți să continuați?
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose()}>
          Nu
        </Button>
        <Button variant="contained" onClick={() => handleConfirm(data)}>Da</Button>
      </DialogActions>
    </Dialog>
  )
}