import { Grid, Select, MenuItem } from "@mui/material";
import { FC } from "react";
import { selectShowPhase2FinallRanking, selectShowPhase2IntermediaryRanking, useAppSelector } from "store";

export const RankingStageSelector: FC<{ stage: number, handleChange: any }> = ({ stage, handleChange }) => {
  const showPhase2FinallRanking = useAppSelector(selectShowPhase2FinallRanking);
  const showPhase2IntermediaryRanking = useAppSelector(selectShowPhase2IntermediaryRanking);
  return (
    <Grid>
      <Select
        value={stage.toString()}
        
        onChange={handleChange}
        size="small"
        sx={{
          width: 200
        }}
      >
        {showPhase2IntermediaryRanking && <MenuItem value={1}>Etapa intermediară</MenuItem>}
        {showPhase2FinallRanking && <MenuItem value={2}>Etapa finală</MenuItem>}
      </Select>

    </Grid>
  )
}