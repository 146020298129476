import { Button, ButtonGroup, Container, Grid, Tooltip } from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridSortModel,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import {
    deleteExtraFilesDoc,
    downloadVocationalTrainingExtraFileDoc,
    requestExtraFilesDocs,
    selectCurrentUser,
    selectSortModel,
    selectVocationalTrainingExtraFiles,
    selectVocationalTrainingExtraFilesRowCount,
    selectExtraFilesListIsLoading,
    uploadExtraFilesDocs,
    useAppDispatch,
    useAppSelector,
    selectVocationalFileIsDownloading,
} from "store";
import { Add, Delete } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { FileUploadPopout } from "components";
import "./VocationalTrainingExtraFiles.scss";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { mapColumnNameToEnum } from "utils/mapColumnNametoEnum";
import { setSortModel } from "store/slices/targetGroupSlice";
import { UserType } from "store/models/enums/UserType";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import { dateTimeFormatOptions } from "utils";

export const VocationalTrainingExtraFiles: FC = () => {
    const rowsPerPage = 10;
    const dispatch = useAppDispatch();
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const extraFiles = useAppSelector(selectVocationalTrainingExtraFiles);
    const extraFilesFormRowCount = useAppSelector(
        selectVocationalTrainingExtraFilesRowCount
    );
    const currentUser = useAppSelector(selectCurrentUser);
    const sortModel = useAppSelector(selectSortModel);
    const extraFilesListIsLoading = useAppSelector(selectExtraFilesListIsLoading);
    const vocationalFileIsDownloading = useAppSelector(selectVocationalFileIsDownloading);
    const [displayUploadDialog, setDisplayUploadDialog] = useState<boolean>(false);

    useEffect(() => {
        let column = mapColumnNameToEnum(sortModel.columnToSortBy);
        let sort = false;
        if (sortModel.sortingOrder === "asc") sort = true;
        let promise = dispatch(requestExtraFilesDocs({
            filter: {
                pageNumber: currentPageNumber,
                columnToSortBy: column,
                sortingOrder: sort
            }, token: currentUser?.jwtToken
        }));

        return () => promise.abort();
    }, [currentPageNumber]);

    const onFilesUpload = (
        files: Array<File>,
        removeAllFilesCallback: Function
    ) => {
        if (files.length > 0) {
            dispatch(
                uploadExtraFilesDocs({ files: files, token: currentUser?.jwtToken })
            ).then(() => {
                removeAllFilesCallback();
                let column = mapColumnNameToEnum(sortModel.columnToSortBy);
                let sort = false;
                if (sortModel.sortingOrder === "asc") sort = true;
                dispatch(
                    requestExtraFilesDocs({
                        filter: {
                            pageNumber: 0,
                            columnToSortBy: column,
                            sortingOrder: sort
                        },
                        token: currentUser?.jwtToken,
                    })
                );
                setCurrentPageNumber(0);
            });
        }
    };

    const handlePageChange = (pageNumber: any) => {
        setCurrentPageNumber(pageNumber);
    };

    const columns: GridColDef[] = [
        { field: "fileTitle", headerName: "Nume document", flex: 1, minWidth: 150 },
        {
            field: "uploadTime",
            headerName: "Data încărcării",
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let newDate = new Date(params.value as string);
                    return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
                }
            },
        },
        {
            field: "size",
            headerName: "Mărime fișier",
            flex: 1,
            minWidth: 150,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let sizeToBeDisplayed = params.value.toString().substring(0, 4);
                    return sizeToBeDisplayed + " MB";
                }
            },
        },
        {
            field: "author",
            headerName: "Încărcat de utilizator",
            flex: 1,
            minWidth: 150
        },
        {
            field: "",
            headerName: "Opțiuni",
            sortable: false,
            flex: 1,
            minWidth: 150,
            align: "right",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <Tooltip title="Descarcă Fișier">
                            <Button
                                disabled={vocationalFileIsDownloading}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        downloadVocationalTrainingExtraFileDoc({
                                            fileId: params.row.fileId,
                                            token: currentUser?.jwtToken,
                                        })
                                    );
                                }}
                            >
                                <DownloadIcon />
                            </Button>
                        </Tooltip>
                        {currentUser?.userType !== UserType.COMPANY_USER && <Tooltip title="Șterge Fișier">
                            <Button
                                disabled={vocationalFileIsDownloading}
                                color="error"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(deleteExtraFilesDoc({ docId: params.row.vocDocumentId, token: currentUser?.jwtToken })).then(() => {
                                        dispatch(requestExtraFilesDocs({
                                            filter: {
                                                pageNumber: currentPageNumber,
                                                columnToSortBy: DataGridColumnNames.UploadTime,
                                                sortingOrder: false
                                            }, token: currentUser?.jwtToken
                                        }));
                                    });
                                }}>
                                <Delete />
                            </Button>
                        </Tooltip>}
                    </ButtonGroup>
                );
            },
        },
    ];

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length > 0) {
            dispatch(setSortModel({
                columnToSortBy: newModel[0].field,
                sortingOrder: newModel[0].sort
            }));
            let column = mapColumnNameToEnum(newModel[0].field);
            let sort = false;
            if (newModel[0].sort === "asc") sort = true;
            dispatch(
                requestExtraFilesDocs({
                    filter: {
                        pageNumber: currentPageNumber,
                        columnToSortBy: column,
                        sortingOrder: sort
                    },
                    token: currentUser?.jwtToken,
                })
            );

        } else {
            dispatch(setSortModel({
                columnToSortBy: "createdAt",
                sortingOrder: "desc"
            }));
            dispatch(
                requestExtraFilesDocs({
                    filter: {
                        pageNumber: currentPageNumber,
                        columnToSortBy: DataGridColumnNames.UploadTime,
                        sortingOrder: false
                    },
                    token: currentUser?.jwtToken,
                })
            );
        }
    };

    const handleClose = () => {
        setDisplayUploadDialog(false);
    };

    return (
        <Container
            id="vocational-training-extra-files-view"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid
                container
                sx={{
                    minHeight: "inherit",
                    p: {
                        xs: 1,
                        md: 2,
                    },
                }}
                alignItems="stretch"
            >
                {currentUser?.userType !== UserType.COMPANY_USER && <Grid container sx={{ justifyContent: "flex-end" }}>
                    {

                        <Button variant="contained" startIcon={<Add />} color="success" onClick={() => { setDisplayUploadDialog(true) }}>
                            Adaugă
                        </Button>
                    }
                </Grid>
                }
                {
                    displayUploadDialog &&
                    <FileUploadPopout
                        showSize={true}
                        uploadOnButtonCallback={onFilesUpload}
                        handleClose={handleClose}
                        displayDialog={displayUploadDialog} />
                }
                <Grid item xs={12} style={{ marginTop: "10px" }}>
                    <DataGrid
                        components={{
                            LoadingOverlay: DataLoadingComponent
                        }}
                        loading={extraFilesListIsLoading}
                        hideFooterPagination={extraFilesListIsLoading}
                        rows={extraFiles}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        paginationMode="server"
                        rowCount={extraFilesFormRowCount}
                        onPageChange={handlePageChange}
                        page={currentPageNumber}
                        hideFooterSelectedRowCount={true}
                        disableSelectionOnClick={true}
                        sx={{
                            "& .MuiDataGrid-row:hover": {
                                background: "aliceblue",
                                cursor: "pointer",
                            },
                            height: 630,
                            boxShadow: 3,
                        }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableColumnMenu={true}
                    ></DataGrid>
                </Grid>
            </Grid>
        </Container>
    );
};
