import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import './VerifyTokenRedirectComponent.scss';
import ConfigurePassword from "../configurePassword/ConfigurePassword";

export const VerifyTokenRedirectComponent: FC = () => {
    const { search } = useLocation();
    const paramSearch = new URLSearchParams(search);
    const token = paramSearch.get("token");
    return (
        <>
            <ConfigurePassword token={token} />
        </>

    )
}