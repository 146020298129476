import { FC } from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
import DatePicker from "@mui/lab/DatePicker";

interface FormikDatePickerProps {
    label?: string;
    variant?: "standard" | "filled" | "outlined";
    name: string;
    size?: "medium" | "small";
    apiError?: string | null | undefined;
    id?: string;
    type?: string;
    readOnly?: boolean;
}

export const FormikDatePicker: FC<any> = ({ items, ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (
        <DatePicker
            label={props.label}
            value={field.value}
            onChange={(e) => helpers.setValue(e)}
            mask="__.__.____"
            disabled={props.readOnly ?? false}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    value={field.value ?? ''}
                    id={field.name}
                    name={field.name}
                    error={meta.touched && (Boolean(meta.error) || Boolean(props.apiError))}
                    helperText={meta.touched && meta.error}
                    size={props.size}
                    sx={{ backgroundColor: "white" }}
                />
            )}
        />
    );
};
