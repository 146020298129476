import { FC } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Container, Grid, Typography } from "@mui/material";

const DataLoadingComponent: FC = () => {
    return (
        <Container sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: '1',
            color: "white",
        }}>
            <Grid sx={{
                paddingTop: 10,
            }}>
                <Box sx={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",

                }}>
                    <CircularProgress color="primary" size={"2rem"} />
                    <Typography variant="h6" color="black" sx={{ fontSize: "1.2rem" }}>Se încarcă</Typography>
                </Box>
            </Grid>
        </Container>
    );
};
export default DataLoadingComponent;