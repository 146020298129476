import { RootState } from "store";
import { UserContactModel } from "store/models/user/UserContactModel";
import { CommunicationModel } from "../models/communication/CommunicationModel";

export const selectReceivedCommunications = (state: RootState): CommunicationModel[] => state.communication.received;
export const selectSentCommunications = (state: RootState): CommunicationModel[] => state.communication.sent;
export const selectCommunicationDetails = (state: RootState): CommunicationModel => state.communication.communicationDetails;
export const selectReceivers = (state: RootState): any[] => state.communication.receivers;
export const selectAllContacts = (state: RootState): any[] => state.communication.allContacts;
export const selectDisplayCommunicationDialog = (state: RootState) => state.communication.displayDialog;
export const selectContacts = (state: RootState): UserContactModel[] => state.communication.contacts;
export const selectReceivedCount = (state: RootState): number => state.communication.receivedCount;
export const selectSentCount = (state: RootState): number => state.communication.sentCount;
export const selectContactCount = (state: RootState): number => state.communication.contactCount;
export const selectCommunicationSortingOrder = (state: RootState): string => state.communication.sortingOrder;
export const selectCommunicationColumnToSortBy = (state: RootState): string => state.communication.columnToSortBy;
export const selectSearchedContacts = (state: RootState): string => state.communication.searchedContacts;
export const selectPageNumber = (state: RootState): number => state.communication.pageNumber;
export const selectContactsListIsLoading = (state: RootState): boolean => state.communication.contactsListIsLoading;
export const selectSentListIsLoading = (state: RootState): boolean => state.communication.sentListIsLoading;
export const selectReceivedListIsLoading = (state: RootState): boolean => state.communication.receivedListIsLoading;
export const selectContactsSelectionModel = (state: RootState): Array<any> => state.communication.contactsSelectionModel;