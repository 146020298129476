import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { FormikDatePicker, FormikTextField } from "components";
import { FormikAutocomplete } from "components/shared/formikInputs/FormikAutocomplete";
import { FormikTimePicker } from "components/shared/formikInputs/FormikTimePicker";
import { Form, Formik } from "formik"
import locale from "date-fns/locale/ro";
import { FC, useEffect, useState } from "react"
import { addOrUpdateInterviewSchedule, getInterviewSchedules, getScheduleDetails, requestCompanyUsersNames, selectCompanyUsersNames, selectCurrentUser, selectScheduleDetails, useAppDispatch, useAppSelector } from "store";
import { setScheduleDetails } from "store/slices/grantEvaluationSlice";
import * as yup from "yup";
import { LocalizationProvider } from "@mui/lab";
import { onlyDateFormat, onlyTimeFormat } from "utils";
import { UserType } from "store/models/enums/UserType";

export interface AddOrEditScheduleProps {
  display: boolean,
  handleClose: any,
  registrationNumber?: string,
  isEditing?: boolean,
  isVersion2: boolean
}

export interface AddOrEditScheduleModel {
  scheduleDate: string | null,
  scheduleTime: string | null,
  location: string,
  userScheduledId: number,
  selectedUser: any,
}

export const AddOrEditSchedule: FC<AddOrEditScheduleProps> = ({ display, handleClose, registrationNumber, isEditing, isVersion2 }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const companyUsers = useAppSelector(selectCompanyUsersNames);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const scheduleDetails = useAppSelector(selectScheduleDetails);

  useEffect(() => {
    if (currentUser?.userType === UserType.EXPERT || currentUser?.userType === UserType.ADMIN) {
      dispatch(requestCompanyUsersNames({
        filterByRegistrationNumber: true,
        token: currentUser?.jwtToken,
        isVersion2: isVersion2,
      }))
    }

  }, [])

  const getLocaleDate = (date: string): string => {
    var newDate = new Date(date);
    return newDate.toLocaleDateString("ro-RO", onlyDateFormat);
  }

  const getLocaleTime = (date: string): string => {
    var newDate = new Date(date);
    return newDate.toLocaleTimeString("ro-RO", onlyTimeFormat)
  }
  var initialValues: AddOrEditScheduleModel = {
    scheduleDate: null,
    scheduleTime: null,
    location: "",
    userScheduledId: 0,
    selectedUser: selectedUser
  }

  if (scheduleDetails != null) {
    initialValues = {
      ...initialValues, ...scheduleDetails,
      scheduleDate: scheduleDetails.scheduleDate,
      scheduleTime: scheduleDetails.scheduleTime,
      location: scheduleDetails.location,
      userScheduledId: scheduleDetails.userScheduledId,
      selectedUser: selectedUser
    };
  }
  useEffect(() => {
    if (scheduleDetails != null) {
      initialValues = {
        scheduleDate: getLocaleDate(scheduleDetails.scheduleDate),
        scheduleTime: getLocaleTime(scheduleDetails.scheduleTime),
        location: scheduleDetails.location,
        userScheduledId: scheduleDetails.userScheduledId,
        selectedUser: selectedUser
      }
      companyUsers.forEach((user: any) => {
        if (user.userId === scheduleDetails.userScheduledId) setSelectedUser(user);
      })
    } else {
      initialValues = {
        scheduleDate: null,
        scheduleTime: null,
        location: "",
        userScheduledId: 0,
        selectedUser: selectedUser
      }
    }

  }, [scheduleDetails])

  const validationSchema = yup.object({
    scheduleDate: yup
      .string()
      .required("Dată programare obligatorie!").nullable(true),
    scheduleTime: yup
      .string()
      .required("Oră programare obligatorie!").nullable(true),
    location: yup
      .string()
      .required("Locație obligatorie!").nullable(true),
    selectedUser: yup.object().required("Selectați un utilizator!").nullable(true),
  });

  const handleSelectUser = (e: any, values: any) => {
    setSelectedUser(values);
    if (values != null) {
      dispatch(getScheduleDetails({
        userScheduledId: values.userId,
        token: currentUser?.jwtToken,
        isVersion2: isVersion2,
      }))
    } else {
      dispatch(setScheduleDetails(null));
    }

  }


  const handleSubmit = (values: any) => {
    dispatch(addOrUpdateInterviewSchedule({
      data: {
        scheduleDate: values.scheduleDate,
        scheduleTime: values.scheduleTime,
        location: values.location,
        registrationNumber: values.registrationNumber,
        userScheduledId: parseInt(selectedUser.userId ?? "0"),
        isVersion2: isVersion2,
      },
      token: currentUser?.jwtToken
    })).then(() => {
      dispatch(getInterviewSchedules({
        token: currentUser?.jwtToken,
        isVersion2: isVersion2,
      }))
      handleClose()
    })
  }

  const getOptionLabel = (user: any): string => {
    return (user.lastName || "") + (user.lastName ? " " : "") + (user.firstName || "");
  }


  return (
    <Dialog
      maxWidth="xs"
      open={display}
    >
      <DialogTitle>Programare interviu</DialogTitle>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <Formik enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}>
          {({ values, errors }) => <Form>
            <DialogContent dividers>
              <Grid container direction="column" justifyContent="center" alignItems="center" >
                <Grid marginBottom={3} width={300} >
                  <FormikAutocomplete
                    allOptions={companyUsers}
                    inputLabel="Beneficiar programat *"
                    value={selectedUser}
                    clearIcon={false}
                    getOptionLabel={getOptionLabel}
                    filterSelectedOptions={true}
                    freeSolo={true}
                    onChange={handleSelectUser}
                    getOptionKey={(user: any) => user.userId}
                    renderOption={(props: any, option: any) => {
                      return (
                        <li {...props} key={option.userId}>
                          {option.lastName + " " + option.firstName}
                        </li>
                      );
                    }}
                    isOptionEqualToValue={(option: any, value: any) => option.userId === value.userId}
                    name="selectedUser"
                  />
                </Grid>
                <Grid marginBottom={3} width={300} >
                  <FormikDatePicker
                    label="Dată programare *"
                    name="scheduleDate"
                  />
                </Grid>
                <Grid marginBottom={3} width={300}>
                  <FormikTimePicker label="Ora programării *" name="scheduleTime" />
                </Grid>
                <Grid marginBottom={3} width={300} >
                  <FormikTextField
                    label="Locația *"
                    name="location"
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button variant="contained" color="error" autoFocus onClick={handleClose}>
                Anulează
              </Button>
              <Button variant="contained" color="success" type="submit" >Salvare</Button>
            </DialogActions>
          </Form>}
        </Formik>
      </LocalizationProvider>
    </Dialog>
  )
}