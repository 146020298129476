import { County } from "../enums/County";

export const countiesSelectItems = [
    { label: "Alba", value: County.ALBA },
    { label: "Cluj", value: County.CLUJ },
    { label: "Sibiu", value: County.SIBIU },
    { label: "Mureș", value: County.MURES },
    { label: "Satu Mare", value: County.SATU_MARE },
    { label: "Maramureș", value: County.MARAMURES },
    { label: "Bihor", value: County.BIHOR },
    { label: "Sălaj", value: County.SALAJ },
    { label: "Covasna", value: County.COVASNA },
    { label: "Bistrița Năsăud", value: County.BISTRITA_NASAUD }
]