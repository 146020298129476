import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig } from "store";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { VocationalTrainingFilter } from "store/models/vocationalTraining/VocationalTrainingDocument";
import { baseUrl, getAxiosErrorMessage } from "utils";
import { hideOverlay, showOverlay } from "store/slices/overlayLoaderSlice";
const axios = require("axios").default;

export const requestVocationTrainingForms = createAsyncThunk<
    //return type
    any,
    //payload type
    { filter: VocationalTrainingFilter; token?: string | null | undefined },
    AppThunkConfig
>(
    "vocationalTraining/requestVocationTrainingForms",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ filter: vocationalTrainingFilter, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl +
                "ExpertDocuments/GetPaginatedVocationalFormDocs",
                {
                    params: {
                        pageNumber: vocationalTrainingFilter.pageNumber.toString(),
                        columnToSortBy: vocationalTrainingFilter.columnToSortBy,
                        sortingOrder: vocationalTrainingFilter.sortingOrder,
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const uploadVocationalTrainingForms = createAsyncThunk<
    //return type
    any,
    //payload type
    any,
    AppThunkConfig
>(
    "vocationalTraining/uploadVocationalTrainingForms",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ files, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            const form = new FormData();
            files.forEach((file: string | Blob) => {
                form.append("files", file);
            });
            let result = await axios.post(
                baseUrl + "ExpertDocuments/UploadVocationalFormDoc",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message ??
                        "Document incarcat!",
                    severity: "success",
                })
            );
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const deleteVocationalForm = createAsyncThunk<
    //return type
    any,
    //payload type
    any,
    AppThunkConfig
>(
    "vocationalTraining/deleteUserDocument",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ docId, token }, thunkAPI) => {
        try {
            const form = new FormData();
            form.append("docId", docId.toString());
            let result = await axios.post(
                baseUrl + "ExpertDocuments/DeleteVocationalFormDoc",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message?.text ??
                        "Document eliminat!",
                    severity: "success",
                })
            );
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestExamResultsDocs = createAsyncThunk<
    //return type
    any,
    //payload type
    { filter: VocationalTrainingFilter; token?: string | null | undefined },
    AppThunkConfig
>(
    "vocationalTraining/requestExamResultsDocs",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ filter: vocationalTrainingFilter, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl +
                "ExpertDocuments/GetPaginatedExamResultsDocs",
                {
                    params: {
                        pageNumber: vocationalTrainingFilter.pageNumber.toString(),
                        columnToSortBy: vocationalTrainingFilter.columnToSortBy,
                        sortingOrder: vocationalTrainingFilter.sortingOrder,
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const uploadExamResultsDocs = createAsyncThunk<
    //return type
    any,
    //payload type
    any,
    AppThunkConfig
>(
    "vocationalTraining/uploadExamResultsDocs",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ files, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            const form = new FormData();
            files.forEach((file: string | Blob) => {
                form.append("files", file);
            });
            let result = await axios.post(
                baseUrl + "ExpertDocuments/UploadExamResultsDoc",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message ??
                        "Document incarcat!",
                    severity: "success",
                })
            );
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const deleteExamResultsDoc = createAsyncThunk<
    //return type
    any,
    //payload type
    { docId: number, token?: string },
    AppThunkConfig
>(
    "vocationalTraining/deleteExamResultsDoc",
    async ({ docId, token }, thunkAPI) => {
        try {
            const form = new FormData();
            form.append("docId", docId.toString());
            let result = await axios.post(
                baseUrl + "ExpertDocuments/DeleteExamResultsDoc",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message?.text ??
                        "Document eliminat!",
                    severity: "success",
                })
            );
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestExtraFilesDocs = createAsyncThunk<
    //return type
    any,
    //payload type
    { filter: VocationalTrainingFilter; token?: string | null | undefined },
    AppThunkConfig
>(
    "vocationalTraining/requestExtraFilesDocs",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ filter: vocationalTrainingFilter, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl +
                "ExpertDocuments/GetPaginatedVocationalMaterialDocs",
                {
                    params: {
                        pageNumber: vocationalTrainingFilter.pageNumber.toString(),
                        columnToSortBy: vocationalTrainingFilter.columnToSortBy,
                        sortingOrder: vocationalTrainingFilter.sortingOrder,
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const uploadExtraFilesDocs = createAsyncThunk<
    //return type
    any,
    //payload type
    any,
    AppThunkConfig
>(
    "vocationalTraining/uploadExtraFilesDocs",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ files, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            const form = new FormData();
            files.forEach((file: string | Blob) => {
                form.append("files", file);
            });
            let result = await axios.post(
                baseUrl + "ExpertDocuments/UploadVocationalMaterialDoc",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message ??
                        "Document incarcat!",
                    severity: "success",
                })
            );
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const deleteExtraFilesDoc = createAsyncThunk<
    //return type
    any,
    //payload type
    { docId: number, token?: string },
    AppThunkConfig
>(
    "vocationalTraining/deleteExtraFilesDoc",
    async ({ docId, token }, thunkAPI) => {
        try {
            const form = new FormData();
            form.append("docId", docId.toString());
            let result = await axios.post(
                baseUrl + "ExpertDocuments/DeleteVocationalMaterialDoc",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message?.text ??
                        "Document eliminat!",
                    severity: "success",
                })
            );
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const addNewCourseSeries = createAsyncThunk<
    any,
    { name: string; token?: string | null },
    AppThunkConfig
>(
    "vocationalTraining/createCourseSeries",
    async ({ name, token }, thunkAPI) => {
        try {
            let { data } = await axios.post(
                baseUrl + "ExpertDocuments/CreateCourseSeries",
                {
                    seriesName: name,
                },
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        data?.response?.message?.text ?? "Serie curs adăugată!",
                    severity: "success",
                })
            );
            return data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
);

export const requestCourseSeries = createAsyncThunk<
    any,
    string | null | undefined,
    AppThunkConfig
>("vocationalTraining/getCourseSeries", async (token, thunkAPI) => {
    try {
        let { data } = await axios.get(
            baseUrl + "ExpertDocuments/GetCourseSeries",
            {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return data.response;
    } catch (err: any) {
        let errorMessage = getAxiosErrorMessage(err);
        if (errorMessage) {
            thunkAPI.dispatch(
                addAppNotification({
                    message: errorMessage,
                    severity: "error",
                })
            );
        } else {
            throw err;
        }
    }
});

export const downloadVocationalFormDoc = createAsyncThunk<
    //return type
    any,
    //payload type
    any,
    AppThunkConfig
>(
    "vocationalTraining/downloadVocationalFormDocument",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ fileId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "ExpertDocuments/DownloadVocationalFormDocument",
                {
                    params: {
                        fileId: fileId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
);
export const downloadExamResultsDoc = createAsyncThunk<
    //return type
    any,
    //payload type
    any,
    AppThunkConfig
>(
    "vocationalTraining/downloadExamResultsDocument",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ fileId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "ExpertDocuments/DownloadExamResultsDocument",
                {
                    params: {
                        fileId: fileId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
);
export const downloadVocationalTrainingExtraFileDoc = createAsyncThunk<
    //return type
    any,
    //payload type
    any,
    AppThunkConfig
>(
    "vocationalTraining/downloadVocationalMaterialDocument",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ fileId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "ExpertDocuments/DownloadVocationalMaterialDocument",
                {
                    params: {
                        fileId: fileId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
);

export const requestUsersInAndOutOfSeries = createAsyncThunk<
    any,
    { seriesId: number | null | undefined; token: string | null | undefined },
    AppThunkConfig
>(
    "vocationalTraining/getUsersInAndOutOfSeries",
    async ({ token, seriesId }, thunkAPI) => {
        try {
            let { data } = await axios.get(
                baseUrl + "ExpertDocuments/GetUsersInAndOutOfSeries",
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        seriesId,
                    },
                }
            );
            return data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
);

export const addUsersToSeries = createAsyncThunk<
    any,
    {
        requestData: { seriesId: number | null | undefined; userIds: Array<number | null | undefined> };
        token: string | null | undefined;
    },
    AppThunkConfig
>(
    "vocationalTraining/addUserToCourseSeries",
    async ({ token, requestData }, thunkAPI) => {
        try {
            let { data } = await axios.post(
                baseUrl + "ExpertDocuments/AddUserToCourseSeries",
                requestData,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        data?.response?.message?.text ?? "Utilizatorii au fost adăugați în serie.",
                    severity: "success",
                })
            );
            return data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
);

export const removeUsersFromSeries = createAsyncThunk<
    any,
    {
        requestData: { seriesId: number | null | undefined; userIds: Array<number | null | undefined> };
        token: string | null | undefined;
    },
    AppThunkConfig
>(
    "vocationalTraining/removeUserFromCourseSeries",
    async ({ token, requestData }, thunkAPI) => {
        try {
            let { data } = await axios.post(
                baseUrl + "ExpertDocuments/RemoveUserFromCourseSeries",
                requestData,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        data?.message?.text ?? "Utilizatorii au fost excluși din serie.",
                    severity: "success",
                })
            );
            return data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
);

export const downloadUsersAcceptedToCourseAnexa = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>(
    "vocationalTraining/downloadUsersAcceptedToCourseAnexa",
    async ({ seriesId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GenerateDocument/DownloadUsersAcceptedToCourseAnexa",
                {
                    params: {
                        seriesId: seriesId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
)

export const downloadAnexa11Archive = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>(
    "vocationalTraining/downloadAnexa11Archive",
    async ({ seriesId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GenerateDocument/DownloadAnexa11Archive",
                {
                    params: {
                        seriesId: seriesId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
)

export const downloadAnexa13Archive = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>(
    "vocationalTraining/downloadAnexa13Archive",
    async ({ seriesId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GenerateDocument/DownloadAnexa13Archive",
                {
                    params: {
                        seriesId: seriesId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
)


export const downloadAnexa22Archive = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>(
    "vocationalTraining/downloadAnexa22Archive",
    async ({ seriesId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GenerateDocument/DownloadAnexa22Archive",
                {
                    params: {
                        seriesId: seriesId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
)

export const generateAnexa14 = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>(
    "vocationalTraining/generateAnexa14",
    async ({ seriesId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GenerateDocument/GenerateAnexa14",
                {
                    params: {
                        seriesId: seriesId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
)

export const generateAnexa23 = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>(
    "vocationalTraining/generateAnexa23",
    async ({ seriesId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GenerateDocument/GenerateAnexa23",
                {
                    params: {
                        seriesId: seriesId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
)

export const generateAnexa24 = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>(
    "vocationalTraining/generateAnexa24",
    async ({ seriesId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GenerateDocument/GenerateAnexa24",
                {
                    params: {
                        seriesId: seriesId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
)
export const generateAnexa25 = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>(
    "vocationalTraining/generateAnexa25",
    async ({ seriesId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GenerateDocument/GenerateAnexa25",
                {
                    params: {
                        seriesId: seriesId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
)
export const generateAnexa26 = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>(
    "vocationalTraining/generateAnexa26",
    async ({ seriesId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GenerateDocument/GenerateAnexa26",
                {
                    params: {
                        seriesId: seriesId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
)
export const generateAnexa27 = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>(
    "vocationalTraining/generateAnexa27",
    async ({ seriesId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GenerateDocument/GenerateAnexa27",
                {
                    params: {
                        seriesId: seriesId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
            } else {
                throw err;
            }
        }
    }
)
