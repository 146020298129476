import { Months } from "store/models/enums/Months";
import { Subcategory } from "store/models/enums/Subcategory";
import { Years } from "store/models/enums/Years";
import { mapSubcategoryToLabel } from "./mapManageDocumentsTabstoEnum";
import { getPropertyFromValue, isNotNullOrUndefined } from "./objectUtils";

class TreeTableModelBuilder {
  index: number;
  constructor() {
    this.index = 0;
  }

  buildTreeTableModel() {
    return {
      root: [] as any
    }
  }

  addNodeToChildren(newNode: any, parentNode: any) {
    parentNode.children.push(newNode);
  }

  buildTreeTableNode(key: any, data: any, children?: any): any {
    return {
      key: key,
      data: data,
      children: children
    }
  }

  buildFileNodes(categoryName: any, category: any, year: any) {
    if (isNotNullOrUndefined(category.nonMonthlyDocuments)) {
      return category.nonMonthlyDocuments.files.map((fileData: any) => this.buildTreeTableNode(
        ++this.index,
        {
          name: fileData.file ? fileData.file.fileTitle : fileData.fileTitle,
          documentId: fileData.documentId,
          nodeType: "document",
          category: categoryName,
          requiresValidation: fileData.requiresValidation,
          isTemplateFile: fileData.isTemplateFile,
          isRejected: fileData.isRejected,
          rejectedComment: fileData.rejectedComment,
          isValidated: fileData?.isValidated,
          fileId: fileData.file ? fileData.file.fileId : fileData.fileId,
          dosarAchizitiiId: category.dosarAchizitiiId ? category.dosarAchizitiiId : null,
          anexaNumber: category.anexaNumber ? category.anexaNumber : null
        }
      ));
    } else {
      if (categoryName === getPropertyFromValue(Subcategory.NOTE_VALIDARE_ACHIZITII, Subcategory)) {
        return category.map((fileData: any) => this.buildTreeTableNode(
          ++this.index,
          {
            name: fileData.fileTitle,
            documentId: fileData.documentId,
            nodeType: "document",
            category: categoryName,
            requiresValidation: fileData.requiresValidation,
            isTemplateFile: fileData.isTemplateFile,
            isRejected: fileData.isRejected,
            rejectedComment: fileData.rejectedComment,
            isValidated: fileData.isValidated,
            fileId: fileData.file ? fileData.file.fileId : null,
            dosarAchizitiiId: category.dosarAchizitiiId ? category.dosarAchizitiiId : null,
            anexaNumber: category.anexaNumber ? category.anexaNumber : null
          }
        ));
      }
      if (categoryName === getPropertyFromValue(Subcategory.NOTE_VALIDARE_SOLICITARE, Subcategory)) {
        return category.validationNotes.map((fileData: any) => this.buildTreeTableNode(
          ++this.index,
          {
            name: fileData.fileTitle,
            documentId: fileData.documentId,
            nodeType: "document",
            category: categoryName,
            requiresValidation: fileData.requiresValidation,
            isTemplateFile: fileData.isTemplateFile,
            isRejected: fileData.isRejected,
            rejectedComment: fileData.rejectedComment,
            isValidated: fileData.isValidated,
            fileId: fileData.file ? fileData.file.fileId : null,
            requestGroupId: category.requestGroupId
          }
        ));
      }
      if (categoryName === getPropertyFromValue(Subcategory.NOTE_VALIDARE_DECONT, Subcategory)) {
        return category.validationNotes.map((fileData: any) => this.buildTreeTableNode(
          ++this.index,
          {
            name: fileData.fileTitle,
            documentId: fileData.documentId,
            nodeType: "document",
            category: categoryName,
            requiresValidation: fileData.requiresValidation,
            isTemplateFile: fileData.isTemplateFile,
            isRejected: fileData.isRejected,
            rejectedComment: fileData.rejectedComment,
            isValidated: fileData.isValidated,
            fileId: fileData.file ? fileData.file.fileId : null,
            decontGroupId: category.decontGroupId
          }
        ));
      } else {
        var months = Object.values(Months).splice(1, 12);
        return months.map((month: any) => {
          let monthData = {
            name: month,
            isValidated: false,
            category: categoryName,
            year: year,
            nodeType: "month",
            subcategoryInt: mapSubcategoryToLabel(categoryName),
            monthInt: Months[month],
            yearInt: Years[year],
          };
          return this.buildTreeTableNode(
            ++this.index,
            monthData,
            category[month.toLowerCase()].files.map((fileData: any) => monthData.subcategoryInt === "Decont cheltuieli" ?
              this.buildDecontGroupNodes(monthData, fileData)

              : this.buildTreeTableNode(
                ++this.index,
                {
                  name: fileData.fileTitle,
                  documentId: fileData.documentId,
                  nodeType: "document",
                  category: categoryName,
                  fileId: fileData.file ? fileData.file.fileId : null,
                  isValidated: fileData.isValidated,
                  requiresValidation: fileData.requiresValidation,
                  isRejected: fileData.isRejected,
                  rejectedComment: fileData.rejectedComment,
                  isTemplateFile: fileData.isTemplateFile
                }
              )
            )
          )
        });
      }
    }
  }


  buildCategoryNode(categoryName: any, category: any, year: any) {

    let categoryData = {
      name: "",
      year: 0,
      nodeType: "",
      subcategoryInt: "",
      yearInt: "",
      dosarAchizitiiId: "",
      anexaNumber: "",
      monthInt: 0,
      isValidated: false,
      requestGroupId: 0,
      month: "",
      decontGroupId: 0,
      category: "",
    };
    categoryData.name = categoryName;
    categoryData.year = year;
    categoryData.nodeType = "subCategory"//subCategory, document, month, year
    categoryData.subcategoryInt = mapSubcategoryToLabel(categoryName);
    categoryData.yearInt = (year === "templates" ? "" : Years[year]);
    categoryData.category = categoryName;
    if (isNotNullOrUndefined(category.nonMonthlyDocuments)) {
      //FIXME Trebuie iful de mai sus ?
      if (category.type === 'anexaAchizitii') {
        categoryData.nodeType = 'anexaAchizitii';
        categoryData.dosarAchizitiiId = category.dosarAchizitiiId;
        categoryData.anexaNumber = category.anexaNumber;

        categoryData.monthInt = 0;
        categoryData.isValidated = category.nonMonthlyDocuments.isValidated;
      }
    }

    if (categoryName === "NOTE_VALIDARE_ACHIZITII") {
      categoryData.dosarAchizitiiId = category.dosarAchizitiiId;
      categoryData.monthInt = 0;
    }
    if (categoryName === "NOTE_VALIDARE_SOLICITARE") {
      categoryData.monthInt = 0;
      categoryData.requestGroupId = category.requestGroupId;
    }

    if (categoryName === "NOTE_VALIDARE_DECONT") {
      categoryData.monthInt = category.monthInt;
      categoryData.month = category.month;
      categoryData.decontGroupId = category.decontGroupId;
    }
    if (categoryName === "solicitareModificari") {
      return this.buildTreeTableNode(
        ++this.index,
        categoryData,
        this.buildRequestGroupNodes(categoryName, category, year)
      )
    }
    if (categoryName === 'dosareAchizitii') {
      categoryData.category = categoryName;
      let key = ++this.index;
      let formattedFiles = category.map((dosar: any) => {
        dosar.nodeType = "dosar";
        dosar.category = categoryName;
        return this.buildCategoryNode(dosar.dosarName, dosar, year);
      });

      return this.buildTreeTableNode(
        key,
        categoryData,
        formattedFiles
      );
    }
    if (category.nodeType === "dosar") {
      
      categoryData.dosarAchizitiiId = category.dosarAchizitiiId;
      categoryData.nodeType = "dosar";
      categoryData.category = category.category;
      let dosarKey = ++this.index;
      let anexe = category.anexe.map((anexa: any) => {
        let isAnexa = isNotNullOrUndefined(anexa.nonMonthlyDocuments);
        let categoryName = isAnexa ? anexa.anexaName : getPropertyFromValue(Subcategory.NOTE_VALIDARE_ACHIZITII, Subcategory);
        anexa.type = anexa.nonMonthlyDocuments ? 'anexaAchizitii' : getPropertyFromValue(Subcategory.NOTE_VALIDARE_ACHIZITII, Subcategory);
        anexa.dosarAchizitiiId = category.dosarAchizitiiId;
        return this.buildCategoryNode(categoryName, anexa, year);
      })

      return this.buildTreeTableNode(
        dosarKey,
        categoryData,
        anexe
      )
    }
    return this.buildTreeTableNode(
      ++this.index,
      categoryData,
      this.buildFileNodes(categoryName, category, year)
    );

  }


  buildTreeTableModelFromResponse(response: any) {
    var treeTable = this.buildTreeTableModel();
    var topLevelNodes = Object.keys(response);
    for (let topLevelNode of topLevelNodes) {
      let categories = Object.keys(response[topLevelNode]);
      if (topLevelNode === "templates") {
        treeTable.root.push(this.buildTreeTableNode(
          ++this.index,
          {
            name: topLevelNode,
            nodeType: "templates"
          },
          categories.map(category => this.buildCategoryNode(category, response[topLevelNode][category], topLevelNode))
        ));
      } else {
        treeTable.root.push(this.buildTreeTableNode(
          ++this.index,
          {
            name: topLevelNode,
            nodeType: "year"
          },
          categories.map(category => this.buildCategoryNode(category, response[topLevelNode][category], topLevelNode))
        ));
      }
    }
    return treeTable;
  }

  buildRequestGroupNodes(categoryName: any, category: any, year: any) {
    if (isNotNullOrUndefined(category.nonMonthlyDocuments)) {
      return category.nonMonthlyDocuments.files.map((requestGroupData: any) => this.buildTreeTableNode(
        ++this.index,
        {
          name: requestGroupData.name,
          id: requestGroupData.groupId,
          yearInt: requestGroupData.yearCategory,
          subcategoryInt: Subcategory.SOLICITARE_MODIFICARI,
          year: year,
          nodeType: "requestGroup",
          category: categoryName
        },

        [...requestGroupData.solicitari.map((solicitare: any) => this.buildTreeTableNode(
          ++this.index,
          {
            id: solicitare.solicitareModificariId,
            expertUserForStatus: solicitare.expertUserForStatus,
            isRejected: solicitare.isRejected,
            isValidated: solicitare.isValidated,
            justificareModificareBuget: solicitare.justificareModificareBuget,
            justificareModificarePlanAfaceri: solicitare.justificareModificarePlanAfaceri,
            linieBuget: solicitare.linieBuget,
            modificareSolicitataBuget: solicitare.modificareSolicitataBuget,
            modificareSolicitataPlanAfaceri: solicitare.modificareSolicitataPlanAfaceri,
            rejectedComment: solicitare.rejectedComment,
            requiresValidation: solicitare.requiresValidation,
            sectiunePlanAfaceri: solicitare.sectiunePlanAfaceri,
            uploadedByExpertUser: solicitare.uploadedByExpertUser,
            valoareInitialaPlanAfaceri: solicitare.valoareInitialaPlanAfaceri,
            valoareInitialaPlanBuget: solicitare.valoareInitialaPlanBuget,
            valoareModificataPlanAfaceri: solicitare.valoareModificataPlanAfaceri,
            valoareModificataPlanBuget: solicitare.valoareModificataPlanBuget,
            requestGroupId: requestGroupData.groupId,
            yearInt: requestGroupData.yearCategory,
            subcategoryInt: Subcategory.SOLICITARE_MODIFICARI,
            year: year,
            nodeType: "document",
            category: categoryName,
            isNotSaved: solicitare.isNotSaved,
            requestFiles: solicitare.alteDocumente
          }
        )), this.buildCategoryNode(
          getPropertyFromValue(Subcategory.NOTE_VALIDARE_SOLICITARE, Subcategory),
          {
            name: getPropertyFromValue(Subcategory.NOTE_VALIDARE_SOLICITARE, Subcategory),
            requestGroupId: requestGroupData.groupId,
            yearInt: requestGroupData.yearCategory,
            monthInt: 0,
            subcategoryInt: Subcategory.NOTE_VALIDARE_SOLICITARE,
            year: year,
            nodeType: "subCategory",
            validationNotes: requestGroupData.noteValidare
          },
          year)]
      ));
    }
  }

  buildDecontGroupNodes(monthData: any, groupData: any) {
    return this.buildTreeTableNode(
      ++this.index,
      {
        name: groupData.name,
        id: groupData.groupId,
        yearInt: monthData.yearInt,
        monthInt: monthData.monthInt,
        month: monthData.name,
        subcategoryInt: monthData.subcategoryInt,
        year: monthData.year,
        nodeType: "decontGroup",
        category: monthData.category,
        isValidated: groupData.deconturi.some((decont: any) => decont.isValidated)
      },
      [...groupData.deconturi.map((decont: any) => this.buildTreeTableNode(
        ++this.index,
        {
          decontId: decont.decontCheltuieliId ? decont.decontCheltuieliId : decont.tempId,
          decontGroupId: groupData.groupId,
          decontFiles: decont.files,
          nodeType: "document",
          category: monthData.category,
          subcategoryInt: monthData.subcategoryInt,
          ajutorMinimis: decont.ajutorMinimis,
          contributieProprie: decont.contributieProprie,
          isRejected: decont.isRejected,
          rejectedComment: decont.rejectedComment,
          isTemplateFile: decont.isTemplateFile,
          uploadTime: decont.uploadTime,
          tipCheltuiala: decont.tipCheltuiala,
          requiresValidation: decont.requiresValidation,
          dataDocumentJustificativ: decont.dataDocumentJustificativ,
          decontCheltuieliId: decont.decontCheltuieliId,
          isNotSaved: decont.isNotSaved,
          nrDocumentJustificativ: decont.nrDocumentJustificativ,
          year: monthData.year,
          month: monthData.name,
          monthInt: monthData.monthInt,
          isValidated: decont.isValidated
        }
      )), this.buildCategoryNode(
        getPropertyFromValue(Subcategory.NOTE_VALIDARE_DECONT, Subcategory),
        {
          name: getPropertyFromValue(Subcategory.NOTE_VALIDARE_DECONT, Subcategory),
          decontGroupId: groupData.groupId,
          yearInt: monthData.yearInt,
          monthInt: monthData.monthInt,
          subcategoryInt: Subcategory.NOTE_VALIDARE_DECONT,
          year: monthData.year,
          nodeType: "subCategory",
          validationNotes: groupData.noteValidare
        },
        monthData.year
      )]);

  }
}

export default TreeTableModelBuilder;