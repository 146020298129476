import { Button, Container, Grid } from "@mui/material";
import { FC } from "react";
import "./UserRepresentativeView.scss";
import {
    countiesSelectItems,
    jobStatusSelectItems,
    studiesSelectItems,
    userTypeSelectItems,
    zoneSelectItems,
} from "store/models/selectItems";
// import { useFormikContext } from "formik";
import {
    FormikCheckBox,
    FormikDatePicker,
    FormikSelectSimple,
    FormikTextField,
} from "components";
import { UserType } from "store/models/enums/UserType";
import { ExpertTypeSelectItems } from "store/models/selectItems/expertTypeSelectItems";
import { EmailsList } from "components/shared/emailsField/EmailsList";

interface UserRepresentativeViewProps {
    canEdit?: boolean;
    isNewUser?: boolean;
    userType?: UserType;
}


export const UserRepresentativeView: FC<UserRepresentativeViewProps> = ({ canEdit = false, isNewUser = false, userType = UserType.COMPANY_USER }) => {

    const companyUserSelected = userType === UserType.COMPANY_USER;
    const expertUserSelected = userType === UserType.EXPERT;
    const adminUserSelected = userType === UserType.ADMIN;



    return (
        <Container
            id="user-representative-page"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid
                container
                sx={{
                    minHeight: "inherit",
                    p: {
                        xs: 1,
                    },
                }}
                alignItems="stretch"
            >
                <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    sx={{ mt: 1 }}
                >
                    <Grid item xs={6}>
                        <FormikTextField
                            id="firstName"
                            name="firstName"
                            label="Prenume"
                            readOnly={!canEdit}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikTextField
                            id="lastName"
                            name="lastName"
                            label="Nume"
                            readOnly={!canEdit}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikTextField
                            id="email"
                            name="email"
                            label="E-mail"
                            readOnly={!canEdit || !isNewUser}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikTextField
                            id="phoneNo"
                            name="numarTelefon"
                            label="Număr de telefon"
                            readOnly={!canEdit}
                        />
                    </Grid>
                    {!adminUserSelected && <Grid item xs={6}>
                        <FormikSelectSimple
                            id="userType"
                            name="userType"
                            label="Tipul utilizatorului"
                            items={userTypeSelectItems}
                            readOnly={!isNewUser}
                        />
                    </Grid>}
                    <Grid item xs={6}>
                        <FormikDatePicker label="Data Nașterii" name="dataNasterii" readOnly={!canEdit} />
                    </Grid>
                    {companyUserSelected && <Grid item xs={12}>
                        <EmailsList limit={5} fieldName="emails" />
                    </Grid>}
                    {companyUserSelected && <Grid item xs={6}>
                        <FormikTextField
                            name="companyName"
                            label="Denumire companie"
                            readOnly={!canEdit}
                        />
                    </Grid>}
                    <Grid item xs={6}>
                        <FormikTextField
                            id="address"
                            name="adresa"
                            label="Adresa"
                            readOnly={!canEdit}
                        />
                    </Grid>
                    {companyUserSelected && <Grid item xs={6}>
                        <FormikTextField id="cnp" name="cnp" label="CNP" readOnly={!canEdit} />
                    </Grid>}
                    <Grid item xs={6}>
                        <FormikTextField id="gen" name="gen" label="Gen" readOnly={!canEdit} />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikSelectSimple
                            id="judet"
                            name="judet"
                            label="Județ"
                            items={countiesSelectItems}
                            readOnly={!canEdit}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikSelectSimple
                            id="zona"
                            name="zona"
                            label="Zona"
                            items={zoneSelectItems}
                            readOnly={!canEdit}
                        />
                    </Grid>
                    {companyUserSelected && <Grid item xs={6}>
                        <FormikSelectSimple
                            id="studii"
                            name="studii"
                            label="Studii"
                            items={studiesSelectItems}
                            readOnly={!canEdit}
                        />
                    </Grid>}
                    {companyUserSelected && <Grid item xs={6}>
                        <FormikSelectSimple
                            id="statut"
                            name="statut"
                            label="Statut"
                            items={jobStatusSelectItems}
                            readOnly={!canEdit}
                        />
                    </Grid>}
                    {companyUserSelected && <Grid item xs={6}>
                        <FormikDatePicker
                            label="Data Recrutare"
                            name="dataRecrutare"
                            readOnly={!canEdit}
                        />
                    </Grid>}

                    {companyUserSelected && <Grid item xs={6}>
                        <FormikDatePicker
                            label="Data Informare"
                            name="dataInformare"
                            readOnly={!canEdit}
                        />
                    </Grid>}
                    {companyUserSelected && <Grid item xs={6}>
                        <FormikTextField id="pf1" name="punctajFaza1" label="Punctaj faza 1" readOnly={!canEdit} />
                    </Grid>}
                    {companyUserSelected && <Grid item xs={6}>
                        <FormikTextField id="pf2" name="punctajFaza2" label="Punctaj faza 2" readOnly={!canEdit} />
                    </Grid>}
                    {companyUserSelected && <Grid item xs={6}>
                        <FormikTextField id="pf3" name="punctajFaza3" label="Punctaj faza 3" readOnly={!canEdit} />
                    </Grid>}
                    {companyUserSelected && <Grid item xs={6}>
                        <FormikTextField id="pt" name="punctajTotal" label="Punctaj total" readOnly={!canEdit} />
                    </Grid>}
                    {companyUserSelected && <Grid item xs={6}>
                        <FormikDatePicker
                            label="Data înregistrare"
                            name="dataInregistrare"
                            readOnly={!canEdit}
                        />
                    </Grid>}
                    {companyUserSelected && <Grid item xs={6}>
                        <FormikTextField id="pt" name="numarInregistrare" label="Număr înregistrare" readOnly={!canEdit} />
                    </Grid>}
                    {companyUserSelected && <><Grid item xs={6}>
                        <FormikTextField
                            id="codFiscal"
                            name="codFiscal"
                            label="CUI"
                            readOnly={!canEdit}
                        />
                    </Grid>

                        <Grid item xs={6}>
                            <FormikTextField
                                id="numeleBancii"
                                name="numeleBancii"
                                label="Numele băncii"
                                readOnly={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikTextField
                                id="contBancar"
                                name="contBancar"
                                label="Cont bancar"
                                readOnly={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikTextField
                                id="reprezentantLegal"
                                name="reprezentantLegal"
                                label="Reprezentant legal"
                                readOnly={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikTextField
                                id="functiaInCompanie"
                                name="functiaInCompanie"
                                label="Funcția în companie"
                                readOnly={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikTextField
                                id="nrContractSubventie"
                                name="nrContractSubventie"
                                label="Număr contract subvenție"
                                readOnly={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikTextField
                                id="agentMonitorizare"
                                name="agentMonitorizare"
                                label="Agent monitorizare"
                                readOnly={!canEdit}
                            />
                        </Grid>
                    </>
                    }
                    {companyUserSelected && <Grid item xs={12} container>
                        <Grid item xs={6}>
                            <FormikCheckBox
                                label="Depunere plan de afaceri"
                                name="depunerePlanAfaceri"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikCheckBox
                                label="Planul de afaceri a fost selectat"
                                name="isGrantApproved"
                                disabled={true}
                            />
                        </Grid>
                    </Grid>}
                    {expertUserSelected && <Grid item xs={6}>
                        <FormikSelectSimple
                            id="selectu"
                            name="expertType"
                            label="Tip expert"
                            items={ExpertTypeSelectItems}
                            readOnly={!canEdit}
                        />
                    </Grid>}
                    {canEdit && <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="contained" type="submit">Salvează</Button>
                    </Grid>}
                </Grid>
            </Grid>
        </Container>
    );
};
