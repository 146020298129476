import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig } from "store";

export const requestTemplateInfoThunk_name = "templates/templateInfo";
export const requestTemplateInfoThunk = createAsyncThunk<
    any,
    void,
    AppThunkConfig
>(
    requestTemplateInfoThunk_name,
    //signal is to be used later, now just leave it there to avoid lint warnings
    async (params, { signal }) => {
        return new Promise(resolve => setTimeout(resolve, 1000));
    }
);