import { Article, Delete, Visibility } from "@mui/icons-material";
import { Button, ButtonGroup, Checkbox, Container, Grid, Tooltip } from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridSortModel,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
    requestGetAllCompanyUsers,
    selectCurrentUser,
    selectUsersListIsLoading,
    selectSortModel,
    selectUsers,
    selectUsersRowCount,
    useAppDispatch,
    useAppSelector,
    selectCompanyUsersNames,
    requestCompanyUsersNames,
    selectSelectedTargetGroupUser,
    CompanyUserBriefModel,
} from "store";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { mapColumnNameToEnum } from "utils/mapColumnNametoEnum";
import "./UsersPage.scss";
import { setDeletingUser, setDeletingUserId, setSelectedTargetGroupUser, setSortModel } from "store/slices/targetGroupSlice";
import { UserType } from "store/models/enums/UserType";
import { newUserPath } from "navigation";
import { UserDeleteConfirmationDialog } from "pages/users-page/confirmationDialog/UserDeleteConfirmationDialog";
import { dateTimeFormatOptions } from "utils";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import AutocompleteSearch from "components/shared/autocompleteSearch/AutocompleteSearch";
import { addAppNotification } from "store/slices/appNotificationSlice";

export const UsersPage: FC = () => {
    const users = useAppSelector(selectUsers);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const sortModel = useAppSelector(selectSortModel);
    const rowCount = useAppSelector(selectUsersRowCount);
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const usersListIsLoading = useAppSelector(selectUsersListIsLoading);
    const companyUsersNames = useAppSelector(selectCompanyUsersNames);
    const selectedTargetGroupUser = useAppSelector(selectSelectedTargetGroupUser);
    const [userSearchedText, setUserSearchedText] = useState<string>("");
    const [displayAutocompleteDropdown, setDisplayAutocompleteDropdown] = useState<boolean>(false);
    const [onlyRegisteredUsers, setOnlyRegisteredUsers] = useState<boolean>(false);

    const rows = users;
    const isExpert = currentUser?.userType === UserType.ADMIN;
    useEffect(() => {
        dispatch(setSelectedTargetGroupUser(null));
        dispatch(requestCompanyUsersNames({
            token: currentUser?.jwtToken,
            isVersion2: false,
        }));
        return () => {
            dispatch(setSelectedTargetGroupUser(null));
        }
    }, []);
    useEffect(() => {
        let column = mapColumnNameToEnum(sortModel.columnToSortBy);
        let sort = false;
        if (sortModel.sortingOrder === "asc") sort = true;
        const promise = dispatch(
            requestGetAllCompanyUsers({
                filter: {
                    pageNumber: currentPageNumber,
                    columnToSortBy: column,
                    sortingOrder: sort,
                    searchText: checkIfSelectedUserNull(),
                    onlyRegisteredUsers: onlyRegisteredUsers
                },
                token: currentUser?.jwtToken,
            })
        );
        return () => {
            promise.abort();
        };
    }, [currentPageNumber, onlyRegisteredUsers]);


    useEffect(() => {
        if (selectedTargetGroupUser !== null && selectedTargetGroupUser !== undefined && selectedTargetGroupUser !== 0) {

            let promise = dispatch(requestGetAllCompanyUsers({
                filter: {
                    pageNumber: 0,
                    columnToSortBy: DataGridColumnNames.CreatedAt,
                    sortingOrder: false,
                    searchText: checkIfSelectedUserNull(),
                    onlyRegisteredUsers: onlyRegisteredUsers
                },
                token: currentUser?.jwtToken,
            }));
            return () => promise.abort();
        } else {
            dispatch(requestGetAllCompanyUsers({
                filter: {
                    pageNumber: 0,
                    columnToSortBy: DataGridColumnNames.CreatedAt,
                    sortingOrder: false,
                    searchText: checkIfSelectedUserNull(),
                    onlyRegisteredUsers: onlyRegisteredUsers
                },
                token: currentUser?.jwtToken,
            }));
        }
    }, [selectedTargetGroupUser]);

    const handlePageChange = (pageNumber: any) => {
        setCurrentPageNumber(pageNumber);
    }

    const isAdminUser = () => {
        if (currentUser?.userType === UserType.ADMIN) {
            return true;
        } else return false;
    }

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length > 0) {
            dispatch(setSortModel({
                columnToSortBy: newModel[0].field,
                sortingOrder: newModel[0].sort
            }));
            let column = mapColumnNameToEnum(newModel[0].field);
            let sort = false;
            if (newModel[0].sort === "asc") sort = true;
            dispatch(
                requestGetAllCompanyUsers({
                    filter: {
                        pageNumber: currentPageNumber,
                        columnToSortBy: column,
                        sortingOrder: sort,
                        searchText: checkIfSelectedUserNull(),
                        onlyRegisteredUsers: onlyRegisteredUsers
                    },
                    token: currentUser?.jwtToken,
                })
            );

        } else {
            dispatch(setSortModel({
                columnToSortBy: "createdAt",
                sortingOrder: "desc"
            }));
            dispatch(
                requestGetAllCompanyUsers({
                    filter: {
                        pageNumber: currentPageNumber,
                        columnToSortBy: DataGridColumnNames.CreatedAt,
                        sortingOrder: false,
                        searchText: checkIfSelectedUserNull(),
                        onlyRegisteredUsers: onlyRegisteredUsers
                    },
                    token: currentUser?.jwtToken,
                })
            );
        }
    };

    const columns: GridColDef[] = [
        { field: "firstName", headerName: "Prenume", flex: 1, minWidth: 150 },
        { field: "lastName", headerName: "Nume", flex: 1, minWidth: 150 },
        {
            field: "createdAt",
            headerName: "Data creat",
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let newDate = new Date(params.value as string);
                    return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
                }
            },
        },
        {
            field: "updated",
            headerName: "Data actualizat",
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let newDate = new Date(params.value as string);
                    return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
                }
            },
        },
        { field: "updatedByUserName", headerName: "Actualizat de către", flex: 1, minWidth: 150 },
        {
            field: "",
            headerName: "Opțiuni",
            sortable: false,
            flex: 1,
            minWidth: 150,
            align: "right",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup size="small" sx={{
                        "& > *:not(:last-child)": {
                            marginRight: .5
                        }
                    }} >
                        <Tooltip title="Vizualizare concurs plan de afaceri">
                            <Button
                                variant="contained"
                                sx={{ borderRadius: "4px !important" }}
                                onClick={(e) => {
                                    navigate("/planafaceri/" + params.rowNode.id);
                                }}
                            >
                                <Article />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Vizualizare Utilizator">
                            <Button
                                variant="contained"
                                sx={{ borderRadius: "4px !important" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate("/user/" + params.rowNode.id);
                                    dispatch(setSelectedTargetGroupUser(null));
                                }}
                            >
                                <Visibility />
                            </Button>
                        </Tooltip>
                        {isAdminUser() && <Tooltip title="Șterge Utilizator">
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ borderRadius: "4px !important" }}
                                onClick={() => {
                                    dispatch(setDeletingUserId(params.rowNode.id))
                                    dispatch(setDeletingUser(true));

                                }}>
                                <Delete />
                            </Button>
                        </Tooltip>}
                    </ButtonGroup>
                );
            },
        },
    ];

    const getOptionLabel = (user: any): string => {
        return (user.lastName || "") + (user.lastName ? " " : "") + (user.firstName || "");
    };

    const onInputChange = (event: any, value: any) => {
        if (event !== null) {
            if (value === "" && event.type === "change") {
                dispatch(setSelectedTargetGroupUser(null));
                handleSelectUser(event, value);

            }
            if (value.length % 3 === 0 && event.type === "change") {

                handleSelectUser(event, value);
                setUserSearchedText(value);
            }
        }

    };
    const onKeyPress = (event: any, value: any) => {
        if (event.key === "Enter") {
            handleSelectUser(event, event.target.value);
        }
    }

    const handleSelectUser = (e: any, values: any) => {
        if (values !== null && values !== undefined && typeof values !== "string") {
            setDisplayAutocompleteDropdown(false);
            dispatch(setSelectedTargetGroupUser({
                userId: values.userId,
                firstName: values.firstName,
                lastName: values.lastName,
                isGrantApproved: values.isGrantApproved,
                isGrantApprovedV2: values.isGrantApprovedV2
            }));
        }

        if (typeof values === "string" && e.type === "change") {
            dispatch(
                requestGetAllCompanyUsers({
                    filter: {
                        pageNumber: currentPageNumber,
                        columnToSortBy: DataGridColumnNames.CreatedAt,
                        sortingOrder: false,
                        searchText: values,
                        onlyRegisteredUsers: onlyRegisteredUsers
                    },
                    token: currentUser?.jwtToken,
                })
            );
        }

        if (typeof values === "string" && e.key === "Enter") {
            let usersFound = 0;
            let userFound: CompanyUserBriefModel = {}

            for (let user of companyUsersNames) {
                if ((user.lastName + " " + user.firstName).toLowerCase().includes(values.toLowerCase())) {
                    usersFound++;
                    userFound = {
                        userId: user.userId,
                        lastName: user.lastName,
                        firstName: user.firstName,
                        isGrantApproved: user.isGrantApproved,
                        isGrantApprovedV2: user.isGrantApprovedV2
                    };
                }
            }
            if (usersFound === 0) {
                dispatch(addAppNotification({
                    message: "Nu a fost găsit nici un rezultat pentru căutarea dorită!",
                    severity: "warning",
                }))
            }
            if (usersFound === 1) {
                dispatch(setSelectedTargetGroupUser({
                    userId: userFound.userId,
                    firstName: userFound.firstName,
                    lastName: userFound.lastName,
                    isGrantApproved: userFound.isGrantApproved,
                    isGrantApprovedV2: userFound.isGrantApprovedV2
                }));
                setDisplayAutocompleteDropdown(false);
            }
            if (usersFound > 1) {
                dispatch(addAppNotification({
                    message: "Selectați unul dintre utilizatori!",
                    severity: "warning",
                }))
            }
        }
    };
    const checkIfSelectedUserNull = (): string => {
        if (selectedTargetGroupUser === null || selectedTargetGroupUser === undefined) {
            return userSearchedText;
        } else {
            return selectedTargetGroupUser.lastName + " " + selectedTargetGroupUser.firstName
        }
    }
    return (
        <Container
            id="users-page"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid
                container
                sx={{
                    minHeight: "inherit",
                    p: {
                        xs: 1,
                        md: 2,
                    },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch"
                }}
                spacing={1}
            >
                <Grid item sx={{
                    display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: {
                        xs: "column", sm: "column", md: "row"
                    }
                }}>
                    <Grid display="flex" width={{ xs: '100%', sm: 400 }}>
                        <Grid width={{ xs: '80%', sm: "80%", md: 175 }}>
                            <AutocompleteSearch
                                options={companyUsersNames}
                                value={selectedTargetGroupUser}
                                getOptionLabel={getOptionLabel}
                                renderOption={(props: any, option: any) => {
                                    return (
                                        <li {...props} key={option.userId}>
                                            {option.lastName + " " + option.firstName}
                                        </li>
                                    );
                                }}
                                onType={handleSelectUser}
                                isOptionEqualToValue={(option: any, value: any) => option.userId === value.userId}
                                onInputChange={onInputChange}
                                onKeyPress={onKeyPress}
                                open={displayAutocompleteDropdown}
                                onOpen={(e: any) => { setDisplayAutocompleteDropdown(true) }}
                                onClose={(e: any) => { setDisplayAutocompleteDropdown(false) }}

                            />
                        </Grid>
                        <Grid display="flex" alignItems="center">
                            <Checkbox value={onlyRegisteredUsers} onChange={(e) => setOnlyRegisteredUsers(!onlyRegisteredUsers)} /><>Beneficiari validați</>
                        </Grid>
                    </Grid>
                    <Grid sx={{ width: { xs: '100%', sm: 400, md: 'inherit' } }}>
                        <Button sx={{ marginRight: 1, fontSize: "0.9rem", whiteSpace: "nowrap", width: { xs: "100%", sm: "100%", md: 175 }, marginTop: { xs: 1, md: 0, xl: 0, lg: 0 } }} variant="contained" onClick={() => { navigate(newUserPath, { state: { userType: UserType.COMPANY_USER } }); dispatch(setSelectedTargetGroupUser(null)); }}>Adaugă utilizator</Button>
                        {isExpert && <Button sx={{ fontSize: "0.9rem", whiteSpace: "nowrap", width: { xs: "100%", sm: "100%", md: 175 }, marginTop: { xs: 1, md: 0, xl: 0, lg: 0 } }} variant="contained" color="warning" onClick={() => { navigate(newUserPath, { state: { userType: UserType.EXPERT } }); dispatch(setSelectedTargetGroupUser(null)); }}>Adaugă expert</Button>}
                    </Grid>
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "stretch", flexGrow: 1, }}>
                    <DataGrid
                        components={{
                            LoadingOverlay: DataLoadingComponent
                        }}
                        loading={usersListIsLoading}
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        sx={{ flexGrow: 1, height: 630 }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableColumnMenu={true}
                        paginationMode="server"
                        onPageChange={handlePageChange}
                        page={currentPageNumber}
                        rowCount={rowCount}
                    ></DataGrid>
                </Grid>
            </Grid>
            <UserDeleteConfirmationDialog onlyRegisteredUsers={onlyRegisteredUsers} />
        </Container>
    );
};
