import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig } from "store";
import { CommunicationFilter } from "store/models/communication";
import { UserContactFilter } from "store/models/user/UserContactModel";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { hideOverlay, showOverlay } from "store/slices/overlayLoaderSlice";
import { baseUrl, getAxiosErrorMessage } from "utils";
const axios = require("axios").default;

export const requestGetContacts = createAsyncThunk<
    any,
    {
        filter: UserContactFilter;
        token: string | null | undefined;
    },
    AppThunkConfig
>("communication/getContacts", async ({ filter: userContactFilter, token }, thunkAPI) => {
    try {
        let { data } = await axios.get(
            baseUrl + "UserCommunication/GetContacts",
            {
                params: {
                    pageNumber: userContactFilter.pageNumber.toString(),
                    columnToSortBy: userContactFilter.columnToSortBy,
                    sortingOrder: userContactFilter.sortingOrder,
                    userId: userContactFilter.userId?.toString(),
                    searchString: userContactFilter.searchString,
                },
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return data.response;
    } catch (err: any) {
        let errorMessage = getAxiosErrorMessage(err);
        if (errorMessage) {
            thunkAPI.dispatch(
                addAppNotification({
                    message: errorMessage,
                    severity: "error",
                })
            );
            return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
        } else {
            throw err;
        }
    }
});

export const requestGetAllContacts = createAsyncThunk<
    any,
    any,
    AppThunkConfig
>("communication/getAllContacts", async ({ token }, thunkAPI) => {
    try {
        let { data } = await axios.get(
            baseUrl + "UserCommunication/GetAllContacts",
            {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return data.response;
    } catch (err: any) {
        let errorMessage = getAxiosErrorMessage(err);
        if (errorMessage) {
            thunkAPI.dispatch(
                addAppNotification({
                    message: errorMessage,
                    severity: "error",
                })
            );
            return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
        } else {
            throw err;
        }
    }
});

export const requestGetReceivedCommunications = createAsyncThunk<
    any,
    {
        filter: CommunicationFilter;
        token: string | null | undefined;
    },
    AppThunkConfig
>(
    "communication/getReceivedCommunications",
    async ({ filter: communicationFilter, token }, thunkAPI) => {
        try {
            let { data } = await axios.get(
                baseUrl + "UserCommunication/GetCommunicationsReceived",
                {
                    params: {
                        pageNumber: communicationFilter.pageNumber.toString(),
                        columnToSortBy: communicationFilter.columnToSortBy,
                        sortingOrder: communicationFilter.sortingOrder,
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestGetSentCommunications = createAsyncThunk<
    any,
    {
        filter: CommunicationFilter;
        token: string | null | undefined;
    },
    AppThunkConfig
>(
    "communication/getSentCommunications",
    async ({ filter: communicationFilter, token }, thunkAPI) => {
        try {
            let { data } = await axios.get(
                baseUrl + "UserCommunication/GetCommunicationsSent",
                {
                    params: {
                        pageNumber: communicationFilter.pageNumber.toString(),
                        columnToSortBy: communicationFilter.columnToSortBy,
                        sortingOrder: communicationFilter.sortingOrder,
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestSendCommunication = createAsyncThunk<
    any,
    {
        requestData: {
            senderId: number | null | undefined;
            subject: string;
            message: string;
            files: Array<File>;
            receiversIds: number[] | null | undefined;
        };
        token: string | null | undefined;
    },
    AppThunkConfig
>(
    "communication/sendCommunication",
    async ({ requestData, token }, thunkAPI) => {
        thunkAPI.dispatch(showOverlay());
        try {
            let formData = new FormData();

            formData.append(
                "senderId",
                requestData?.senderId?.toString() ?? ""
            );
            formData.append("subject", requestData.subject);
            formData.append("message", requestData.message);
            if (requestData.receiversIds) {
                requestData.receiversIds.forEach((id, index) => {
                    formData.append(
                        "receiversIds[" + index + "]",
                        id.toString()
                    );
                });
            }
            if (requestData.files && requestData.files.length > 0) {
                requestData.files.forEach((file) =>
                    formData.append("files", file)
                );
            }

            let { data } = await axios.post(
                baseUrl + "UserCommunication/SendCommunication",
                formData,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        data?.response?.message?.text ?? "Comunicare trimisă!",
                    severity: "success",
                })
            );
            thunkAPI.dispatch(hideOverlay());
            return data.response;
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestDeleteCommunication = createAsyncThunk<
    any,
    {
        requestData: {
            userLoggedInId: number | undefined;
            communicationId: number;
        };
        token: string | null | undefined;
    },
    AppThunkConfig
>(
    "communication/deleteCommunication",
    async ({ requestData, token }, thunkAPI) => {
        try {
            let formData = new FormData();
            formData.append(
                "userLoggedInId",
                requestData?.userLoggedInId?.toString() ?? ""
            );
            formData.append(
                "communicationId",
                requestData?.communicationId?.toString() ?? ""
            );
            let { data } = await axios.post(
                baseUrl + "UserCommunication/DeleteCommunication",
                formData,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        data?.response?.message?.text ??
                        "Comunicare eliminată!",
                    severity: "success",
                })
            );
            return data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestDownloadAttachment = createAsyncThunk<
    any,
    {
        requestData: { fileId: number; fileTitle: string };
        token: string | null | undefined;
    },
    AppThunkConfig
>(
    "communication/downloadAttachment",
    async ({ requestData, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "UserCommunication/DownloadCommunicationDocument",
                {
                    responseType: "blob",
                    params: { fileId: requestData.fileId },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);
