
export const mapManageDocumentsTabstoEnum = (tab?: number) => {
    switch (tab) {
        case 1: {
            return "technical";
        }
        case 2: {
            return "financial";
        }
        case 3: {
            return "social"
        }
        case 4: {
            return "acquisitions";
        }
        case 5: {
            return "employment-contracts";
        }
        case 6: {
            return "extra-docs";
        }
        default: {
            return "technical";
        }
    }
};

export const mapManageDocumentsTabstoInt = (tab?: string) => {
    switch (tab) {
        case "technical": {
            return 1;
        }
        case "financial": {
            return 2;
        }
        case "social": {
            return 3;
        }
        case "acquisitions": {
            return 4;
        }
        case "employment-contracts": {
            return 5;
        }
        case "extra-docs": {
            return 6;
        }
        default: {
            return 1;
        }
    }

};

export const mapSubcategoryToLabel = (subcategory: string): string => {
    switch (subcategory) {
        case "decontCheltuieli": {
            return "Decont cheltuieli";
        }
        case "registruSalariati": {
            return "Registru salariați";
        }
        case "raportSalariat": {
            return "Raport salariat";
        }
        case "obiecteInventar": {
            return "Obiecte inventar";
        }
        case "registruImobilizari": {
            return "Registru imobilizări";
        }
        case "registruJurnalVanzari": {
            return "Registru jurnal vânzări";
        }
        case "balantaAnalitica": {
            return "Balanță analitică";
        }
        case "balantaGenerala": {
            return "Balanță generală";
        }
        case "alteDocumenteFinanciare": {
            return "Ate documente financiare";
        }
        case "solicitarePlatiDecont": {
            return "Solicitare plăți decont";
        }
        case "solicitareRestituireCreditare": {
            return "Solicitare restituire creditare";
        }
        case "executieBugetara": {
            return "Execuție bugetară";
        }
        case "documenteJustificative": {
            return "Documente justificative";
        }
        case "raportActivitate": {
            return "Raport activitate";
        }
        case "documenteSuplimentare": {
            return "Documente suplimentare";
        }
        case "dosareAchizitii": {
            return "Dosare achiziții";
        }
        case "solicitareModificari": {
            return "Modificări solicitate";
        }
        case "proceduraAchizitii": {
            return "Procedură achiziții";
        }
        case "formulareAchizitii": {
            return "Formulare achiziții";
        }
        case "proceduraContract": {
            return "Procedură contract";
        }
        case "formulareContract": {
            return "Formulare contract";
        }
        case "NOTE_VALIDARE_DECONT": {
            return "Note validare decont";
        }
        case "NOTE_VALIDARE_SOLICITARE": {
            return "Note validare solicitare";
        }
        case "NOTE_VALIDARE_ACHIZITII": {
            return "Note validare achiziții";
        }

    }
    return "";
}

export const mapSubcategoryToEnum = (subcategory: string): number => {
    switch (subcategory) {
        case "decontCheltuieli": {
            return 7;
        }
        case "registruSalariati": {
            return 4;
        }
        case "raportSalariat": {
            return 3;
        }
        case "obiecteInventar": {
            return 6;
        }
        case "registruImobilizari": {
            return 5;
        }
        case "registruJurnalVanzari": {
            return 21;
        }
        case "balantaAnalitica": {
            return 1;
        }
        case "balantaGenerala": {
            return 2;
        }
        case "alteDocumenteFinanciare": {
            return 24;
        }
        case "solicitarePlatiDecont": {
            return 22;
        }
        case "solicitareRestituireCreditare": {
            return 23;
        }
        case "executieBugetara": {
            return 25;
        }
        case "documenteJustificative": {
            return 11;
        }
        case "raportActivitate": {
            return 10;
        }
        case "documenteSuplimentare": {
            return 26;
        }
        case "dosareAchizitii": {
            return 14;
        }
        case "solicitareModificari": {
            return 20;
        }
        case "proceduraAchizitii": {
            return 12;
        }
        case "formulareAchizitii": {
            return 13;
        }
        case "proceduraContract": {
            return 18;
        }
        case "formulareContract": {
            return 19;
        }
        case "NOTE_VALIDARE_DECONT": {
            return 27;
        }
        case "NOTE_VALIDARE_SOLICITARE": {
            return 28;
        }
        case "NOTE_VALIDARE_ACHIZITII": {
            return 29;
        }

    }
    return 0;
}