import { Container, Grid } from '@mui/material';
import AutocompleteSearch from 'components/shared/autocompleteSearch/AutocompleteSearch';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { CompanyUserBriefModel, requestCompanyUsersNames, selectCompanyUsersNames, selectCurrentUser, selectSelectedUser, useAppDispatch, useAppSelector } from 'store';
import { UserType } from 'store/models/enums/UserType';
import { addAppNotification } from 'store/slices/appNotificationSlice';
import { setAnexa1Phase2Documents, setIsGrantApprovedForSelectedUser, setSelectedUser, setUserAlteDocumentePhase2Documents, setUserAnexa2Phase2Documents, } from 'store/slices/grantContestSlice';
import { Annexe1Component } from './annexe1/Annexe1Component';
import { Annexe2Component } from './annexe2/Annexe2Component';
import { ApproveGrantPopout } from './approve-grant-popout/ApproveGrantPopout';
import './GrantContestPhase2Component.scss';
import { OtherDocumentsComponent } from './other-documents/OtherDocumentsComponent';

export const GrantContestPhase2Component: FC<any> = ({ isVersion2 }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentUser = useAppSelector(selectCurrentUser);
    const companyUsersNames = useAppSelector(selectCompanyUsersNames);
    const selectedUser = useAppSelector(selectSelectedUser);
    const [displayAutocompleteDropdown, setDisplayAutocompleteDropdown] = useState<boolean>(false);


    useEffect(() => {
        if (selectedUser !== null) {
            if (isVersion2) {
                navigate(`/planafaceri/${selectedUser.userId}`)
            } else {
                navigate(`/planafaceri/v1/${selectedUser.userId}`)
            }

        }
        if (currentUser?.userType === UserType.EXPERT || currentUser?.userType === UserType.ADMIN) {
            dispatch(requestCompanyUsersNames({
                filterByRegistrationNumber: false,
                token: currentUser?.jwtToken,
                isVersion2: false,
            }))
        }
    }, []);

    useEffect(() => {
        if (selectedUser !== null) {
            localStorage.setItem('userId', JSON.stringify(selectedUser.userId));
        }
    }, [selectedUser])

    const handleSelectUser = (e: any, values: any) => {
        if (values !== null && values !== undefined && typeof values !== "string") {
            setDisplayAutocompleteDropdown(false)
            dispatch(setSelectedUser({
                userId: values.userId,
                firstName: values.firstName,
                lastName: values.lastName,
                isGrantApproved: values.isGrantApproved,
                isGrantApprovedV2: values.isGrantApprovedV2
            }));
            dispatch(setIsGrantApprovedForSelectedUser(isVersion2 ? values.isGrantApprovedV2 : values.isGrantApproved));
            if (isVersion2) {
                navigate(`/planafaceri/${values.userId}`)
            } else {
                navigate(`/planafaceri/v1/${values.userId}`)
            }

        }
        if (typeof values === "string" && e.key === "Enter") {
            let usersFound = 0;
            let userFound: CompanyUserBriefModel = {}

            for (let user of companyUsersNames) {
                if ((user.lastName + " " + user.firstName).toLowerCase().includes(values.toLowerCase())) {
                    usersFound++;
                    userFound = {
                        userId: user.userId,
                        lastName: user.lastName,
                        firstName: user.firstName,
                        isGrantApproved: user.isGrantApproved,
                        isGrantApprovedV2: user.isGrantApprovedV2
                    };
                }
            }
            if (usersFound === 0) {
                setDisplayAutocompleteDropdown(false)
                dispatch(setAnexa1Phase2Documents({
                    anexa1documents: [],
                    rowCount: 0,
                }))
                dispatch(setUserAnexa2Phase2Documents({
                    anexa2documents: [],
                    rowCount: 0,
                }))
                dispatch(setUserAlteDocumentePhase2Documents({
                    altedocumente: [],
                    rowCount: 0,
                }))
                dispatch(addAppNotification({
                    message: "Nu a fost găsit nici un rezultat pentru căutarea dorită!",
                    severity: "warning",
                }))
            }
            if (usersFound === 1) {
                setDisplayAutocompleteDropdown(false)
                dispatch(setSelectedUser({
                    userId: userFound.userId,
                    firstName: userFound.firstName,
                    lastName: userFound.lastName,
                    isGrantApproved: userFound.isGrantApproved,
                    isGrantApprovedV2: userFound.isGrantApprovedV2
                }));
            }
            if (usersFound > 1) {
                dispatch(addAppNotification({
                    message: "Selectați unul dintre utilizatori!",
                    severity: "warning",
                }))
            }
        }
    };


    const onInputChange = (event: any, value: any) => {
        if (event !== null) {
            if (value === "" && event.type === 'change') {
                localStorage.removeItem('userId')
                dispatch(setSelectedUser(null));
                if (isVersion2) {
                    navigate('/planafaceri/' + currentUser?.userId)
                } else {
                    navigate('/planafaceri/v1/' + currentUser?.userId)
                }
            }
            if (event.key === "Enter") {
                handleSelectUser(event, selectedUser)
            }
        }

    }
    const onKeyPress = (event: any, value: any) => {
        if (event.key === "Enter") {
            handleSelectUser(event, event.target.value)
        }
    }

    const getOptionLabel = (user: any): string => {
        return (user.lastName || "") + (user.lastName ? " " : "") + (user.firstName || "");
    }
    return (
        <Container
            id="grant-contest-phase2-view"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid
                container
                sx={{
                    minHeight: "inherit",
                    p: {
                        xs: 1,
                        md: 2,
                    },
                }}
                alignItems="stretch"
            >
                {
                    currentUser?.userType !== UserType.COMPANY_USER ?
                        <Grid container marginBottom={1} justifyContent="flex-end" alignItems="center" >
                            <ApproveGrantPopout isVersion2={isVersion2} />
                            <Grid item sx={{ marginLeft: 1 }}>
                                <AutocompleteSearch
                                    width={175}
                                    options={companyUsersNames}
                                    value={selectedUser}
                                    getOptionLabel={getOptionLabel}
                                    renderOption={(props: any, option: any) => {
                                        return (
                                            <li {...props} key={option.userId}>
                                                {option.lastName + " " + option.firstName}
                                            </li>
                                        );
                                    }}
                                    onType={handleSelectUser}
                                    isOptionEqualToValue={(option: any, value: any) => option.userId === value.userId}
                                    onInputChange={onInputChange}
                                    onKeyPress={onKeyPress}
                                    open={displayAutocompleteDropdown}
                                    onOpen={(e: any) => { setDisplayAutocompleteDropdown(true) }}
                                    onClose={(e: any) => { setDisplayAutocompleteDropdown(false) }}
                                />
                            </Grid>
                        </Grid> : <Grid></Grid>
                }
                <Grid item xs={12} marginBottom={3}><Annexe1Component isVersion2={isVersion2} selectedUserId={selectedUser?.userId} /></Grid>
                <Grid item xs={12} marginBottom={3}><Annexe2Component isVersion2={isVersion2} selectedUserId={selectedUser?.userId} /></Grid>
                <Grid item xs={12} marginBottom={3}><OtherDocumentsComponent isVersion2={isVersion2} selectedUserId={selectedUser?.userId} /></Grid>
            </Grid>
        </Container>
    )
}