import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  actions: {},
  isLoading: false,
  loadings: 0,
};

export const overlayLoaderSlice = createSlice({
  name: 'overlayLoaderSlice',
  initialState: initialState,
  reducers: {
    showOverlay(state) {
      state.loadings = state.loadings + 1;
      state.isLoading = true;
    },
    hideOverlay(state) {
      state.loadings = state.loadings - 1;
      if (state.loadings === 0) {
        state.isLoading = false;
      }

    }
  }
})

export const {
  showOverlay,
  hideOverlay
} = overlayLoaderSlice.actions;