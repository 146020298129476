import { Container, Grid, Tab, Tabs } from "@mui/material";
import { FC, useState } from "react";
import "./VocationalTrainingPage.scss";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import locale from "date-fns/locale/ro";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { VocationalTrainingFormsView } from "./vocational-training-forms-view";
import { VocationalTrainingExamResultsView } from "./vocational-training-exam-results-view";
import { VocationalTrainingExtraFiles } from "./vocational-training-extra-files";
import { CourseSeriesView } from "../../components/vocational-training";
import { selectCurrentUser, useAppSelector } from "store";
import { UserType } from "store/models/enums/UserType";

export const VocationalTrainingPage: FC = () => {
    const [activeTab, setActiveTab] = useState(0);
    const currentUser = useAppSelector(selectCurrentUser);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <Container
            id="vocational-training-page"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <Grid
                    container
                    sx={{
                        minHeight: "inherit",
                    }}
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={12}
                        container
                        direction="column"
                        alignItems="stretch"
                    >
                        <Grid item xs="auto">
                            <Tabs value={activeTab} onChange={handleChange}>
                                <Tab label="Formulare formare profesionala" />
                                {currentUser?.userType!== UserType.COMPANY_USER ? <Tab label="Serii de curs" /> : <Tab sx={{display : "none"}}/>} 
                                <Tab label="Rezultate examen" />
                                <Tab label="Materiale formare profesională" />
                            </Tabs>
                        </Grid>
                        <Grid item flexGrow={1}>
                            {activeTab === 0 && <VocationalTrainingFormsView />}
                            {activeTab === 1 && <CourseSeriesView />}
                            {activeTab === 2 && <VocationalTrainingExamResultsView />}
                            {activeTab === 3 && <VocationalTrainingExtraFiles />}
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </Container>
    );
};
