import { HomePage } from "pages/home-page";
import { LoginPage } from "pages/login-page";
import { UsersPage } from "pages/users-page";
import { UserDetailsPage } from "pages/user-details-page";
import { CommunicationPage } from "pages/communication-page/CommunicationPage";
import { VocationalTrainingPage } from "pages/vocational-training-page/VocationalTrainingPage";
import { ForgotPasswordPage } from "pages/forgot-password-page/ForgotPasswordPage";
import { ResetPasswordPage } from "pages/reset-password-page/ResetPasswordPage";
import {
    communicationPath,
    forgotPasswordPath,
    grantContestPath,
    grantContestv1Path,
    homePath,
    loginPath,
    newUserPath,
    resetPasswordPath,
    userDetailsPath,
    usersPath,
    verifyEmailPath,
    vocationalTrainingPath,
    manageDocumentsPath,
} from "./constants";
import { UserType } from "../store/models/enums/UserType";
import { GrantContestPage } from "pages/grant-contest-page";
import { VerifyTokenRedirectComponent } from "components/shared/verifyTokenRedirect/VerifyTokenRedirectComponent";
import { ManageDocumentsComponent } from "pages/manageDocuments-page/ManageDocumentsComponent";

export interface BaseRoute {
    path: string;
    component: any;
    approvedRoles?: Array<UserType>;
}

export const homePageRoute: BaseRoute = {
    path: homePath,
    component: HomePage,
    approvedRoles: [UserType.ADMIN, UserType.EXPERT, UserType.COMPANY_USER],
};

export const loginPageRoute: BaseRoute = {
    path: loginPath,
    component: LoginPage,
};

export const forgotPasswordRoute: BaseRoute = {
    path: forgotPasswordPath,
    component: ForgotPasswordPage,
};

export const resetPasswordRoute: BaseRoute = {
    path: resetPasswordPath,
    component: ResetPasswordPage,
};

export const usersPageRoute: BaseRoute = {
    path: usersPath,
    component: UsersPage,
    approvedRoles: [UserType.ADMIN, UserType.EXPERT],
};

export const userDetailsPageRoute: BaseRoute = {
    path: userDetailsPath,
    component: UserDetailsPage,
    approvedRoles: [UserType.ADMIN, UserType.EXPERT, UserType.COMPANY_USER],
};

export const newUserPageRoute: BaseRoute = {
    path: newUserPath,
    component: UserDetailsPage,
    approvedRoles: [UserType.ADMIN, UserType.EXPERT],
};

export const communicationPageRoute: BaseRoute = {
    path: communicationPath,
    component: CommunicationPage,
    approvedRoles: [UserType.ADMIN, UserType.EXPERT, UserType.COMPANY_USER],
};

export const vocationalTrainingRoute: BaseRoute = {
    path: vocationalTrainingPath,
    component: VocationalTrainingPage,
    approvedRoles: [UserType.ADMIN, UserType.EXPERT, UserType.COMPANY_USER],
};

export const grantContestRoute: BaseRoute = {
    path: grantContestPath,
    component: GrantContestPage,
    approvedRoles: [UserType.ADMIN, UserType.EXPERT, UserType.COMPANY_USER],
};
export const grantContestV1Route: BaseRoute = {
    path: grantContestv1Path,
    component: GrantContestPage,
    approvedRoles: [UserType.ADMIN, UserType.EXPERT],
};

export const verifyEmailRoute: BaseRoute = {
    path: verifyEmailPath,
    component: VerifyTokenRedirectComponent,
}

export const manageDocumentsRoute: BaseRoute = {
    path: manageDocumentsPath,
    component: ManageDocumentsComponent,
    approvedRoles: [UserType.ADMIN, UserType.EXPERT, UserType.COMPANY_USER],
};


export const routes = [
    homePageRoute,
    loginPageRoute,
    usersPageRoute,
    userDetailsPageRoute,
    communicationPageRoute,
    vocationalTrainingRoute,
    forgotPasswordRoute,
    resetPasswordRoute,
    newUserPageRoute,
    grantContestRoute,
    verifyEmailRoute,
    grantContestV1Route,
    manageDocumentsRoute,
];
