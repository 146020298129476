export enum Subcategory {
  BALANTA_ANALITICA = 1,
  BALANTA_GENERALA,
  RAPORT_SALARIAT,
  REGISTRU_SALARIATI,
  REGISTRU_IMOBILIZARI,
  OBIECTE_INVENTAR,
  DECONT_CHELTUIELI,
  SOLICITARE_AUTORIZARE_DECONT,
  SOLICITARE_AUTORIZARE_CREDITARE,
  RAPORT_ACTIVITATE,
  DOCUMENTE_JUSTIFICATIVE,
  PROCEDURA_ACHIZITII,
  FORMULARE_ACHIZITII,
  DOSARE_ACHIZITII,
  ACTE_CONSTITUTIVE,
  PLAN_DE_AFACERI,
  BUGET,
  PROCEDURA_CONTRACT,
  FORMULARE_CONTRACT,
  SOLICITARE_MODIFICARI,
  REGISTRU_JURNAL_VANZARI,
  SOLICITARE_PLATI_DECONT,
  SOLICITARE_RESTITUIRE_CREDITARE,
  ALTE_DOCUMENTE_FINANCIARE,
  EXECUTIE_BUGETARA,
  SUPLIMENTAR,
  NOTE_VALIDARE_DECONT,
  NOTE_VALIDARE_SOLICITARE,
  NOTE_VALIDARE_ACHIZITII,
}