import { FC, useEffect, useState } from "react";
import { Grid, Box, Typography, Chip, IconButton } from "@mui/material";
import {
    setCommunicationDetails,
    setContactsSelectionModel,
    setDisplayDialog,
    setReceivers,
} from "store/slices/communicationSlice";
import "./SendOrViewCommunication.scss";
import { Send, Cancel, Reply, Download } from "@mui/icons-material";
import {
    selectCommunicationDetails,
    useAppSelector,
    useAppDispatch,
    selectCurrentUser,
    requestSendCommunication,
    requestDownloadAttachment,
    selectReceivers,
} from "store";
import {
    FileUploadComponent,
    FormikTextField,
    FormikTextArea,
} from "components";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { ContactsAutocomplete } from "./contacts-autocomplete/ContactsAutocomplete";

export const SendOrViewCommunication: FC = () => {
    const dispatch = useAppDispatch();

    const comDetails = useAppSelector(selectCommunicationDetails);
    const currentUser = useAppSelector(selectCurrentUser);
    const receivers = useAppSelector(selectReceivers);

    const [isReadOnly, setIsReadOnly] = useState(true);
    useEffect(() => {
        if (
            comDetails.userCommunicationId === null ||
            comDetails.userCommunicationId === undefined
        )
            setIsReadOnly(false);
    }, [comDetails]);
    const canReply: boolean = currentUser?.userId !== comDetails.from;

    let filesToUpload: Array<File>;
    const validationSchema = yup.object().shape({
        subject: yup.string().required("Subiectul este obligatoriu"),
        message: yup.string().required("Mesajul este obligatoriu"),
        receivers: yup.array().min(1, "Selectati cel putin un destinatar"),
    });

    const handleClose = () => {
        dispatch(setDisplayDialog(false));

        dispatch(setContactsSelectionModel([]));
    };

    const handleReply = (initialValues: any) => {
        dispatch(setReceivers([{
            receiverId: comDetails.from,
            receiverLastName: comDetails.lastName,
            receiverFirstName: comDetails.firstName
        }]))
        dispatch(
            setCommunicationDetails({
                to: [
                    {
                        receiverId: comDetails.from,
                        receiverLastName: comDetails.firstName,
                        receiverFirstName: comDetails.lastName,
                    },
                ],
                from: currentUser?.userId,
                dateCreated: "",
                subject: `Reply: ${initialValues.subject}`,
                message: "",
                files: [],
                userCommunicationId: null,
            })
        );
    };

    const handleDownload = (attachment: any) => {
        dispatch(requestDownloadAttachment({ requestData: { fileId: attachment.fileId, fileTitle: attachment.fileTitle }, token: currentUser?.jwtToken }))
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                receivers: comDetails?.to ?? [],
                message: comDetails?.message ?? "",
                subject: comDetails?.subject ?? "",
                from: currentUser?.userId,
            }}
            onSubmit={(values, { setErrors }: any) => {
                dispatch(
                    requestSendCommunication({
                        requestData: {
                            senderId: currentUser?.userId,
                            subject: values.subject,
                            message: values.message,
                            files: filesToUpload,
                            receiversIds: receivers.map(
                                (receiver) => receiver.receiverId
                            ),
                        },
                        token: currentUser?.jwtToken,
                    })
                )
                    .unwrap()
                    .then(handleClose)
                    .finally(() => { });
            }}
            validationSchema={validationSchema}
        >
            {({ initialValues }) => (
                <Form style={{ height: "100%" }}>
                    <Grid
                        container
                        sx={{ height: "100%" }}
                        flexDirection="column"
                        alignItems="stretch"
                        flexWrap="nowrap"
                        className="parent-container"
                    >
                        <Grid item xs="auto">
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "center",
                                }}
                            >
                                {!isReadOnly && (
                                    <IconButton color="primary" type="submit">
                                        <Send fontSize="large" />
                                    </IconButton>
                                )}
                                {canReply && (
                                    <IconButton
                                        color="primary"
                                        onClick={() =>
                                            handleReply(initialValues)
                                        }
                                    >
                                        <Reply fontSize="large" />
                                    </IconButton>
                                )}

                                <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    color="error"
                                    edge="end"
                                >
                                    <Cancel fontSize="large" />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item container xs="auto">
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="subtitle1">
                                    Subiect:
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <FormikTextField
                                    name="subject"
                                    variant="standard"
                                    size="small"
                                    readOnly={isReadOnly}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs="auto">
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    display: "flex",
                                    alignItems: "start",
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{ paddingRight: 1 }}
                                >
                                    Catre:
                                </Typography>
                            </Grid>

                            <Grid item xs={10}>
                                <ContactsAutocomplete isReadOnly={isReadOnly} />
                            </Grid>
                        </Grid>
                        <Grid item container xs="auto">
                            <Grid item xs={2}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ paddingRight: 1 }}
                                >
                                    Atasamente:
                                </Typography>
                            </Grid>
                            <Grid item xs={10} sx={{ height: "100px", overflowY: "auto" }}>
                                {isReadOnly ? (
                                    comDetails &&
                                    comDetails.attachements &&
                                    comDetails?.attachements.map(
                                        (attachment, index) => (
                                            <Chip
                                                sx={{ margin: 0.1 }}
                                                label={attachment.fileTitle}
                                                color="primary"
                                                variant="outlined"
                                                icon={<Download />}
                                                key={`key-attach-${index}`}
                                                onClick={() => handleDownload(attachment)}
                                            />
                                        )
                                    )
                                ) : (
                                    <FileUploadComponent
                                        onFilesChange={(files: File[]) => {
                                            filesToUpload = files;
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid item flexGrow={1}>
                            <Box sx={{ paddingBottom: 1 }}>
                                <FormikTextArea
                                    name="message"
                                    label="Mesaj"
                                    rows={7}
                                    readOnly={isReadOnly}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
