import { createBrowserHistory } from "history";
import { configureCustomStore } from "./store";

export const history = createBrowserHistory();
export const store = configureCustomStore();
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunkConfig = { dispatch: any, state: RootState };

export * from './models';
export * from './slices';
export * from './hooks';
export * from './thunks';
export * from './selectors';