import { Studies } from "../enums/Studies";

export const studiesSelectItems = [
    { label: "ISCED2", value: Studies.ISCED2 },
    { label: "ISCED3", value: Studies.ISCED3 },
    { label: "ISCED4", value: Studies.ISCED4 },
    { label: "ISCED5", value: Studies.ISCED5 },
    { label: "ISCED6", value: Studies.ISCED6 },
    { label: "ISCED7", value: Studies.ISCED7 },
];
