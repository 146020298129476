import { createSlice } from "@reduxjs/toolkit";
import { addNewEmptyDosarAchizitii, addNewGroupOfDeconturi, addNewSolicitariGroup, deleteDecont, deleteDocument, deleteDosarAchizitii, deleteGroupOfDeconturi, deleteGroupSolicitareModificari, deleteSolicitareModificari, getAllCategoryDocuments, rejectDecont, rejectDocument, rejectSolicitare, unrejectDecont, unrejectDocument, unrejectSolicitare, unvalidateAnexa, unvalidateDecont, unvalidateDocument, unvalidateSolicitareModificari, updateDecont, updateSolicitareModificari, uploadAnexa, uploadDecontCheltuieli, uploadDocument, uploadSolicitareModificari, validateAnexa, validateDecont, validateDocument, validateSolicitareModificari } from "store/thunks";
import TreeTableModelBuilder from "utils/treeTableUtils";

export const manageDocumentsSlice = createSlice({
  name: "manageDocumentsSlice",
  initialState: {
    documentGroup: null,
    documents: [],
    initialDocuments: [],
    expandedKeys: {},
    newDecontData: {},
    decontSubmitStatus: "idle",
    solicitareSubmitStatus: "idle",
    newRequestData: null,
    documentsIsLoading: false,
    actionNode: null,
  } as any,
  reducers: {
    setNewDecontData(state: any, action: any) {
      state.newDecontData = action.payload;
    },
    setActionNode(state: any, action: any) {
      state.actionNode = action.payload;
    },
    setDocuments(state: any, action: any) {
      state.documents = action.payload;
    },
    setExpandedKeys(state: any, action: any) {
      let actionKeys = Object.keys(action.payload);

      let result = actionKeys;
      state.expandedKeys = {};
      for (var i in result) state.expandedKeys[result[i]] = true;
    },
    setSimpleExpandKey: (state, action) => {
      state.expandedKeys[action.payload] = true;
    },
    addNewDecontCheltuieli: (state: any, action: any) => {
      let newDecont = {
        tempId: ++state.tempIds,
        ajutorMinimis: "",
        contributieProprie: "",
        nrDocumentJustificativ: "",
        isRejected: false,
        isNotSaved: true,
        isTemplateFile: false,
        tipCheltuiala: "",
        requiresValidation: true,
        dataDocumentJustificativ: "",
        subcategory: 7,
        filename: ""
      };
      state.initialDocuments[action.payload.data.year][action.payload.data.category][action.payload.data.month.toLowerCase()].files.find((decontGroup: any) => decontGroup.groupId === action.payload.data.id).deconturi.splice(0, 0, newDecont);
      let builder = new TreeTableModelBuilder();
      state.documents = builder.buildTreeTableModelFromResponse(state.initialDocuments).root;

      state.expandedKeys[action.payload.key] = true;
      state.newDecontData = {
        decontId: newDecont.tempId,
        year: action.payload.data.year,
        category: action.payload.data.category,
        month: action.payload.data.month,
        decontGroupId: action.payload.data.id
      }

    },
    addRequestInputsNode(state, action) {
      state.initialDocuments[action.payload.data.year][action.payload.data.category].nonMonthlyDocuments.files.find((requestGroup: any) => requestGroup.groupId === action.payload.data.id).solicitari.splice(0, 0, {
        solicitareModificariId: ++state.tempIds,
        requestGroupId: action.payload.data.id,
        year: action.payload.data.yearCategory,
        nodeType: "document",
        isNotSaved: true,
        category: action.payload.data.category,
        alteDocumente: []
      });
      let builder = new TreeTableModelBuilder()
      state.documents = builder.buildTreeTableModelFromResponse(state.initialDocuments).root;
      state.expandedKeys[action.payload.key] = true;
      state.newRequestData = {
        id: state.tempIds,
        requestGroupId: action.payload.data.id,
        year: action.payload.data.year,
        nodeType: "document",
        isNotSaved: true,
        category: action.payload.data.category,
        alteDocumente: []
      };
    },
    deleteDecontCheltuieli: (state: any, action: any) => {
      var temp = state.initialDocuments[action.payload.year][action.payload.category][action.payload.month.toLowerCase()].files.find((decontGroup: any) => decontGroup.groupId === action.payload.decontGroupId).deconturi.filter((item: any) => item.tempId !== action.payload.decontId);
      state.initialDocuments[action.payload.year][action.payload.category][action.payload.month.toLowerCase()].files.find((decontGroup: any) => decontGroup.groupId === action.payload.decontGroupId).deconturi = temp;
      let builder = new TreeTableModelBuilder();
      state.documents = builder.buildTreeTableModelFromResponse(state.initialDocuments).root;

      state.expandedKeys[action.payload.key] = true;
    },
    removeNewRequest: (state, action) => {
      var temp = state.initialDocuments[action.payload.year][action.payload.category].nonMonthlyDocuments.files.find((requestGroup: any) => requestGroup.groupId === action.payload.requestGroupId).solicitari.filter((item: any) => item.solicitareModificariId !== action.payload.id);
      state.initialDocuments[action.payload.year][action.payload.category].nonMonthlyDocuments.files.find((requestGroup: any) => requestGroup.groupId === action.payload.requestGroupId).solicitari = temp;
      let builder = new TreeTableModelBuilder();
      state.documents = builder.buildTreeTableModelFromResponse(state.initialDocuments).root;

      state.expandedKeys[action.payload.key] = true;
    },
    setDecontSubmitStatus: (state, action) => {
      state.decontSubmitStatus = action.payload;
    },
    generateValidationNote: (state, action) => {

    },
    setSolicitareSubmitStatus: (state, action) => {
      state.solicitareSubmitStatus = action.payload;
    },
    generateAquisitionValidationNote: (state, action) => {

    },

  },
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllCategoryDocuments.pending, (state: any, action: any) => {
        state.documentsIsLoading = true;
      });
    builder.addCase(
      getAllCategoryDocuments.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      getAllCategoryDocuments.rejected, (state: any, action: any) => {
        state.documentsIsLoading = false;
      });

    builder.addCase(
      uploadDocument.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      uploadDecontCheltuieli.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      deleteDecont.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      deleteGroupOfDeconturi.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      addNewGroupOfDeconturi.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      uploadSolicitareModificari.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      deleteSolicitareModificari.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      deleteGroupSolicitareModificari.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      updateSolicitareModificari.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      validateSolicitareModificari.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      unvalidateSolicitareModificari.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      rejectSolicitare.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      unrejectSolicitare.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      })

    builder.addCase(
      validateDocument.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      unvalidateDocument.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      validateDecont.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      unvalidateDecont.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      rejectDocument.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      unrejectDocument.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      rejectDecont.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      unrejectDecont.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      deleteDocument.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      updateDecont.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      addNewEmptyDosarAchizitii.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      uploadAnexa.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      addNewSolicitariGroup.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      validateAnexa.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      unvalidateAnexa.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
    builder.addCase(
      deleteDosarAchizitii.fulfilled, (state: any, action: any) => {
        state.documentsIsLoading = false;
        if (action.payload !== null && action.payload !== undefined) {
          state.documents = action.payload;

          state.initialDocuments = action.payload;
          let builder = new TreeTableModelBuilder();
          state.documents = builder.buildTreeTableModelFromResponse(action.payload).root;
        }
      });
  }
})

export const { addNewDecontCheltuieli, addRequestInputsNode, deleteDecontCheltuieli, generateAquisitionValidationNote, removeNewRequest, setActionNode, setDecontSubmitStatus,
  setExpandedKeys, setSimpleExpandKey, setDocuments, setNewDecontData, setSolicitareSubmitStatus } = manageDocumentsSlice.actions;