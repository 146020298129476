import { FC } from 'react';
import { DownloadForOffline } from '@mui/icons-material';
import DownloadIcon from "@mui/icons-material/Download";
import { ButtonGroup, Button, Tooltip } from '@mui/material';
import { PhaseNumber } from 'store/models/enums/PhaseNumber';
import { PhaseType } from 'store/models/enums/PhaseType';
import { downloadAppealFiles, downloadEvaluationGrid, selectCurrentUser, useAppDispatch, useAppSelector } from 'store';


export const EvaluationPageButtons: FC<{ userId: number | undefined, phaseNumber: PhaseNumber, stage: number, btnSize: string | any, appealBtnDisabled?: boolean, evaluationBtnDisabled?: boolean, isVersion2: boolean }> = ({ userId, phaseNumber, stage, btnSize, appealBtnDisabled, evaluationBtnDisabled, isVersion2 }) => {

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const getPhaseType = (): PhaseType => {
    if (phaseNumber == PhaseNumber.Phase3) return PhaseType.Final;
    if (stage === 1) {
      return PhaseType.Intermediary;
    } else if (stage === 2) {
      return PhaseType.Final;
    }
    return PhaseType.Intermediary;
  }

  const handleDownloadEvaluationGrid = () => {
    dispatch(downloadEvaluationGrid({
      requestData: {
        userId: userId,
        phaseNumber: phaseNumber,
        phaseType: getPhaseType(),
        isVersion2: isVersion2,
      },
      token: currentUser?.jwtToken
    }))
  }

  const handleDownloadAppealFiles = () => {
    dispatch(downloadAppealFiles({
      userId: userId,
      token: currentUser?.jwtToken
    }))
  }

  return (

    <ButtonGroup size={btnSize} sx={{
      "& > *:not(:last-child)": {
        marginRight: .5
      }
    }} >
      <Tooltip title={evaluationBtnDisabled ? "" : "Descarcă grila de evaluare"}>
        <Button sx={{ borderRadius: "4px !important" }} variant='contained' disabled={evaluationBtnDisabled} onClick={(e) => {
          handleDownloadEvaluationGrid()
        }}>
          <DownloadIcon />
        </Button>
      </Tooltip>
      {phaseNumber === PhaseNumber.Phase2 && stage !== 2 ?
        < Tooltip title={appealBtnDisabled ? "" : "Descarcă contestație"}>
          <Button disabled={appealBtnDisabled} sx={{ borderRadius: "4px !important" }} variant='contained' onClick={(e) => {
            handleDownloadAppealFiles()
          }}>
            <DownloadForOffline />
          </Button>
        </Tooltip> : <></>
      }
    </ButtonGroup >

  )
}