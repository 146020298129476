export const annexesSelectItems = [
  {
    label: "Anexa 11",
    value: 11,
  },
  {
    label: "Anexa 13",
    value: 13,
  },
  {
    label: "Anexa 14",
    value: 14,
  },
  {
    label: "Anexa 19",
    value: 19,
  },
  {
    label: "Anexa 22",
    value: 22,
  },
  {
    label: "Anexa 23",
    value: 23,
  },
  {
    label: "Anexa 24",
    value: 24,
  },
  {
    label: "Anexa 25",
    value: 25,
  },
  {
    label: "Anexa 26",
    value: 26,
  },
  {
    label: "Anexa 27",
    value: 27,
  },
]