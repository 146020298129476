import { Container, Grid, Tab, Tabs } from "@mui/material";
import { UserRepresentativeView } from "./user-representative-view";
import React, { FC, useEffect, useState } from "react";
import "./UserDetailsPage.scss";
import {
    registerCompanyUser,
    registerExpertUser,
    requestLogout,
    requestUserDetails,
    selectCurrentUser,
    selectUserDetails,
    updateUser,
    useAppDispatch,
    useAppSelector,
} from "store";
import * as yup from "yup";
import { Formik, Form } from "formik";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import locale from "date-fns/locale/ro";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { UserPersonalDocumentsView } from "./user-personal-documents-view";
import { UserGTDocumentsView } from "./user-gt-documents-view";
import { UserType } from "store/models/enums/UserType";
import { newUserPath, usersPath } from "navigation";
import { resetUserDetails } from "store/slices/targetGroupSlice";

interface LocationState {
    userType?: UserType;
}

export const UserDetailsPage: FC<any> = () => {
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { userId } = useParams();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const currentUser = useAppSelector(selectCurrentUser);
    const canEdit = currentUser?.userType !== UserType.COMPANY_USER;
    const isSameCompanyUser = (userId && (currentUser?.userId === parseInt(userId, 10))) || canEdit;

    const location = useLocation();
    let locationState = location.state as LocationState;

    const isAddingNewUser = true ? location.pathname === newUserPath : false;

    if (!isSameCompanyUser) {
        dispatch(requestLogout(currentUser?.jwtToken));
    }

    const setCurrentTimeForDates = (values: any) => {
        var date = new Date();
        values?.dataRecrutare?.setHours(date.getHours());
        values?.dataRecrutare?.setMinutes(date.getMinutes());
        values?.dataInformare?.setHours(date.getHours());
        values?.dataInformare?.setMinutes(date.getMinutes());
        values?.birthDate?.setHours(date.getHours());
        values?.birthDate?.setMinutes(date.getMinutes());
        values?.dataInregistrare?.setHours(date.getHours());
        values?.dataInregistrare?.setMinutes(date.getMinutes());
        values?.dataNasterii?.setHours(date.getHours());
        values?.dataNasterii?.setMinutes(date.getMinutes());

    }

    const userDetails = useAppSelector(selectUserDetails);

    useEffect(() => {
        setActiveTab(0);
        if (!isAddingNewUser) {
            let promise = dispatch(
                requestUserDetails({
                    userId: parseInt(userId ?? "0"),
                    token: currentUser?.jwtToken,
                })
            );

            return () => promise.abort();
        } else {
            dispatch(resetUserDetails());
        }
        //eslint-disable-next-line
    }, [userId]);

    const validationSchema = yup.object({
        email: yup
            .string()
            .email("Introduceți email valid")
            .required("Email obligatoriu").nullable(true),
        firstName: yup.string().required("Prenumele este obligatoriu").nullable(true),
        lastName: yup.string().required("Numele este obligatoriu").nullable(true),
        userType: yup.number().required("Tipul utilizatorului este obligatoriu").nullable(true),

    });

    var initialValues: any = {
        userId: null,
        firstName: null,
        lastName: null,
        dateCreated: null,
        companyName: null,
        email: null,
        phoneNo: null,
        password: null,
        confirmPassword: null,
        userType: locationState?.userType ?? null,
        expertUserId: canEdit ? currentUser?.userId : null,
        faxNumber: null,
        CNP: null,
        address: null,
        judet: null,
        zona: null,
        birthDate: null,
        gen: null,
        studii: null,
        statut: null,
        curs: null,
        grupaCurs: null,
        functiaInCompanie: null,
        dataRecrutare: null,
        finalizat: null,
        workshop: null,
        sursaInformare: null,
        dataInformare: null,
        dataInregistrare: null,
        numarInregistrare: null,
        expertType: 0,
        dataNasterii: null,
        emails: undefined,
    };
    if (!isAddingNewUser) initialValues = {
        ...initialValues, ...userDetails,
        birthDate: userDetails.birthDate ? new Date(userDetails.birthDate) : null,
        dataNasterii: userDetails.dataNasterii ? new Date(userDetails.dataNasterii) : null,
        dataRecrutare: userDetails.dataRecrutare ? new Date(userDetails.dataRecrutare) : null,
        dataInformare: userDetails.dataInformare ? new Date(userDetails.dataInformare) : null,
        dateCreated: userDetails.dateCreated ? new Date(userDetails.dateCreated) : null,
        dataInregistrare: userDetails.dataInregistrare ? new Date(userDetails.dataInregistrare) : null,
    };

    const onKeyDown = (keyEvent: any) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <Container
            id="user-details-page"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <Grid
                    container
                    sx={{
                        minHeight: "inherit",
                    }}
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={12}
                        container
                        direction="column"
                        alignItems="stretch"
                    >
                        <Grid item xs="auto">
                            <Tabs value={activeTab} onChange={handleChange}>
                                <Tab label="Informații personale" />
                                {!isAddingNewUser && <Tab label="Documente personale" />}
                                {!isAddingNewUser && userDetails?.userType === UserType.COMPANY_USER && <Tab label="Documente dosar GT" />}
                            </Tabs>
                        </Grid>
                        <Grid item flexGrow={1}>
                            <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                onSubmit={(values) => {
                                    if (isAddingNewUser) {
                                        if (values.userType === UserType.COMPANY_USER) {
                                            setCurrentTimeForDates(values)
                                            dispatch(registerCompanyUser({
                                                userData: {
                                                    ...values,
                                                    birthDate: values.birthDate ? values.birthDate.toISOString() : null,
                                                    dataNasterii: values.dataNasterii ? values.dataNasterii.toISOString() : null,
                                                    dataRecrutare: values.dataRecrutare ? values.dataRecrutare.toISOString() : null,
                                                    dataInformare: values.dataInformare ? values.dataInformare.toISOString() : null,
                                                    dataInregistrare: values.dataInregistrare ? values.dataInregistrare.toISOString() : null,
                                                    punctajFaza1: values.punctajFaza1 ? parseInt(values.punctajFaza1, 10) : 0,
                                                    punctajFaza2: values.punctajFaza2 ? parseInt(values.punctajFaza2, 10) : 0,
                                                    punctajFaza3: values.punctajFaza3 ? parseInt(values.punctajFaza3, 10) : 0,
                                                    punctajTotal: values.punctajTotal ? parseInt(values.punctajTotal, 10) : 0,
                                                }, token: currentUser?.jwtToken
                                            })).then(() => navigate(usersPath));
                                        }
                                        if (values.userType === UserType.EXPERT) {
                                            dispatch(registerExpertUser({
                                                userData: {
                                                    firstName: values.firstName,
                                                    lastName: values.lastName,
                                                    email: values.email,
                                                    numarTelefon: values.phoneNo,
                                                    userType: values.userType,
                                                    dataNasterii: values.dataNasterii ? values.dataNasterii.toISOString() : null,
                                                    password: values.password,
                                                    confirmPassword: values.confirmPassword,
                                                    gen: values.gen,
                                                    zona: values.zona,
                                                    judet: values.judet,
                                                    expertType: values.expertType
                                                }, token: currentUser?.jwtToken
                                            })).then(() => navigate(usersPath));;

                                        }
                                    } else {
                                        setCurrentTimeForDates(values)
                                        dispatch(updateUser({
                                            userData: {
                                                ...values,
                                                userType: values.userType.toString(),
                                                punctajFaza1: values.punctajFaza1 ? parseInt(values.punctajFaza1, 10) : 0,
                                                punctajFaza2: values.punctajFaza2 ? parseInt(values.punctajFaza2, 10) : 0,
                                                punctajFaza3: values.punctajFaza3 ? parseInt(values.punctajFaza3, 10) : 0,
                                                punctajTotal: values.punctajTotal ? parseInt(values.punctajTotal, 10) : 0,
                                            }, token: currentUser?.jwtToken
                                        }));
                                    }
                                }}
                                validationSchema={validationSchema}
                                validateOnChange={false}
                                validateOnBlur={false}
                            >
                                {({ values, errors }) => <Form onKeyDown={onKeyDown} style={{ height: "100%" }}>
                                    {activeTab === 0 &&
                                        <UserRepresentativeView canEdit={canEdit} isNewUser={isAddingNewUser} userType={values.userType} />
                                    }
                                    {activeTab === 1 && (
                                        <UserPersonalDocumentsView />
                                    )}
                                    {activeTab === 2 && <UserGTDocumentsView />}
                                </Form>}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </Container>
    );
};
