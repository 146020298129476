import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import "./HeaderComponent.scss";
import { setDisplayNav } from "store/slices/baseSlice";
import { useAppDispatch, useAppSelector } from "store";
import { AccountCircle, Lock, ManageAccounts } from "@mui/icons-material";
import React, { FC, useRef, useState } from "react";
import { Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { requestLogout } from "store/thunks/authenticationThunks";
import { useNavigate } from "react-router";
import rural from "../../../assets/images/pro_rural_alba.png";
import xerom from "../../../assets/images/logo-xerom-contact.png";
import { homePath } from "navigation";

const HeaderComponent: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [displayMenu, setDisplayMenu] = useState<boolean>(false);
    const profileMenuAnchor = useRef<HTMLButtonElement>(null);

    const currentUser = useAppSelector((state) => state.auth.currentUser);

    return (
        <AppBar>
            <Toolbar variant="dense">
                {currentUser && (
                    <React.Fragment>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => {
                                dispatch(setDisplayNav(true));
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid container sx={{ minHeight: "inherit", alignContent: "stretch" }} columnSpacing={1}>
                            <Grid item container xs={2} sx={{ display: "flex" }} columnSpacing={1}>
                                <Grid item xs={4}>
                                    <div
                                        onClick={() => navigate(homePath)}
                                        style={{
                                            height: "100%",
                                            backgroundImage: `url(${rural})`,
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            cursor: "pointer"
                                        }}
                                    ></div>
                                </Grid>
                                <Grid item xs={8}>
                                    <div
                                        onClick={() => navigate(homePath)}
                                        style={{
                                            height: "100%",
                                            backgroundImage: `url(${xerom})`,
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            cursor: "pointer"
                                        }}
                                    ></div>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <Box className="text-center" sx={{ textAlign: "center", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography variant="body1" sx={{ fontSize: "calc(.5rem + .5vw)" }}>
                                        Susținem antreprenoriatul social în mediul rural
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                        <IconButton
                            color="inherit"
                            ref={profileMenuAnchor}
                            onClick={() => setDisplayMenu(true)}
                            sx={{ marginLeft: "auto" }}
                        >
                            <AccountCircle fontSize="large" />
                        </IconButton>
                        <Menu
                            open={displayMenu}
                            anchorEl={profileMenuAnchor.current}
                            onClose={() => setDisplayMenu(false)}
                        >
                            <MenuItem
                                onClick={() => {
                                    setDisplayMenu(false);
                                    navigate(`/user/${currentUser.userId}`);
                                }}>
                                <ListItemIcon>
                                    <ManageAccounts color="secondary" />
                                </ListItemIcon>
                                <ListItemText>Profilul meu</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setDisplayMenu(false);
                                    dispatch(
                                        requestLogout(
                                            currentUser?.jwtToken ?? ""
                                        )
                                    );
                                }}
                            >
                                <ListItemIcon>
                                    <Lock color="secondary" />
                                </ListItemIcon>
                                <ListItemText>Log out</ListItemText>
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                )}
            </Toolbar>
        </AppBar >
    );
};

export default HeaderComponent;
