import { Container, Grid, Tooltip, IconButton } from '@mui/material';
import { SimCardDownload } from '@mui/icons-material';
import AutocompleteSearch from 'components/shared/autocompleteSearch/AutocompleteSearch';
import { FC, useEffect, useState } from 'react';
import { CompanyUserBriefModel, downloadEvaluationTemplateForPhase2, requestCompanyUsersNames, selectCompanyUsersNames, selectCurrentUser, selectHasAppeal, selectSelectedUser, useAppDispatch, useAppSelector } from 'store';
import { UserType } from 'store/models/enums/UserType';
import { addAppNotification } from 'store/slices/appNotificationSlice';
import { setIsGrantApprovedForSelectedUser, setSelectedUser } from 'store/slices/grantContestSlice';
import { Phase2FinallyEvaluationPage } from './phase-2-evaluation-finally/Phase2FinallyEvaluationPage';
import { Phase2TemporaryEvaluationPage } from './phase-2-evaluation-temporary/Phase2TemporaryEvaluationPage';
import { useNavigate } from 'react-router';
import { setUserPhase2FinalEvaluationGrids, setUserPhase2TemporaryEvaluationGrids } from 'store/slices/grantEvaluationSlice';


export const GrantEvaluationPhase2Page: FC<any> = ({ isVersion2 }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const companyUsersNames = useAppSelector(selectCompanyUsersNames);
    const selectedUser = useAppSelector(selectSelectedUser);
    const [displayAutocompleteDropdown, setDisplayAutocompleteDropdown] = useState<boolean>(false);
    const hasAppeal = useAppSelector(selectHasAppeal);

    useEffect(() => {
        if (selectedUser === null) {
            dispatch(setUserPhase2FinalEvaluationGrids([]))
            dispatch(setUserPhase2TemporaryEvaluationGrids([]))
        }
        if (selectedUser !== null) {
            localStorage.setItem('userId', JSON.stringify(selectedUser.userId));
        }
    }, [selectedUser])
    useEffect(() => {
        if (!selectedUser?.isGrantApproved && !isVersion2) {

            navigate(`/planafaceri/v1/${currentUser?.userId}`);
            dispatch(setSelectedUser(null));

        }
        if (!selectedUser?.isGrantApprovedV2 && isVersion2) {
            navigate(`/planafaceri/${currentUser?.userId}`);
            dispatch(setSelectedUser(null));

        }
        if (currentUser?.userType === UserType.EXPERT || currentUser?.userType === UserType.ADMIN) {
            dispatch(requestCompanyUsersNames({
                filterByRegistrationNumber: true,
                token: currentUser?.jwtToken,
                isVersion2: isVersion2
            }))
        }
    }, [isVersion2])

    const handleSelectUser = (e: any, values: any) => {
        if (values !== null && values !== undefined && typeof values !== "string") {
            setDisplayAutocompleteDropdown(false)
            dispatch(setSelectedUser({
                userId: values.userId,
                firstName: values.firstName,
                lastName: values.lastName,
                isGrantApproved: values.isGrantApproved,
                isGrantApprovedV2: values.isGrantApprovedV2,
            }));
            dispatch(setIsGrantApprovedForSelectedUser(isVersion2 ? values.isGrantApprovedV2 : values.isGrantApproved));
            if (isVersion2) {
                navigate(`/planafaceri/${values.userId}`);
            } else {
                navigate(`/planafaceri/v1/${values.userId}`);
            }

        }
        if (typeof values === "string" && e.key === "Enter") {
            let usersFound = 0;
            let userFound: CompanyUserBriefModel = {}

            for (let user of companyUsersNames) {
                if ((user.lastName + " " + user.firstName).toLowerCase().includes(values.toLowerCase())) {
                    usersFound++;
                    userFound = {
                        userId: user.userId,
                        lastName: user.lastName,
                        firstName: user.firstName,
                        isGrantApproved: user.isGrantApproved,
                        isGrantApprovedV2: user.isGrantApprovedV2
                    };
                }
            }
            if (usersFound === 0) {
                setDisplayAutocompleteDropdown(false);
                dispatch(addAppNotification({
                    message: "Nu a fost găsit nici un rezultat pentru căutarea dorită!",
                    severity: "warning",
                }));
            }
            if (usersFound === 1) {
                setDisplayAutocompleteDropdown(false)
                dispatch(setSelectedUser({
                    userId: userFound.userId,
                    firstName: userFound.firstName,
                    lastName: userFound.lastName,
                    isGrantApproved: userFound.isGrantApproved,
                    isGrantApprovedV2: userFound.isGrantApprovedV2
                }));
            }
            if (usersFound > 1) {
                dispatch(addAppNotification({
                    message: "Selectați unul dintre utilizatori!",
                    severity: "warning",
                }));
            }
        }
    };


    const onInputChange = (event: any, value: any) => {
        if (event !== null) {
            if (value === "" && event.type === 'change') {
                localStorage.removeItem('userId')
                dispatch(setSelectedUser(null));
            }
            if (event.key === "Enter") {
                handleSelectUser(event, selectedUser)
            }
        }

    };
    const onKeyPress = (event: any, value: any) => {
        if (event.key === "Enter") {
            handleSelectUser(event, event.target.value)
        }
    };

    const getOptionLabel = (user: any): string => {
        return (user.lastName || "") + (user.lastName ? " " : "") + (user.firstName || "");
    };


    return (
        <Container
            id="grant-evaluation-phase2-view"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid
                container
                sx={{
                    minHeight: "inherit",
                    p: {
                        xs: 1,
                        md: 2,
                    },
                }}
                alignItems="stretch"
            >
                {
                    currentUser?.userType !== UserType.COMPANY_USER ?
                        <Grid container marginBottom={1} justifyContent="flex-end" alignItems="center" >
                            <Grid >
                                <Tooltip title="Descarcă modelul pentru grila de evaluare">
                                    <IconButton color="primary" onClick={(e) => {
                                        dispatch(downloadEvaluationTemplateForPhase2({
                                            token: currentUser?.jwtToken
                                        }))
                                    }}>
                                        <SimCardDownload />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item sx={{ marginLeft: 1 }}>
                                <AutocompleteSearch
                                    width={175}
                                    options={companyUsersNames}
                                    value={selectedUser}
                                    getOptionLabel={getOptionLabel}
                                    renderOption={(props: any, option: any) => {
                                        return (
                                            <li {...props} key={option.userId}>
                                                {option.lastName + " " + option.firstName}
                                            </li>
                                        );
                                    }}
                                    onType={handleSelectUser}
                                    isOptionEqualToValue={(option: any, value: any) => option.userId === value.userId}
                                    onInputChange={onInputChange}
                                    onKeyPress={onKeyPress}
                                    open={displayAutocompleteDropdown}
                                    onOpen={(e: any) => { setDisplayAutocompleteDropdown(true) }}
                                    onClose={(e: any) => { setDisplayAutocompleteDropdown(false) }}
                                />
                            </Grid>
                        </Grid> : <Grid></Grid>
                }
                <Grid item xs={12} marginBottom={3}><Phase2TemporaryEvaluationPage isVersion2={isVersion2} selectedUserId={selectedUser?.userId} /></Grid>
                <Grid item xs={12} marginBottom={3}><Phase2FinallyEvaluationPage isVersion2={isVersion2} selectedUserId={selectedUser?.userId} /></Grid>
            </Grid>
        </Container >
    )
}