import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig } from "store";
import { EvaluationsPaginatedModel, GenerateRankingModel, GrantEvaluationFiltersModel, RenounceGrandtModel, ScheduleModel, ScoreBoardModel, UploadAppealModel, UploadEvaluationGridModel, UploadScheduleModel } from "store/models";
import { RankingFilters } from "store/models/grantEvaluation/RankingFilters";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { hideOverlay, showOverlay } from "store/slices/overlayLoaderSlice";
import { baseUrl, getAxiosErrorMessage } from "utils";
import { boolean } from "yup";
const axios = require("axios").default;

export const uploadIntermediaryPhase2EvaluationGrid = createAsyncThunk<
    // return type
    any,
    //payload type
    { filter: UploadEvaluationGridModel; token?: string | null },
    AppThunkConfig
>(
    "grantEvaluation/uploadIntermediaryPhase2EvaluationGrid",
    async (
        { filter: { files, userId, uploadedByUserId,isVersion2 }, token },
        thunkAPI
    ) => {
        try {
            thunkAPI.dispatch(showOverlay());
            const form = new FormData();
            files.forEach((file: string | Blob) => {
                form.append("file", file);
            });
            form.append("userId", userId.toString());
            form.append("uploadedByUserId", uploadedByUserId.toString());
            form.append("isVersion2",isVersion2.toString())
            let result = await axios.post(
                baseUrl + "GrantEvaluation/UploadIntermediaryPhase2EvaluationGrid",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message.text ??
                        "Document incarcat cu succes",
                    severity: "success",
                })
            );
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const uploadFinalPhase2EvaluationGrid = createAsyncThunk<
    // return type
    any,
    //payload type
    { filter: UploadEvaluationGridModel; token?: string | null },
    AppThunkConfig
>(
    "grantEvaluation/uploadFinalPhase2EvaluationGrid",
    async (
        { filter: { files, userId, uploadedByUserId,isVersion2 }, token },
        thunkAPI
    ) => {
        try {
            thunkAPI.dispatch(showOverlay());
            const form = new FormData();
            files.forEach((file: string | Blob) => {
                form.append("file", file);
            });
            form.append("userId", userId.toString());
            form.append("uploadedByUserId", uploadedByUserId.toString());
            form.append("isVersion2",isVersion2.toString())
            let result = await axios.post(
                baseUrl + "GrantEvaluation/UploadFinalPhase2EvaluationGrid",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message.text ??
                        "Document incarcat cu succes",
                    severity: "success",
                })
            );
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestIntermediaryPhase2EvaluationGrids = createAsyncThunk<
    //return type
    EvaluationsPaginatedModel,
    //payload type
    { filter: GrantEvaluationFiltersModel; token?: string | null },
    AppThunkConfig
>(
    "grantContest/requestIntermediaryPhase2EvaluationGrids",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ filter, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GetIntermediaryPhase2EvaluationGrids",
                {
                    params: {
                        pageNumber: filter.pageNumber.toString(),
                        columnToSortBy: filter.columnToSortBy,
                        sortingOrder: filter.sortingOrder,
                        userId: filter.userId.toString(),
                        isVersion2:filter.isVersion2.toString()
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestFinalPhase2EvaluationGrids = createAsyncThunk<
    //return type
    EvaluationsPaginatedModel,
    //payload type
    { filter: GrantEvaluationFiltersModel; token?: string | null },
    AppThunkConfig
>(
    "grantContest/requestFinalPhase2EvaluationGrids",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ filter, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GetFinalPhase2EvaluationGrids",
                {
                    params: {
                        pageNumber: filter.pageNumber.toString(),
                        columnToSortBy: filter.columnToSortBy,
                        sortingOrder: filter.sortingOrder,
                        userId: filter.userId.toString(),
                        isVersion2:filter.isVersion2.toString()
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);





export const deleteEvaluationGrid = createAsyncThunk<
    //return type
    any,
    //payload type
    { docId: number, token?: string },
    AppThunkConfig
>(
    "grantEvaluation/deleteEvaluationGrid",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ docId, token }, thunkAPI) => {
        try {
            const form = new FormData();
            form.append("evaluationId", docId.toString());
            let result = await axios.post(
                baseUrl + "GrantEvaluation/DeleteEvaluationGrid",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message?.text ??
                        "Documentul a fost sters",
                    severity: "success",
                })
            );
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const downloadEvaluationGrid = createAsyncThunk<
    //return type
    any,
    //payload type
    { requestData: any, token: string | undefined },
    AppThunkConfig
>(
    "grantEvaluation/downloadEvaluationGrid",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ requestData, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/DownloadEvaluationGrid",
                {
                    params: {
                        userId: requestData.userId,
                        phaseNumber: requestData.phaseNumber,
                        phaseType: requestData.phaseType,
                        isVersion2:requestData.isVersion2,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();

        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {

                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);


export const getScoresForPhase2Intermediary = createAsyncThunk<
    ScoreBoardModel,
    { filters: RankingFilters, token?: string | null },
    AppThunkConfig
>(
    "grantEvaluation/getScoresForPhase2Intermediary",
    async (
        { filters, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GetScoresForPhase2Intermediary",
                {
                    params: {
                        pageNumber: filters.pageNumber,
                        columnToSortBy: filters.columnToSortBy,
                        sortingOrder: filters.sortingOrder,
                        isVersion2:filters.isVersion2,
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }

        }
    }
)

export const getScoresForPhase2Final = createAsyncThunk<
    ScoreBoardModel,
    { filters: RankingFilters, token?: string | null },
    AppThunkConfig
>(
    "grantEvaluation/getScoresForPhase2Final",
    async (
        { filters, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GetScoresForPhase2Final",
                {
                    params: {
                        pageNumber: filters.pageNumber,
                        columnToSortBy: filters.columnToSortBy,
                        sortingOrder: filters.sortingOrder,
                        isVersion2:filters.isVersion2,
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }

        }
    }
)

export const downloadEvaluationTemplateForPhase2 = createAsyncThunk<
    //return type
    any,
    //payload type
    { token: string | undefined },
    AppThunkConfig
>(
    "grantEvaluation/downloadEvaluationTemplateForPhase2",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/DownloadEvaluationTemplateForPhase2",
                {
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();

        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);


export const uploadFinalPhase3EvaluationGrid = createAsyncThunk<
    // return type
    any,
    //payload type
    { filter: UploadEvaluationGridModel; token?: string | null },
    AppThunkConfig
>(
    "grantEvaluation/uploadFinalPhase3EvaluationGrid",
    async (
        { filter: filter, token },
        thunkAPI
    ) => {
        try {
            thunkAPI.dispatch(showOverlay());
            const form = new FormData();
            filter.files.forEach((file: string | Blob) => {
                form.append("file", file);
            });

            form.append("userId", filter.userId.toString());
            form.append("uploadedByUserId", filter.uploadedByUserId.toString());
            form.append("points", filter.points!);
            form.append("comment", filter.comment!)
            form.append("isVersion2",filter.isVersion2.toString())

            let result = await axios.post(
                baseUrl + "GrantEvaluation/UploadFinalPhase3EvaluationGrid",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message.text ??
                        "Document incarcat cu succes",
                    severity: "success",
                })
            );
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);


export const requestFinalPhase3EvaluationGrids = createAsyncThunk<
    //return type
    EvaluationsPaginatedModel,
    //payload type
    { filter: GrantEvaluationFiltersModel; token?: string | null },
    AppThunkConfig
>(
    "grantContest/requestFinalPhase3EvaluationGrids",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ filter, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GetFinalPhase3EvaluationGrids",
                {
                    params: {
                        pageNumber: filter.pageNumber.toString(),
                        columnToSortBy: filter.columnToSortBy,
                        sortingOrder: filter.sortingOrder,
                        userId: filter.userId.toString(),
                        isVersion2:filter.isVersion2.toString(),
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const getScoresForPhase3Final = createAsyncThunk<
    ScoreBoardModel,
    { filters: RankingFilters, token?: string | null },
    AppThunkConfig
>(
    "grantEvaluation/getScoresForPhase3Final",
    async (
        { filters, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GetScoresForPhase3Final",
                {
                    params: {
                        pageNumber: filters.pageNumber,
                        columnToSortBy: filters.columnToSortBy,
                        sortingOrder: filters.sortingOrder,
                        isVersion2:filters.isVersion2,
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }

        }
    }
);

export const downloadEvaluationTemplateForPhase3 = createAsyncThunk<
    //return type
    any,
    //payload type
    { token: string | undefined },
    AppThunkConfig
>(
    "grantEvaluation/downloadEvaluationTemplateForPhase3",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/DownloadEvaluationTemplateForPhase3",
                {
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();

        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const uploadAppeal = createAsyncThunk<
    // return type
    any,
    //payload type
    { filter: UploadAppealModel; token?: string | null },
    AppThunkConfig
>(
    "grantEvaluation/uploadAppeal",
    async (
        { filter: { userAppealingId, phaseNumber, phaseType, mandatoryFile, files,isVersion2 }, token },
        thunkAPI
    ) => {
        try {
            thunkAPI.dispatch(showOverlay());
            const form = new FormData();
            form.append("userAppealingId", userAppealingId!.toString());
            form.append("phaseNumber", phaseNumber.toString());
            form.append("phaseType", phaseType.toString());
            form.append("isVersion2", isVersion2.toString())
            mandatoryFile.forEach((file: string | Blob) => {
                form.append("mandatoryFile", file);
            });

            files.forEach((file: string | Blob) => {
                form.append("files", file);
            });
            let result = await axios.post(
                baseUrl + "GrantEvaluation/UploadAppeal",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message.text ??
                        "Ati depus o contestatie",
                    severity: "success",
                })
            );
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const downloadAppealFiles = createAsyncThunk<
    //return type
    any,
    //payload type
    { userId: any, token: string | undefined },
    AppThunkConfig
>(
    "grantEvaluation/downloadAppealFiles",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ userId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/DownloadAppealFiles",
                {
                    params: {
                        userAppealingId: userId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();

        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const deleteAppeal = createAsyncThunk<
    //return type
    any,
    //payload type
    { userAppealingId: number | undefined, token?: string },
    AppThunkConfig
>(
    "grantEvaluation/deleteAppeal",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ userAppealingId, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay())
            const form = new FormData();
            form.append("userAppealingId", userAppealingId?.toString() ?? "0");
            let result = await axios.post(
                baseUrl + "GrantEvaluation/DeleteAppeal",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay())
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message?.text ??
                        "Documentul a fost sters",
                    severity: "success",
                })
            );

        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay())
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const generateRanking = createAsyncThunk<
    //return type
    any,
    //payload type
    { filter: GenerateRankingModel; token?: string | null },
    AppThunkConfig
>(
    "grantContest/generateRanking",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ filter, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            let result = await axios.post(
                baseUrl + "GrantEvaluation/GenerateRanking", filter,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(addAppNotification({
                message: result?.data?.message?.text,
                severity: "success",
            }));
            return result.data.response;

        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestGetGeneratedRankings = createAsyncThunk<
    //return type
    GenerateRankingModel[],
    //payload type
    { token?: string | null },
    AppThunkConfig
>(
    "grantContest/requestGetGeneratedRankings",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GetGeneratedRankings",
                {

                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const extractSchedulesFromDocument = createAsyncThunk<
    any,
    { files: File[], token: string | undefined, isVersion2:boolean },
    AppThunkConfig
>(
    "grantEvaluation/extractSchedulesFromDocument",
    async (
        { files, token,isVersion2 },
        thunkAPI
    ) => {
        try {
            thunkAPI.dispatch(showOverlay());
            const form = new FormData();
            files.forEach((file: string | Blob) => {
                form.append("file", file);
            });
            form.append("isVersion2",isVersion2.toString())
            let result = await axios.post(
                baseUrl + "GrantEvaluation/ExtractSchedulesFromDocument", form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message: result?.data?.message.text ?? "Au fost incarcate programarile",
                    severity: "success",
                })
            );
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
)

export const getInterviewSchedules = createAsyncThunk<
    //return type
    ScheduleModel[],
    //payload type
    {isVersion2:boolean, token?: string | null },
    AppThunkConfig
>(
    "grantEvaluation/getInterviewSchedules",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ token,isVersion2 }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GetInterviewSchedules",
                {
                    params:{
                        isVersion2:isVersion2,
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const addOrUpdateInterviewSchedule = createAsyncThunk<
    //return type
    any,
    //payload type
    { data: UploadScheduleModel; token?: string | null },
    AppThunkConfig
>(
    "grantEvaluation/addOrUpdateInterviewSchedule",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ data, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            let result = await axios.post(
                baseUrl + "GrantEvaluation/AddOrUpdateInterviewSchedule", data,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (result.data.message) {
                thunkAPI.dispatch(addAppNotification({
                    message: result.data.message.text,
                    severity: result.data.message.messageType,
                }))
            }
            thunkAPI.dispatch(hideOverlay());
            return result.data.response;
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const deleteInterviewSchedule = createAsyncThunk<
    //return type
    any,
    //payload type
    { scheduleId: number; token?: string | null },
    AppThunkConfig
>(
    "grantEvaluation/deleteInterviewSchedule",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ scheduleId, token }, thunkAPI) => {
        try {
            var form = new FormData();
            form.append("scheduleId", scheduleId.toString())
            let result = await axios.post(
                baseUrl + "GrantEvaluation/DeleteInterviewSchedule", form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const getScheduleDetails = createAsyncThunk<
    //return type
    ScheduleModel,
    //payload type
    { userScheduledId: number,isVersion2:boolean, token?: string | null },
    AppThunkConfig
>(
    "grantEvaluation/getScheduleDetails",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ userScheduledId, token,isVersion2 }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GetScheduleDetails",
                {
                    params: {
                        userScheduledId: userScheduledId,
                        isVersion2:isVersion2,
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const generatePhase2IntermediaryResults = createAsyncThunk<
    //return type
    any,
    //payload type
    { token?: string | null,isVersion2:boolean },
    AppThunkConfig
>(
    "grantEvaluation/generatePhase2IntermediaryResults",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ token,isVersion2 }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GeneratePhase2IntermediaryResults",
                {
                    params:{
                        isVersion2:isVersion2
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
            thunkAPI.dispatch(hideOverlay());
            return result.data.response;
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const generatePhase2FinalResults = createAsyncThunk<
    //return type
    any,
    //payload type
    { token?: string | null,isVersion2:boolean },
    AppThunkConfig
>(
    "grantEvaluation/generatePhase2FinalResults",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ token,isVersion2 }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GeneratePhase2FinalResults",
                {
                    params:{
                        isVersion2:isVersion2
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
            thunkAPI.dispatch(hideOverlay());
            return result.data.response;
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const generatePhase3FinalResults = createAsyncThunk<
    //return type
    any,
    //payload type
    { token?: string | null,isVersion2:boolean },
    AppThunkConfig
>(
    "grantEvaluation/generatePhase3FinalResults",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ token,isVersion2 }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            let result = await axios.get(
                baseUrl + "GrantEvaluation/GeneratePhase3FinalResults",
                {
                    params:{
                        isVersion2:isVersion2
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
            thunkAPI.dispatch(hideOverlay());
            return result.data.response;
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const updateUserRenounced = createAsyncThunk<
    //return type
    any,
    //payload type
    { filter: RenounceGrandtModel; token?: string | null },
    AppThunkConfig
>(
    "grantContest/updateUserRenounced",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ filter, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            let result = await axios.post(
                baseUrl + "GrantEvaluation/UpdateUserRenounced", filter,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(addAppNotification({
                message: result?.data?.message?.text,
                severity: "success",
            }));
            return result.data.response;

        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const downloadEvaluationGridById = createAsyncThunk<
    //return type
    any,
    //payload type
    { evaluationId: number, token: string | undefined },
    AppThunkConfig
>(
    "grantEvaluation/downloadEvaluationGridById",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ evaluationId, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            let result = await axios.get(
                baseUrl + "GrantEvaluation/DownloadEvaluationGridById",
                {
                    params: {
                        evaluationId: evaluationId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                },
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
            thunkAPI.dispatch(hideOverlay());
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);
