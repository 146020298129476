import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    addUsersToSeries,
    deleteExamResultsDoc,
    deleteExtraFilesDoc,
    deleteVocationalForm,
    downloadExamResultsDoc,
    downloadVocationalFormDoc,
    downloadVocationalTrainingExtraFileDoc,
    removeUsersFromSeries,
    requestCourseSeries,
    requestExamResultsDocs,
    requestExtraFilesDocs,
    requestUsersInAndOutOfSeries,
    requestVocationTrainingForms,
    uploadExamResultsDocs,
    uploadExtraFilesDocs,
    uploadVocationalTrainingForms,
} from "store";
import { CourseSeries } from "../models/vocationalTraining/CourseSeries";
import { VocationalTrainingState } from "../models/vocationalTraining/VocationalTrainingState";
import { VocationalTrainingDocument } from "../models/vocationalTraining/VocationalTrainingDocument";
import { UserModel } from "store/models/user/UserModel";

const initialState: VocationalTrainingState = {
    actions: {},
    documents: new Array<VocationalTrainingDocument>(),
    courseSeries: new Array<CourseSeries>(),
    usersInAndOutOfSeries: {
        usersOfSeries: new Array<UserModel>(),
        usersOutOfSeries: new Array<UserModel>(),
    },
    vocationalTrainingFormsResponse: {},
    vocationalTrainingExamResultsResponse: {},
    vocationalTraininExtraFilesResponse: {},
    formsListIsLoading: false,
    examResultsListIsLoading: false,
    extraFilesListIsLoading: false,
    vocationalFileIsDownloading: false,
    vocationalFileIsUploading: false,
    courseSeriesUserIsLoading: false,
    userIsChangedBetweenCourseSeries: false
};

export const vocationalTrainingSlice = createSlice({
    name: "vocationalTrainingSlice",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            requestVocationTrainingForms.fulfilled,
            (state, action: PayloadAction<any>) => {
                action.payload.vocDocuments.forEach((x: any) => {
                    x.id = x.vocDocumentId;
                });
                state.vocationalTrainingFormsResponse = action.payload;
                state.formsListIsLoading = false;
            }
        );
        builder.addCase(requestVocationTrainingForms.pending, (state,action) => {
            state.formsListIsLoading = true;
        });
        builder.addCase(requestVocationTrainingForms.rejected, (state,action) => {
            state.formsListIsLoading = false;
        });
        builder.addCase(
            requestCourseSeries.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.courseSeries = action.payload;
            }
        );
        builder.addCase(
            requestUsersInAndOutOfSeries.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.usersInAndOutOfSeries = action.payload;
                state.courseSeriesUserIsLoading = false;
            }
        );
        builder.addCase(
            requestExamResultsDocs.fulfilled,
            (state, action: PayloadAction<any>) => {
                action.payload.vocDocuments.forEach((x: any) => {
                    x.id = x.vocDocumentId;
                });
                state.vocationalTrainingExamResultsResponse = action.payload;
                state.examResultsListIsLoading = false;
            }
        );
        builder.addCase(requestExamResultsDocs.pending, (state,action) => {
            state.examResultsListIsLoading = true;
        });
        builder.addCase(requestExamResultsDocs.rejected, (state,action) => {
            state.examResultsListIsLoading = false;
        });

        builder.addCase(
            requestExtraFilesDocs.fulfilled,
            (state, action: PayloadAction<any>) => {
                action.payload.vocDocuments.forEach((x: any) => {
                    x.id = x.vocDocumentId;
                });
                state.vocationalTraininExtraFilesResponse = action.payload;
                state.extraFilesListIsLoading = false;
            }
        );
        builder.addCase(requestExtraFilesDocs.pending, (state,action) => {
            state.extraFilesListIsLoading = true;
        });
        builder.addCase(requestExtraFilesDocs.rejected, (state,action) => {
            state.extraFilesListIsLoading = false;
        });
        builder.addCase(downloadExamResultsDoc.pending, (state,action) => {
            state.vocationalFileIsDownloading = true;
        });
        builder.addCase(downloadExamResultsDoc.fulfilled, (state,action) => {
            state.vocationalFileIsDownloading = false;
        });
        builder.addCase(downloadExamResultsDoc.rejected, (state,action) => {
            state.vocationalFileIsDownloading = false;
        });
        builder.addCase(deleteExamResultsDoc.pending, (state,action) => {
            state.examResultsListIsLoading = true;
        });
        builder.addCase(deleteExamResultsDoc.fulfilled, (state,action) => {
            state.examResultsListIsLoading = false;
        });
        builder.addCase(deleteExamResultsDoc.rejected, (state,action) => {
            state.examResultsListIsLoading = false;
        });
        builder.addCase(uploadExamResultsDocs.pending, (state,action) => {
            state.vocationalFileIsUploading = true;
        });
        builder.addCase(uploadExamResultsDocs.fulfilled, (state,action) => {
            state.vocationalFileIsUploading = false;
        });
        builder.addCase(uploadExamResultsDocs.rejected, (state,action) => {
            state.vocationalFileIsUploading = false;
        });
        builder.addCase(downloadVocationalTrainingExtraFileDoc.pending, (state,action) => {
            state.vocationalFileIsDownloading = true;
        });
        builder.addCase(downloadVocationalTrainingExtraFileDoc.fulfilled, (state,action) => {
            state.vocationalFileIsDownloading = false;
        });
        builder.addCase(downloadVocationalTrainingExtraFileDoc.rejected, (state,action) => {
            state.vocationalFileIsDownloading = false;
        });
        builder.addCase(deleteExtraFilesDoc.pending, (state,action) => {
            state.extraFilesListIsLoading = true;
        });
        builder.addCase(deleteExtraFilesDoc.fulfilled, (state,action) => {
            state.extraFilesListIsLoading = false;
        });
        builder.addCase(deleteExtraFilesDoc.rejected, (state,action) => {
            state.extraFilesListIsLoading = false;
        });
        builder.addCase(uploadExtraFilesDocs.pending, (state,action) => {
            state.vocationalFileIsUploading = true;
        });
        builder.addCase(uploadExtraFilesDocs.fulfilled, (state,action) => {
            state.vocationalFileIsUploading = false;
        });
        builder.addCase(uploadExtraFilesDocs.rejected, (state,action) => {
            state.vocationalFileIsUploading = false;
        });
        builder.addCase(downloadVocationalFormDoc.pending, (state,action) => {
            state.vocationalFileIsDownloading = true;
        });
        builder.addCase(downloadVocationalFormDoc.fulfilled, (state,action) => {
            state.vocationalFileIsDownloading = false;
        });
        builder.addCase(downloadVocationalFormDoc.rejected, (state,action) => {
            state.vocationalFileIsDownloading = false;
        });
        builder.addCase(deleteVocationalForm.fulfilled, (state,action) => {
            state.formsListIsLoading = false;
        });
        builder.addCase(deleteVocationalForm.rejected, (state,action) => {
            state.formsListIsLoading = false;
        });
        builder.addCase(deleteVocationalForm.pending, (state,action) => {
            state.formsListIsLoading = true;
        });
        builder.addCase(uploadVocationalTrainingForms.pending, (state,action) => {
            state.vocationalFileIsUploading = true;
        });
        builder.addCase(uploadVocationalTrainingForms.fulfilled, (state,action) => {
            state.vocationalFileIsUploading = false;
        });
        builder.addCase(uploadVocationalTrainingForms.rejected, (state,action) => {
            state.vocationalFileIsUploading = false;
        });
        builder.addCase(requestUsersInAndOutOfSeries.pending, (state,action) => {
            state.courseSeriesUserIsLoading = true;
        });
        builder.addCase(requestUsersInAndOutOfSeries.rejected, (state,action) => {
            state.courseSeriesUserIsLoading = false;
        });
        builder.addCase(addUsersToSeries.fulfilled, (state,action) => {
            state.userIsChangedBetweenCourseSeries = false;
        });
        builder.addCase(addUsersToSeries.pending, (state,action) => {
            state.userIsChangedBetweenCourseSeries = true;
        });
        builder.addCase(addUsersToSeries.rejected, (state,action) => {
            state.userIsChangedBetweenCourseSeries = false;
        });
        builder.addCase(removeUsersFromSeries.fulfilled, (state,action) => {
            state.userIsChangedBetweenCourseSeries = false;
        });
        builder.addCase(removeUsersFromSeries.pending, (state,action) => {
            state.userIsChangedBetweenCourseSeries = true;
        });
        builder.addCase(removeUsersFromSeries.rejected, (state,action) => {
            state.userIsChangedBetweenCourseSeries = false;
        });
    },
});

export const {} = vocationalTrainingSlice.actions;
