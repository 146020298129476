import { Autocomplete, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { FC, useState } from "react";
import { UserModel } from "store/models/user/UserModel";

export const EmailsList: FC<{ limit: number, fieldName: string }> = ({ limit, fieldName }) => {

  const formik = useFormikContext<UserModel>();
  const emails = formik?.values?.emails;
  const [helperText, setHelperText] = useState<string>();


  const handleChange = (e: any, newValue: any) => {
    if (newValue.length > (emails ? emails.length : 0)) {
      if (emails?.includes(e.target.value)) {
        setHelperText("Acest email a fost introdus deja!")
        setTimeout(() => {
          setHelperText(undefined);
        }, 3000)
        return;
      }
      if ((emails ? emails.length : 0) === 5) {
        setHelperText("Maxim 5!")
        setTimeout(() => {
          setHelperText(undefined);
        }, 3000)
        return;
      }
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
        formik.setFieldValue(fieldName, newValue);
        return;
      } else {
        setHelperText("Email invalid!")
        setTimeout(() => {
          setHelperText(undefined);
        }, 3000)
        return;
      }
    }
    else {
      formik.setFieldValue(fieldName, newValue);
    }
  }



  return (
    <Autocomplete
      options={[]}
      multiple
      value={emails}
      limitTags={limit}
      fullWidth
      freeSolo
      onChange={handleChange}
      clearIcon={false}
      renderInput={(params) => (
        <TextField {...params} placeholder="Scrieți email-ul și apăsați tasta Enter pentru introducere" label="Emailuri de informare" error={helperText !== undefined} helperText={helperText} />
      )}


    />

  )
}