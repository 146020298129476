import { Close } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/lab';
import locale from "date-fns/locale/ro";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import { FormikDatePicker, FormikTextField } from 'components';
import { Form, Formik } from 'formik';
import { FC } from 'react';
import { approveGrantForUser, requestCompanyUsersNames, selectApprovingGrant, selectCurrentUser, selectSelectedUser, useAppDispatch, useAppSelector } from 'store';
import { setApprovingGrant, setSelectedUser } from 'store/slices/grantContestSlice';
import * as yup from "yup";


export const ApproveGrantPopout: FC<any> = ({ isVersion2 }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const approvingGrant = useAppSelector(selectApprovingGrant);
  const selectedUser = useAppSelector(selectSelectedUser);
  const handleValidateClick = () => {
    dispatch(setApprovingGrant(true));
  }
  const validationSchema = yup.object({
    dataInregistrare: yup
      .string()
      .required("Dată înregistrare obligatorie!").nullable(true),
    numarInregistrare: yup
      .string()
      .required("Număr înregistrare obligatoriu!").nullable(true),

  });
  var initialValues: any = {
    dataInregistrare: null,
    numarInregistrare: null,
  };
  const handleSubmitDialog = (values: any) => {
    var date = new Date();
    values?.dataInregistrare?.setHours(date.getHours())
    values?.dataInregistrare?.setMinutes(date.getMinutes())
    dispatch(approveGrantForUser({
      approveGrantData: {
        dataInregistrare: values.dataInregistrare ? values.dataInregistrare.toISOString() : null,
        numarInregistrare: values.numarInregistrare,
        companyUserId: selectedUser?.userId!,
        isVersion2: isVersion2,
      },
      token: currentUser?.jwtToken,
    })).then(() => {
      dispatch(setSelectedUser({
        userId: selectedUser?.userId,
        firstName: selectedUser?.firstName,
        lastName: selectedUser?.lastName,
        isGrantApproved: isVersion2 ? false : true,
        isGrantApprovedV2: isVersion2 ? true : false,
      }));
      dispatch(requestCompanyUsersNames({
        token: currentUser?.jwtToken,
        isVersion2: false
      }))
    })

  }
  return (
    <div style={{ height: "100%" }}>
      {(!approvingGrant) && <Button sx={{ height: "inherit" }} variant="contained" color="success" disabled={selectedUser === null || (!isVersion2 && selectedUser?.isGrantApproved) || (isVersion2 && selectedUser?.isGrantApprovedV2)} onClick={handleValidateClick}>Validează</Button>}
      <Dialog
        id="approve-grant"
        className="dialog"
        open={approvingGrant}
        maxWidth="xs"
        fullWidth
        disableScrollLock
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "end" }}>
          <Tooltip title="Inchide">
            <IconButton onClick={() => dispatch(setApprovingGrant(false))}><Close /></IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={(values) => {
                handleSubmitDialog(values)
              }}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
            >

              {({ values, errors }) => <Form style={{ height: "100%" }}>
                <Grid container padding={2} direction="column" justifyContent="center" alignItems="center">
                  <Grid width="60%" marginBottom={3} >
                    <FormikTextField
                      label="Număr înregistrare *"
                      name="numarInregistrare"
                    />
                  </Grid>
                  <Grid width="60%" marginBottom={3}>
                    <FormikDatePicker
                      label="Dată înregistrare *"
                      name="dataInregistrare"
                    />
                  </Grid>

                  <Grid marginBottom={3}>
                    <Button variant="contained" color="success" type="submit">Validează</Button>
                  </Grid>

                </Grid>
              </Form>}

            </Formik>
          </LocalizationProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}