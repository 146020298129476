import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig, TargetGroupFilesResponse } from "store";
import { UserDocumentsFilter, CompanyUsersFilter } from "store/models/user/UserModel";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { hideOverlay, showOverlay } from "store/slices/overlayLoaderSlice";
import { baseUrl, getAxiosErrorMessage } from "utils";
const axios = require("axios").default;

export const requestGetAllCompanyUsers = createAsyncThunk<
    //return type
    any,
    { filter: CompanyUsersFilter; token?: string | null | undefined }
>(
    "targetGroup/getAllCompanyUsers",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ filter: companyUsersFilter, token }, thunkAPI) => {
        try {
            let users = await axios.get(baseUrl + "Users/GetAllCompanyUsers",
                {
                    params: {
                        pageNumber: companyUsersFilter.pageNumber.toString(),
                        columnToSortBy: companyUsersFilter.columnToSortBy,
                        sortingOrder: companyUsersFilter.sortingOrder,
                        searchText: companyUsersFilter.searchText,
                        onlyRegisteredUsers: companyUsersFilter.onlyRegisteredUsers
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
            return users.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestUserDetails = createAsyncThunk<
    //return type
    any,
    //payload type
    { userId: number; token?: string | null },
    AppThunkConfig
>(
    "targetGroup/requestUserDetails",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ userId, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay())
            let user = await axios.get(baseUrl + "Users/GetUser", {
                params: {
                    id: userId
                },
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            thunkAPI.dispatch(hideOverlay())
            return user.data.response;
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay())
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const registerCompanyUser = createAsyncThunk<
    //return type
    any,
    //payload type
    { userData: any, token: string | null | undefined },
    AppThunkConfig
>(
    "targetGroup/registerCompanyUser",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ userData, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay())
            let { data } = await axios.post(baseUrl + "Users/RegisterCompanyUser",
                userData,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        data?.response?.message?.text ??
                        "Utilizator înregistrat cu succces",
                    severity: "success",
                })
            );
            return data.response;
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay())
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const registerExpertUser = createAsyncThunk<
    //return type
    any,
    //payload type
    { userData: any, token: string | null | undefined },
    AppThunkConfig
>(
    "targetGroup/registerExpertUser",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ userData, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            let { data } = await axios.post(baseUrl + "Users/RegisterExpertUser",
                userData,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        data?.response?.message?.text ??
                        "Expert înregistrat cu succces",
                    severity: "success",
                })
            );
            return data.response;
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const updateUser = createAsyncThunk<
    //return type
    any,
    //payload type
    { userData: any, token: string | null | undefined },
    AppThunkConfig
>(
    "targetGroup/updateUser",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ userData, token }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showOverlay());
            let { data } = await axios.post(baseUrl + "Users/UpdateUser",
                userData,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        data?.response?.message?.text ??
                        "Utilizator actualizat cu succes",
                    severity: "success",
                })
            );
            return data.response;
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const requestUserDocuments = createAsyncThunk<
    //return type
    TargetGroupFilesResponse,
    //payload type
    { filter: UserDocumentsFilter; token?: string | null },
    AppThunkConfig
>(
    "targetGroup/requestUserDocuments",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ filter: userDocumentsFilter, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "UserDocuments/GetUserDocuments",
                {
                    params: {
                        pageNumber: userDocumentsFilter.pageNumber.toString(),
                        columnToSortBy: userDocumentsFilter.columnToSortBy,
                        sortingOrder: userDocumentsFilter.sortingOrder,
                        userDocumentType: userDocumentsFilter.userDocumentType,
                        userId: userDocumentsFilter.userId.toString()
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data.response;
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const downloadUserDocument = createAsyncThunk<
    //return type
    any,
    //payload type
    { fileId: number; token?: string | null },
    AppThunkConfig
>(
    "targetGroup/downloadUserDocument",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async ({ fileId, token }, thunkAPI) => {
        try {
            let result = await axios.get(
                baseUrl + "UserDocuments/DownloadDocument",
                {
                    params: {
                        fileId: fileId,
                    },
                    responseType: "blob",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            let url = window.URL.createObjectURL(blob);

            let fileLink = document.createElement("a");
            fileLink.href = url;

            let filename = "";
            let disposition = result.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const deleteUserDocument = createAsyncThunk<
    //return type
    any,
    //payload type
    any,
    AppThunkConfig
>(
    "targetGroup/deleteUserDocument",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async (
        { requestData: { userDocId, userDocumentType, userId }, token },
        thunkAPI
    ) => {
        try {
            const form = new FormData();
            form.append("userDocId", userDocId.toString());
            let result = await axios.post(
                baseUrl + "UserDocuments/DeleteDocument",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message?.text ?? "Document sters!",
                    severity: "success",
                })
            );
        } catch (err: any) {
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const uploadUserDocument = createAsyncThunk<
    //return type
    any,
    //payload type
    any,
    AppThunkConfig
>(
    "targetGroup/uploadUserDocument",
    async (
        { requestData: { files, userDocumentType, userId }, token },
        thunkAPI
    ) => {
        try {
            thunkAPI.dispatch(showOverlay());
            const form = new FormData();
            files.forEach((file: string | Blob) => {
                form.append("Files", file);
            });
            form.append("UserId", userId.toString());
            form.append("UserDocumentType", userDocumentType.toString());
            let result = await axios.post(
                baseUrl + "UserDocuments/UploadUserDocuments",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message ?? "Document incarcat!",
                    severity: "success",
                })
            );
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);

export const deleteUser = createAsyncThunk<
    //return type
    any,
    //payload type
    any,
    AppThunkConfig
>(
    "targetGroup/deleteUser",
    //signal is to be used later, now just leave it there to avoid lint warnings
    async (
        { userId, token },
        thunkAPI
    ) => {
        try {
            thunkAPI.dispatch(showOverlay());
            const form = new FormData();
            form.append("id", userId.toString());
            let result = await axios.post(
                baseUrl + "Users/DeleteUser",
                form,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            thunkAPI.dispatch(hideOverlay());
            thunkAPI.dispatch(
                addAppNotification({
                    message:
                        result?.data?.message?.text ?? "Utilizator sters!",
                    severity: "success",
                })
            );
        } catch (err: any) {
            thunkAPI.dispatch(hideOverlay());
            let errorMessage = getAxiosErrorMessage(err);
            if (errorMessage) {
                thunkAPI.dispatch(
                    addAppNotification({
                        message: errorMessage,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
            } else {
                throw err;
            }
        }
    }
);
