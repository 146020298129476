import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Container, Grid, Tab, Tabs } from "@mui/material";
import locale from "date-fns/locale/ro";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { requestGetGeneratedRankings, selectCurrentUser, selectSelectedUser, selectShowPhase2FinallRanking, selectShowPhase2FinallRankingV1, selectShowPhase2IntermediaryRanking, selectShowPhase2IntermediaryRankingV1, selectShowPhase3Ranking, selectShowPhase3RankingV1, useAppSelector } from "store";
import { UserType } from "store/models/enums/UserType";
import { setGrantApproved, setSelectedUser } from "store/slices/grantContestSlice";
import { setUserPhase2FinalEvaluationGrids, setUserPhase2TemporaryEvaluationGrids, setUserPhase3FinalEvaluationGrids } from "store/slices/grantEvaluationSlice";
import { GrantContestPhase1Component } from "./grant-contest-phase-1";
import { GrantContestPhase2Component } from "./grant-contest-phase-2";
import { GrantEvaluationPhase2Page } from "./grant-evaluation-phase-2/GrantEvaluationPhase2Page";
import { GrantEvaluationPhase3Page } from "./grant-evaluation-phase-3/GrantEvaluationPhase3Page";
import { GrantEvaluationPhase2RankingPage } from "./grant-ranking-phase-2/GrantEvaluationPhase2RankingPage";
import { GrantEvaluationPhase3RankingPage } from "./grant-ranking-phase-3/GrantEvaluationPhase3RankingPage";
import './GrantContestPage.scss';
import { InterviewSchedules } from "./interview-schedules-phase-3/InterviewSchedules";
export const GrantContestPage: FC = () => {
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const navigate = useNavigate();
    const { userId } = useParams();
    const showPhase3Ranking = useAppSelector(selectShowPhase3Ranking);
    const showPhase2FinallRanking = useAppSelector(selectShowPhase2FinallRanking);
    const showPhase2IntermediaryRanking = useAppSelector(selectShowPhase2IntermediaryRanking);
    const showPhase3RankingV1 = useAppSelector(selectShowPhase3RankingV1);
    const showPhase2FinallRankingV1 = useAppSelector(selectShowPhase2FinallRankingV1);
    const showPhase2IntermediaryRankingV1 = useAppSelector(selectShowPhase2IntermediaryRankingV1);
    const selectedUser = useAppSelector(selectSelectedUser);

    const route = window.location.href;
    const isVersion2 = (!route.includes("v1"));


    useEffect(() => {
        dispatch(setGrantApproved(checkUserForGrantApproved()))

        if (currentUser?.userType !== UserType.COMPANY_USER) {
            dispatch(setSelectedUser(null));
            if (isVersion2) {
                navigate("/planafaceri/" + currentUser?.userId);
            }
            else {
                navigate("/planafaceri/v1/" + currentUser?.userId);
            }
        }
        if (localStorage.getItem('userId')) {

            if (isVersion2) {
                navigate("/planafaceri/" + parseInt(localStorage.getItem("userId") ?? "0"))
            } else {
                navigate("/planafaceri/v1/" + parseInt(localStorage.getItem("userId") ?? "0"))
            }

        }
        dispatch(requestGetGeneratedRankings({
            token: currentUser?.jwtToken,
        }));

        return (() => {
            dispatch(setUserPhase3FinalEvaluationGrids([]))
            dispatch(setUserPhase2FinalEvaluationGrids([]))
            dispatch(setUserPhase2TemporaryEvaluationGrids([]));
        })
    }, [])



    const checkUserForGrantApproved = () => {
        if (currentUser?.userType !== UserType.COMPANY_USER) {
            return false;
        }
        else {
            if (isVersion2 && currentUser?.isGrantApprovedV2 && currentUser?.userType === UserType.COMPANY_USER) {
                return true;
            }
            if (!isVersion2 && currentUser?.isGrantApproved && currentUser?.userType === UserType.COMPANY_USER) {
                return true;
            }
        }
    }

    useEffect(() => {
        if (!currentUser || (currentUser.userType === UserType.COMPANY_USER && currentUser?.userId !== parseInt(userId ?? "0"))) {
            if (isVersion2) {
                navigate("/planafaceri/" + currentUser?.userId);
            } else {
                navigate("/planafaceri/v1/" + currentUser?.userId);
            }

        }
    }, [userId])

    useEffect(() => {
        if (currentUser?.userId === parseInt(userId ?? "0") && currentUser?.userType !== UserType.COMPANY_USER) {
            dispatch(setSelectedUser(null));
        } else {

        }
    }, [navigate])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const displayPhase2EvaluationTab = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER) return false;
        if (selectedUser === null || (selectedUser !== null && !isVersion2 && selectedUser?.isGrantApproved)) return true;
        if (selectedUser === null || (selectedUser !== null && isVersion2 && selectedUser?.isGrantApprovedV2)) return true;
        if (!selectedUser?.isGrantApproved) return false;
        return true;
    }
    const displayRankingPhase2Tab = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER && !isVersion2 && !currentUser?.isGrantApproved) return false;
        if (currentUser?.userType === UserType.COMPANY_USER && isVersion2 && !currentUser?.isGrantApprovedV2) return false;
        if (isVersion2 && (showPhase2FinallRanking || showPhase2IntermediaryRanking)) return true;
        if (!isVersion2 && (showPhase2FinallRankingV1 || showPhase2IntermediaryRankingV1)) return true;
        return false;
    }

    const displayInterviewSchedules = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER && currentUser?.isGrantApprovedV2 && showPhase2FinallRanking && isVersion2) return true;
        if (currentUser?.userType === UserType.COMPANY_USER && currentUser?.isGrantApproved && showPhase2FinallRankingV1 && !isVersion2) return true;
        if (currentUser?.userType !== UserType.COMPANY_USER && (!selectedUser?.isGrantApproved && !isVersion2 && selectedUser !== null)) return false;
        if (currentUser?.userType !== UserType.COMPANY_USER && (!selectedUser?.isGrantApprovedV2 && isVersion2 && selectedUser !== null)) return false;
        if (isVersion2 && showPhase2FinallRanking) return true;
        if (isVersion2 && showPhase3Ranking) return true;
        if (!isVersion2 && showPhase2FinallRankingV1) return true;
        if (!isVersion2 && showPhase3RankingV1) return true;
        return false;
    }

    const displayPhase3EvaluationTab = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER || (!selectedUser?.isGrantApproved && selectedUser !== null && !isVersion2) || (!selectedUser?.isGrantApprovedV2 && selectedUser !== null && isVersion2)) {
            return false;
        } else {
            if (isVersion2 && showPhase2FinallRanking) return true;
            if (!isVersion2 && showPhase2FinallRankingV1) return true;
        }
        return false;
    }


    const displayRankingPhase3Tab = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER && !currentUser?.isGrantApproved && !isVersion2) return false;
        if (currentUser?.userType === UserType.COMPANY_USER && !currentUser?.isGrantApprovedV2 && isVersion2) return false;
        if (isVersion2 && showPhase3Ranking) return true;
        if (!isVersion2 && showPhase3RankingV1) return true;
        return false;
    }


    return (
        <Container
            id="grant-contest-page"
            maxWidth={false}
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <Grid
                    container
                    sx={{
                        minHeight: "inherit",
                    }}
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={12}
                        container
                        direction="column"
                        alignItems="stretch"
                    >
                        <Container maxWidth="lg" disableGutters>
                            <Tabs value={activeTab} onChange={handleChange} variant="scrollable">
                                <Tab label="Faza 1" />
                                <Tab label="Faza 2" />
                                {displayPhase2EvaluationTab() ? <Tab label="Evaluare Faza 2" /> : <Tab sx={{ display: "none" }} />}
                                {displayRankingPhase2Tab() ? <Tab label="Clasament Faza 2" /> : <Tab sx={{ display: "none" }} />}
                                {displayInterviewSchedules() ? <Tab label="Programare interviu faza 3" /> : <Tab sx={{ display: "none" }} />}
                                {displayPhase3EvaluationTab() ? <Tab label="Evaluare Faza 3" /> : <Tab sx={{ display: "none" }} />}
                                {displayRankingPhase3Tab() ? < Tab label="Clasament Faza 3" /> : <Tab sx={{ display: "none" }} />}
                            </Tabs>
                        </Container>
                        <Grid item flexGrow={1} >
                            {activeTab === 0 && <GrantContestPhase1Component isVersion2={isVersion2} />}
                            {activeTab === 1 && <GrantContestPhase2Component isVersion2={isVersion2} />}
                            {activeTab === 2 && <GrantEvaluationPhase2Page isVersion2={isVersion2} />}
                            {activeTab === 3 && <GrantEvaluationPhase2RankingPage isVersion2={isVersion2} />}
                            {activeTab === 4 && <InterviewSchedules isVersion2={isVersion2} />}
                            {activeTab === 5 && <GrantEvaluationPhase3Page isVersion2={isVersion2} />}
                            {activeTab === 6 && <GrantEvaluationPhase3RankingPage isVersion2={isVersion2} />}
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </Container>
    )
}