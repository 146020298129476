import { RootState } from "store";
import { CompanyUserBriefModel, PhaseDocumentModel } from "store/models";
import { SortModel } from "store/models/common";

export const selectCompanyUsersNames = (state: RootState): CompanyUserBriefModel[] => state.grantContest?.companyUsersNames ?? [];
export const selectGrantSortModel = (state: RootState): SortModel => state.grantContest.grantSortModel;
export const selectUserGrantDocumentsListIsLoading = (state: RootState): boolean => state.grantContest.userPhase1DocumentsListIsLoading;
export const selectUserIsDownloadingGrantPhaseFile = (state: RootState): boolean => state.grantContest.userIsDownloadingGrantPhaseFile;
export const selectUserPhase1Documents = (state: RootState): PhaseDocumentModel[] => state.grantContest.userPhase1Documents;
export const selectAnexa1Phase2Documents = (state: RootState): PhaseDocumentModel[] => state.grantContest.anexa1Phase2Documents;
export const selectPhase1DocumentsCount = (state: RootState): number => state.grantContest.phase1DocumentsCount;
export const selectUserAnexa2Phase2Documents = (state: RootState): PhaseDocumentModel[] => state.grantContest.userAnexa2Phase2Documents;
export const selectAnexa2Phase2DocumentsCount = (state: RootState): number => state.grantContest.anexa2Phase2DocumentsCount;
export const selectAnexa2Phase2SortModel = (state: RootState): SortModel => state.grantContest.anexa2Phase2SortModel;
export const selectAnexa1Phase2SortModel = (state: RootState): SortModel => state.grantContest.anexa1Phase2SortModel;
export const selectAnexa1Phase2DocumentsCount = (state: RootState): number => state.grantContest.anexa1Phase2DocumentsCount;
export const selectUserAnexa1Phase2DocumentsListIsLoading = (state: RootState): boolean => state.grantContest.userAnexa1Phase2DocumentsListIsLoading;
export const selectUserAlteDocumentePhase2Documents = (state: RootState): PhaseDocumentModel[] => state.grantContest.userAlteDocumentePhase2Documents;
export const selectAlteDocumentePhase2DocumentsCount = (state: RootState): number => state.grantContest.alteDocumentePhase2DocumentsCount;
export const selectAlteDocumentePhase2SortModel = (state: RootState): SortModel => state.grantContest.alteDocumentePhase2SortModel;
export const selectSelectedUser = (state: RootState): CompanyUserBriefModel | null => state.grantContest.selectedUser;
export const selectApprovingGrant = (state: RootState): boolean => state.grantContest.approvingGrant;
export const selectGrantApproved = (state: RootState): boolean => state.grantContest.grantApproved;
export const selectUserAnexa2Phase2DocumentsListIsLoading = (state: RootState): boolean => state.grantContest.userAnexa2Phase2DocumentsListIsLoading;
export const selectUserIsDownloadingAnexa1Phase2File = (state: RootState): boolean => state.grantContest.userIsDownloadingAnexa1Phase2File;
export const selectUserIsDownloadingAnexa2Phase2File = (state: RootState): boolean => state.grantContest.userIsDownloadingAnexa2Phase2File;
export const selectUserIsDownloadingAlteDocsPhase2File = (state: RootState): boolean => state.grantContest.userIsDownloadingAlteDocsPhase2File;
export const selectUserAlteDocsPhase2DocumentsListIsLoading = (state: RootState): boolean => state.grantContest.userAlteDocsPhase2DocumentsListIsLoading;
export const selectIsGrantApprovedForSelectedUser = (state: RootState): boolean => state.grantContest.isGrantApprovedForSelectedUser