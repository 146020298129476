export enum Months {
    None = 0,
    Ianuarie = 1,
    Februarie = 2,
    Martie = 3,
    Aprilie = 4,
    Mai = 5,
    Iunie = 6,
    Iulie = 7,
    August = 8,
    Septembrie = 9,
    Octombrie = 10,
    Noiembrie = 11,
    Decembrie = 12
}