import { FC, useState } from "react";
import "./ResetPasswordPage.scss";
import * as yup from "yup";
import loginBackground from "assets/images/wallpaper.jpg";
import {
    Button,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { Field, FieldProps, Form, Formik } from "formik";
import { requestResetPassword, useAppDispatch } from "store";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { loginPath } from "navigation";

export const ResetPasswordPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [params] = useSearchParams();

    const validationSchema = yup.object({
        password: yup.string().required("Introduceti parola!"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref<any>("password")], "Parolele nu sunt identice!")
            .required("Introduceti parola din nou!"),
        resetToken: yup.string().required(),
    });

    const [showPassword, setShowPassword] = useState<boolean>(false);
    return (
        <Container
            maxWidth={false}
            sx={{
                minHeight: "inherit",
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `url("${loginBackground}")`,
            }}
            id="login-page"
        >
            <Paper
                elevation={5}
                sx={{
                    minHeight: {
                        xs: "450px",
                    },
                    width: {
                        xs: "100%",
                        sm: "450px",
                    },
                }}
            >
                <Formik
                    initialValues={{
                        password: "",
                        confirmPassword: "",
                        resetToken: params.get("token"),
                    }}
                    onSubmit={(values) => {
                        dispatch(requestResetPassword({ ...values }))
                            .unwrap()
                            .then(() => navigate(loginPath));
                    }}
                    validationSchema={validationSchema}
                >
                    <Form style={{ minHeight: "inherit" }}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            sx={{ px: 6, py: 2, minHeight: "inherit" }}
                        >
                            <Grid item>
                                <Typography
                                    variant="h3"
                                    color="secondary.dark"
                                    textAlign="center"
                                >
                                    Resetare parolă
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    textAlign="center"
                                >
                                    Trimite email de resetare a parolei
                                </Typography>
                            </Grid>
                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <Field id="password" name="password">
                                        {({
                                            field,
                                            meta,
                                        }: FieldProps<string, any>) => (
                                            <TextField
                                                name={field.name}
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                fullWidth
                                                label="Parolă"
                                                value={field.value ?? ''}
                                                onChange={field.onChange}
                                                error={
                                                    meta.touched &&
                                                    Boolean(meta.error)
                                                }
                                                helperText={
                                                    meta.touched && meta.error
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() =>
                                                                    setShowPassword(
                                                                        !showPassword
                                                                    )
                                                                }
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item>
                                    <Field
                                        id="confirmPassword"
                                        name="confirmPassword"
                                    >
                                        {({
                                            field,
                                            meta,
                                        }: FieldProps<string, any>) => (
                                            <TextField
                                                name={field.name}
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                fullWidth
                                                label="Confirmă parola"
                                                value={field.value ?? ''}
                                                onChange={field.onChange}
                                                error={
                                                    meta.touched &&
                                                    Boolean(meta.error)
                                                }
                                                helperText={
                                                    meta.touched && meta.error
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() =>
                                                                    setShowPassword(
                                                                        !showPassword
                                                                    )
                                                                }
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item container justifyContent="center">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        type="submit"
                                    >
                                        <Typography variant="h6">
                                            Trimite
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Paper>
        </Container>
    );
};
