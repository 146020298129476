import { FC, useState } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Button, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";

interface CaseFileDialogProps {
    header: string;
    confirmAction: Function;
    displayDialog: boolean;
    handleClose: Function;
}

export const CaseFileDialogComponent: FC<CaseFileDialogProps> = ({ header, confirmAction, displayDialog, handleClose }) => {
    const [textFieldValue, setTextFieldValue] = useState<string>("");

    return (
        <Dialog
            open={displayDialog}
            onClose={() => handleClose()}
            maxWidth="xs"
            fullWidth
            disableScrollLock
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                {header}
                <IconButton onClick={() => handleClose()}><Close /></IconButton>
            </DialogTitle>
            <DialogContent sx={{ display: "flex", justifyContent: "center", minHeight: 150 }}>
                <TextField sx={{ width: "70%", marginTop: 1, marginBottom: 1 }} id="outlined" label="Nume" required variant="outlined" onChange={(e) => { setTextFieldValue(e.target.value) }} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={() => handleClose()}>
                    Anulează
                </Button>
                <Button variant="contained" disabled={textFieldValue === ""} color="warning" onClick={(e) => confirmAction({ dosarName: textFieldValue })} autoFocus>
                    Salvează
                </Button>
            </DialogActions>
        </Dialog>

    );

};