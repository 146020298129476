import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthenticationModel } from "store/models/authModel/AuthenticationModel";
import { AuthenticationState } from "store/models/authModel/AuthenticationState";
import {
    requestLogin,
    requestLogout,
    requestRefreshToken,
    requestUserEmailByVerificationToken,
    requestVerifyToken
} from "store/thunks/authenticationThunks";

const initialState: AuthenticationState = {
    actions: {},
    currentUser: null,
    isRefreshing: true,
    isSilentRefresh: false,
    userIsLoggingIn: false,
    userEmail: "",
    verifyEmailSuccess: false,
};

export const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        setCurrentUser(state, action: PayloadAction<AuthenticationModel>) {
            state.currentUser = action.payload;
        },
        setIsRefreshing(state, action: PayloadAction<boolean>) {
            state.isRefreshing = action.payload;
        },
        setIsSilentRefresh(state, action: PayloadAction<boolean>) {
            state.isSilentRefresh = action.payload;
        },
        setUserIsLoggingIn(state, action) {
            state.userIsLoggingIn = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestLogin.fulfilled, (state, action) => {
            localStorage.setItem(
                "user",
                JSON.stringify(action.payload.jwtToken)
            );
            state.currentUser = action.payload;
            state.userIsLoggingIn = false;
        });
        builder.addCase(requestLogin.pending, (state, action) => {
            state.userIsLoggingIn = true;
        });
        builder.addCase(requestLogin.rejected, (state, action) => {
            state.currentUser = null;
            if (localStorage.getItem("user")) localStorage.removeItem("user");
            state.userIsLoggingIn = false;
        });

        builder.addCase(requestRefreshToken.fulfilled, (state, action) => {
            localStorage.setItem(
                "user",
                JSON.stringify(action.payload.jwtToken)
            );
            state.currentUser = action.payload;
            state.isRefreshing = false;
            state.isSilentRefresh = false;
        });
        builder.addCase(requestRefreshToken.pending, (state, action) => {
            state.isRefreshing = true;
        });
        builder.addCase(requestRefreshToken.rejected, (state, action) => {
            state.currentUser = null;
            state.isRefreshing = false;
            state.isSilentRefresh = false;
            if (localStorage.getItem("user")) localStorage.removeItem("user");
        });

        builder.addCase(requestLogout.fulfilled, (state, action) => {
            state.currentUser = null;
            if (localStorage.getItem("user")) localStorage.removeItem("user");
            if (localStorage.getItem("userId")) localStorage.removeItem("userId");
        });
        builder.addCase(requestLogout.rejected, (state, action) => {
            state.currentUser = null;
            if (localStorage.getItem("user")) localStorage.removeItem("user");
        });
        builder.addCase(requestUserEmailByVerificationToken.fulfilled, (state, action) => {
            if (action.payload !== null) {
                state.userEmail = action.payload;
            }

        });
        builder.addCase(requestVerifyToken.fulfilled, (state, action) => {
            state.verifyEmailSuccess = true;
        });
        builder.addCase(requestVerifyToken.rejected, (state, action) => {
            state.verifyEmailSuccess = false;
        })
    }
})

export const { setCurrentUser, setIsRefreshing, setIsSilentRefresh, setUserIsLoggingIn } =
    authSlice.actions;
