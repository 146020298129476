import { Add, Delete } from '@mui/icons-material';
import { Button, ButtonGroup, Container, Grid, Tooltip, Typography } from '@mui/material';
import DownloadIcon from "@mui/icons-material/Download";
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid';
import { FileUploadPopout } from 'components';
import { FC, useEffect, useState } from 'react';
import { requestAlteDocumentePhase2Documents, selectUserAlteDocumentePhase2Documents, selectAlteDocumentePhase2SortModel, selectCurrentUser, selectAlteDocumentePhase2DocumentsCount, useAppDispatch, useAppSelector, uploadAlteDocumentePhase2UserDocuments, deleteAlteDocumentePhase2Document, downloadAlteDocumentePhase2Document, selectGrantApproved, selectUserIsDownloadingAlteDocsPhase2File, selectUserAlteDocsPhase2DocumentsListIsLoading } from 'store';
import { DataGridColumnNames } from 'store/models/enums/DataGridColumnNames';
import { setAlteDocumentePhase2SortModel, setUserAlteDocumentePhase2Documents } from 'store/slices/grantContestSlice';
import { mapColumnNameToEnum } from 'utils/mapColumnNametoEnum';
import DataLoadingComponent from 'components/shared/dataLoadingComponent/DataLoadingComponent';
import { UserType } from 'store/models/enums/UserType';
import { dateTimeFormatOptions } from 'utils';

export const OtherDocumentsComponent: FC<{ selectedUserId: number | null | undefined, isVersion2: boolean }> = ({ selectedUserId, isVersion2 }) => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const userAlteDocumentePhase2Documents = useAppSelector(selectUserAlteDocumentePhase2Documents);
    const alteDocumentePhase2DocumentsCount = useAppSelector(selectAlteDocumentePhase2DocumentsCount);
    const userIsDownloadingAlteDocsPhase2File = useAppSelector(selectUserIsDownloadingAlteDocsPhase2File);
    const grantApproved = useAppSelector(selectGrantApproved);
    const userAlteDocsPhase2DocumentsListIsLoading = useAppSelector(selectUserAlteDocsPhase2DocumentsListIsLoading);

    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);

    const rowsPerPage = 10;

    const alteDocumentePhase2SortModel = useAppSelector(selectAlteDocumentePhase2SortModel);
    const [displayUploadDialog, setDisplayUploadDialog] = useState<boolean>(false);

    const handleRequestAlteDocuments = (userId: number | null | undefined, pageNumber: number, columnToSortBy: DataGridColumnNames, sortingOrder: boolean, token: string | undefined) => {
        dispatch(requestAlteDocumentePhase2Documents({
            filter: {
                userId: userId,
                pageNumber: pageNumber,
                columnToSortBy: columnToSortBy,
                sortingOrder: sortingOrder,
                isVersion2: isVersion2
            },
            token: token
        }));
    };

    useEffect(() => {
        setCurrentPageNumber(0);
        if (selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) {
            handleRequestAlteDocuments(selectedUserId, 0, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);
        } else {
            dispatch(setUserAlteDocumentePhase2Documents({
                altedocumente: [],
                rowCount: 0,
            }))
        }
    }, [selectedUserId])

    useEffect(() => {
        let column = mapColumnNameToEnum(alteDocumentePhase2SortModel.columnToSortBy);
        let sort = false;
        if (alteDocumentePhase2SortModel.sortingOrder === "asc") sort = true;
        if (selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) {
            handleRequestAlteDocuments(selectedUserId, currentPageNumber, column, sort, currentUser?.jwtToken);
        }
    }, [currentPageNumber, isVersion2]);

    const columns: GridColDef[] = [
        { field: "fileTitle", headerName: "Nume document", flex: 1, minWidth: 150, },
        {
            field: "uploadTime",
            headerName: "Data încărcării",
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let newDate = new Date(params.value as string);
                    return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
                }
            },
        },
        {
            field: "fileSize",
            headerName: "Mărime fișier",
            flex: 1,
            minWidth: 150,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let sizeToBeDisplayed = params.value
                        .toString()
                        .substring(0, 4);
                    return sizeToBeDisplayed + " MB";
                }
            },
        },
        {
            field: "uploadedBy",
            headerName: "Încărcat de",
            flex: 1,
            minWidth: 150
        },
        {
            field: "",
            headerName: "Opțiuni",
            sortable: false,
            flex: 1,
            align: "right",
            minWidth: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <Tooltip title="Descarcă Fișier">
                            <Button
                                disabled={userIsDownloadingAlteDocsPhase2File}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        downloadAlteDocumentePhase2Document({ fileId: params.row.fileId, token: currentUser?.jwtToken })
                                    );
                                }}
                            >
                                <DownloadIcon />
                            </Button>
                        </Tooltip>
                        {currentUser?.userType !== UserType.COMPANY_USER && <Tooltip title="Șterge Fișier">
                            <Button
                                disabled={userIsDownloadingAlteDocsPhase2File}
                                color="error"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        deleteAlteDocumentePhase2Document({
                                            docId: params.row.documentId,
                                            token: currentUser?.jwtToken,
                                        })
                                    ).then(() => {
                                        handleRequestAlteDocuments(selectedUserId, currentPageNumber, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);

                                    });

                                }}
                            >
                                <Delete />
                            </Button>
                        </Tooltip>}
                    </ButtonGroup>
                );
            },
        },
    ];

    const handlePageChange = (pageNumber: any) => {
        setCurrentPageNumber(pageNumber);
    }

    const onFilesUpload = (
        files: Array<File>,
        removeAllFilesCallback: Function
    ) => {
        if (files.length > 0) {
            dispatch(
                uploadAlteDocumentePhase2UserDocuments({
                    requestData: {
                        companyUserId: selectedUserId!,
                        files: files,
                        isVersion2: isVersion2
                    },
                    token: currentUser?.jwtToken
                })).then(() => {
                    let column = mapColumnNameToEnum(alteDocumentePhase2SortModel.columnToSortBy);
                    let sort = false;
                    if (alteDocumentePhase2SortModel.sortingOrder === "asc") sort = true;
                    removeAllFilesCallback();
                    handleRequestAlteDocuments(selectedUserId, 0, column, sort, currentUser?.jwtToken);
                    setCurrentPageNumber(0);
                }
                );

        }
    }

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length > 0) {
            dispatch(setAlteDocumentePhase2SortModel({
                columnToSortBy: newModel[0].field,
                sortingOrder: newModel[0].sort
            }));
            let column = mapColumnNameToEnum(newModel[0].field);
            let sort = false;
            if (newModel[0].sort === "asc") sort = true;
            handleRequestAlteDocuments(selectedUserId, currentPageNumber, column, sort, currentUser?.jwtToken);

        } else {
            dispatch(setAlteDocumentePhase2SortModel({
                columnToSortBy: "createdAt",
                sortingOrder: "desc"
            }));
            handleRequestAlteDocuments(selectedUserId, currentPageNumber, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);
        }
    };

    const handleClose = () => {
        setDisplayUploadDialog(false);
    };

    const canAddDoc = currentUser?.userType !== UserType.COMPANY_USER ? ((selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) ? true : false) : true;

    return (
        <Container
            id="anexa2-view"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid container sx={{ backgroundColor: "lightgrey", padding: 1 }}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", marginBottom: 1, alignItems: "center" }}>
                    <Grid container sx={{ justifyContent: "flex-start" }}>
                        <Typography
                            variant="h6"
                            color="white"
                            textAlign="center"
                        >
                            Alte documente
                        </Typography>
                    </Grid>
                    <Grid container sx={{ justifyContent: "flex-end" }}>
                        {
                            !grantApproved && !(currentUser?.userType === UserType.COMPANY_USER) && 
                            <Button disabled={!canAddDoc} variant="contained" startIcon={<Add />} color="success" onClick={() => { setDisplayUploadDialog(true) }}>
                                Adaugă
                            </Button>
                        }
                    </Grid>
                    {
                        displayUploadDialog &&
                        <FileUploadPopout
                            showSize={true}
                            uploadOnButtonCallback={onFilesUpload}
                            uploadDisabled={!canAddDoc}
                            handleClose={handleClose}
                            displayDialog={displayUploadDialog} />
                    }
                </Grid>
                <Grid item xs={12} sx={{
                    backgroundColor: "white"
                }} >
                    <DataGrid
                        components={{
                            LoadingOverlay: DataLoadingComponent
                        }}
                        loading={userAlteDocsPhase2DocumentsListIsLoading}
                        hideFooterPagination={userAlteDocsPhase2DocumentsListIsLoading}
                        rows={userAlteDocumentePhase2Documents}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        paginationMode="server"
                        rowCount={alteDocumentePhase2DocumentsCount}
                        onPageChange={handlePageChange}
                        page={currentPageNumber}
                        hideFooterSelectedRowCount={true}
                        disableSelectionOnClick={true}
                        sx={{
                            "& .MuiDataGrid-row:hover": {
                                background: "aliceblue",
                                cursor: "pointer",
                            },
                            height: 630,
                            boxShadow: 3,

                        }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableColumnMenu={true}
                    ></DataGrid>
                </Grid>
            </Grid>
        </Container>
    )
}