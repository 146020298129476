import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC } from "react";
import { deleteUser, selectDeletingUser, useAppDispatch, useAppSelector, selectDeletingUserId, selectCurrentUser, requestGetAllCompanyUsers } from "store";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { setDeletingUser } from "store/slices/targetGroupSlice";

export const UserDeleteConfirmationDialog: FC<{ onlyRegisteredUsers: boolean }> = ({ onlyRegisteredUsers }) => {
  const dispatch = useAppDispatch();
  const deletingUser = useAppSelector(selectDeletingUser);
  const deletingUserId = useAppSelector(selectDeletingUserId);
  const currentUser = useAppSelector(selectCurrentUser);
  const handleDeleteCancel = () => {
    dispatch(setDeletingUser(false));
  }
  const handleDeleteConfirmation = () => {
    dispatch(deleteUser({
      userId: deletingUserId,
      token: currentUser?.jwtToken
    })).then(() => {
      dispatch(requestGetAllCompanyUsers({
        filter: {
          pageNumber: 0,
          columnToSortBy: DataGridColumnNames.CreatedAt,
          sortingOrder: false,
          searchText: "",
          onlyRegisteredUsers: onlyRegisteredUsers
        },
        token: currentUser?.jwtToken,
      }))
    })
  }


  return (
    <Dialog
      maxWidth="xs"
      open={deletingUser}
    >
      <DialogTitle>Confirmare ștergere</DialogTitle>
      <DialogContent dividers>
        Ești sigur că vrei să ștergi utilizatorul ?
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" autoFocus onClick={handleDeleteCancel}>
          Anulează
        </Button>
        <Button variant="contained" onClick={handleDeleteConfirmation}>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}