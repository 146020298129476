import { FC, useEffect } from "react";
import Switch from '@mui/material/Switch';
import { Divider, Grid, List, ListItem, ListItemText } from "@mui/material";
import { generateRanking, requestGetGeneratedRankings, selectShowPhase2FinallRanking, selectShowPhase2FinallRankingV1, selectShowPhase2IntermediaryRanking, selectShowPhase2IntermediaryRankingV1, selectShowPhase3Ranking, selectShowPhase3RankingV1, useAppDispatch, useAppSelector } from "store";
import { PhaseNumber } from "store/models/enums/PhaseNumber";
import { PhaseType } from "store/models/enums/PhaseType";

export const FormControlComponent: FC<any> = ({ userId, token, position, bottom, width, isVersion2 }) => {
    const dispatch = useAppDispatch();
    const showPhase2IntermediaryRanking = useAppSelector(selectShowPhase2IntermediaryRanking);
    const showPhase2FinallRanking = useAppSelector(selectShowPhase2FinallRanking);
    const showPhase3Ranking = useAppSelector(selectShowPhase3Ranking);


    const showPhase2IntermediaryRankingV1 = useAppSelector(selectShowPhase2IntermediaryRankingV1);
    const showPhase2FinallRankingV1 = useAppSelector(selectShowPhase2FinallRankingV1);
    const showPhase3RankingV1 = useAppSelector(selectShowPhase3RankingV1);


    useEffect(() => {

        dispatch(requestGetGeneratedRankings({
            token: token,
        }));
    }, []);

    const handleChangePhase2IntermediaryV1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(generateRanking({
            filter: {
                generated: event.target.checked,
                generatedByUserId: userId,
                phaseNumber: PhaseNumber.Phase2,
                phaseType: PhaseType.Intermediary,
                isVersion2: false,
            },
            token: token,
        })).then(() => {
            dispatch(requestGetGeneratedRankings({
                token: token,
            }));
        });
    };
    const handleChangePhase2FinallyV1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(generateRanking({
            filter: {
                generated: event.target.checked,
                generatedByUserId: userId,
                phaseNumber: PhaseNumber.Phase2,
                phaseType: PhaseType.Final,
                isVersion2: false,
            },
            token: token,
        })).then(() => {
            dispatch(requestGetGeneratedRankings({
                token: token,
            }));
        });
    };
    const handleChangePhase3FinallyV1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(generateRanking({
            filter: {
                generated: event.target.checked,
                generatedByUserId: userId,
                phaseNumber: PhaseNumber.Phase3,
                phaseType: PhaseType.Final,
                isVersion2: false,
            },
            token: token,
        })).then(() => {
            dispatch(requestGetGeneratedRankings({
                token: token,
            }));
        });
    };

    const handleChangePhase2Intermediary = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(generateRanking({
            filter: {
                generated: event.target.checked,
                generatedByUserId: userId,
                phaseNumber: PhaseNumber.Phase2,
                phaseType: PhaseType.Intermediary,
                isVersion2: true,
            },
            token: token,
        })).then(() => {
            dispatch(requestGetGeneratedRankings({
                token: token,
            }));
        });
    };
    const handleChangePhase2Finally = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(generateRanking({
            filter: {
                generated: event.target.checked,
                generatedByUserId: userId,
                phaseNumber: PhaseNumber.Phase2,
                phaseType: PhaseType.Final,
                isVersion2: true,
            },
            token: token,
        })).then(() => {
            dispatch(requestGetGeneratedRankings({
                token: token,
            }));
        });
    };
    const handleChangePhase3Finally = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(generateRanking({
            filter: {
                generated: event.target.checked,
                generatedByUserId: userId,
                phaseNumber: PhaseNumber.Phase3,
                phaseType: PhaseType.Final,
                isVersion2: true,
            },
            token: token,
        })).then(() => {
            dispatch(requestGetGeneratedRankings({
                token: token,
            }));
        });
    };

    return (

        <List sx={{ position: position, bottom: bottom, width: width }}>
            <Divider sx={{ borderBottomWidth: 1, backgroundColor: "black" }}></Divider>
            <ListItem><ListItemText>Afișează clasament pentru:</ListItemText></ListItem>
            <ListItem>
                <Grid container direction={"row"}>
                    <ListItemText>Faza 2 intermediar v1</ListItemText>
                    <Switch
                        color="primary"
                        checked={showPhase2IntermediaryRankingV1}
                        onChange={handleChangePhase2IntermediaryV1}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
            </ListItem>
            <ListItem>
                <Grid container direction={"row"}>
                    <ListItemText>Faza 2 final v1</ListItemText>
                    <Switch
                        color="primary"
                        checked={showPhase2FinallRankingV1}
                        onChange={handleChangePhase2FinallyV1}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
            </ListItem>
            <ListItem>
                <Grid container direction={"row"}>
                    <ListItemText>Faza 3 v1</ListItemText>
                    <Switch
                        color="primary"
                        checked={showPhase3RankingV1}
                        onChange={handleChangePhase3FinallyV1}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
            </ListItem>

            <ListItem>
                <Grid container direction={"row"}>
                    <ListItemText>Faza 2 intermediar</ListItemText>
                    <Switch
                        color="primary"
                        checked={showPhase2IntermediaryRanking}
                        onChange={handleChangePhase2Intermediary}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
            </ListItem>
            <ListItem>
                <Grid container direction={"row"}>
                    <ListItemText>Faza 2 final</ListItemText>
                    <Switch
                        color="primary"
                        checked={showPhase2FinallRanking}
                        onChange={handleChangePhase2Finally}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
            </ListItem>
            <ListItem>
                <Grid container direction={"row"}>
                    <ListItemText>Faza 3</ListItemText>
                    <Switch
                        color="primary"
                        checked={showPhase3Ranking}
                        onChange={handleChangePhase3Finally}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
            </ListItem>
        </List>
    );

};
