import { FC } from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";

interface FormikTextAreaProps {
    label?: string;
    variant?: "standard" | "filled" | "outlined";
    name: string;
    size?: "medium" | "small";
    apiError?: string | null | undefined;
    id?: string;
    type?: string;
    readOnly?: boolean;
    rows?: number;
}

export const FormikTextArea: FC<FormikTextAreaProps> = (props) => {
    const [field, meta] = useField(props);
    return (
        <TextField
            name={field.name}
            label={props.label}
            fullWidth
            value={field.value ?? ''}
            onChange={field.onChange}
            error={
                meta.touched && (Boolean(meta.error) || Boolean(props.apiError))
            }
            helperText={meta.touched && meta.error}
            multiline
            rows={props.rows ?? 2}
            InputProps={{
                disabled: props.readOnly ?? false,
            }}
        />
    );
};
