import { DeleteForeverRounded, Edit } from "@mui/icons-material";
import { Button, ButtonGroup, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { FileUploadPopout } from "components";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import { FC, useEffect, useState } from "react";
import { extractSchedulesFromDocument, getInterviewSchedules, selectCurrentUser, selectInterviewSchedules, useAppDispatch, useAppSelector, deleteInterviewSchedule, selectDeletingSchedule, selectSchedulesListIsLoading } from "store";
import { UserType } from "store/models/enums/UserType";
import { setScheduleDetails } from "store/slices/grantEvaluationSlice";
import { onlyDateFormat, onlyTimeFormat } from "utils";
import { AddOrEditSchedule } from "./AddOrEditSchedule";

export const InterviewSchedules: FC<any> = ({ isVersion2 }) => {
  const dispatch = useAppDispatch();
  const interviewSchedules = useAppSelector(selectInterviewSchedules);
  const currentUser = useAppSelector(selectCurrentUser);

  const [displayUploadDialog, setDisplayUploadDialog] = useState<boolean>(false);
  const [displayAddDialog, setDisplayAddDialog] = useState<boolean>(false);
  const [isEditingSchedule, setIsEditingSchedule] = useState<boolean>(false);
  const deletingSchedule = useAppSelector(selectDeletingSchedule);
  const schedulesListIsLoading = useAppSelector(selectSchedulesListIsLoading);

  const handleGetInterviewSchedules = (token: string | undefined) => {
    dispatch(getInterviewSchedules({ token: token, isVersion2: isVersion2 }));
  }

  useEffect(() => {
    handleGetInterviewSchedules(currentUser?.jwtToken);

  }, [isVersion2])

  const onFilesUpload = (
    files: Array<File>,
    removeAllFilesCallback: Function
  ) => {
    if (files.length > 0) {
      dispatch(
        extractSchedulesFromDocument({
          files: files,
          token: currentUser?.jwtToken,
          isVersion2: isVersion2,
        })
      ).then(() => {
        removeAllFilesCallback();
        handleGetInterviewSchedules(currentUser?.jwtToken);
      }
      );
    }
  };
  const closeUploadDialog = () => {
    setDisplayUploadDialog(false);
  }

  const closeAddDialog = () => {
    setDisplayAddDialog(false);
  }
  const openAddDialog = () => {
    setDisplayAddDialog(true);
  }

  const handleDeleteInterviewSchedule = (interviewScheduleId: number) => {
    dispatch(deleteInterviewSchedule({ scheduleId: interviewScheduleId, token: currentUser?.jwtToken })).then(() => {
      dispatch(getInterviewSchedules({ token: currentUser?.jwtToken, isVersion2: isVersion2 }))
    })
  }

  const isCompanyUser = () => {
    if (currentUser?.userType === UserType.EXPERT || currentUser?.userType === UserType.ADMIN) return false;
    return true;
  }

  const getLocaleDate = (date: string): string => {
    var newDate = new Date(date);
    return newDate.toLocaleDateString("ro-RO", onlyDateFormat);
  }

  const getLocaleTime = (date: string): string => {
    var newDate = new Date(date);
    return newDate.toLocaleTimeString("ro-RO", onlyTimeFormat)
  }


  const getTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell >Număr înregistrare</TableCell>
          <TableCell align="right">Data</TableCell>
          <TableCell align="right">Ora estimată</TableCell>
          <TableCell align="right">Locația interviului</TableCell>
          {!isCompanyUser() && <TableCell align="right">Opțiuni</TableCell>}
        </TableRow>
      </TableHead>
    )
  }

  const getTableRow = (schedule: any) => {
    return (
      <TableRow key={schedule.id} >
        <TableCell width={120} >{schedule.registrationNumber}/{getLocaleDate(schedule.registrationDate)}</TableCell>
        <TableCell align="right">{getLocaleDate(schedule.scheduleDate)}</TableCell>
        <TableCell align="right">{getLocaleTime(schedule.scheduleTime)}</TableCell>
        <TableCell align="right">{schedule.location}</TableCell>
        {!isCompanyUser() && <TableCell align="right">
          <Grid container justifyContent="flex-end">
            <ButtonGroup
              sx={{
                "& > *:not(:last-child)": {
                  marginRight: .5
                }
              }}
              size="small"
              color="inherit">
              <Tooltip title="Editează">
                <Button variant="contained" color="warning" sx={{ borderRadius: "4px !important" }} onClick={(e) => {
                  dispatch(setScheduleDetails(schedule));
                  setIsEditingSchedule(true);
                  setDisplayAddDialog(true);
                }}><Edit /></Button>
              </Tooltip>
              <Tooltip title="Șterge">
                <Button variant="contained" color="error" sx={{ borderRadius: "4px !important" }} onClick={(e) => {
                  handleDeleteInterviewSchedule(schedule.interviewScheduleId);
                }}><DeleteForeverRounded /></Button>
              </Tooltip>
            </ButtonGroup>
          </Grid>
        </TableCell>}
      </TableRow>
    )
  }

  return (
    <Container sx={{ padding: 2 }}>
      {!isCompanyUser() &&
        <Grid container justifyContent="flex-end" paddingBottom={2}>
          <Button variant="contained" size="medium" color="success" sx={{ marginRight: 2 }}
            onClick={(e) => {
              dispatch(setScheduleDetails(null))
              openAddDialog()
            }}
          >Adaugă programare</Button>
          <Button variant="contained" size="medium" onClick={(e) => {
            setDisplayUploadDialog(true);
          }}>
            Încarcă programări
          </Button>
        </Grid>
      }
      {interviewSchedules.length === 0 ? <Typography padding={2} textAlign="center"  >Niciun beneficiar nu a fost programat pentru interviu.</Typography> :
        < TableContainer component={Paper} >
          {schedulesListIsLoading || deletingSchedule ? <DataLoadingComponent /> : <Table >
            {getTableHeader()}
            <TableBody>
              {
                interviewSchedules.map((schedule: any) => (
                  getTableRow(schedule)
                ))

              }
            </TableBody>
          </Table>}
        </TableContainer>

      }
      {
        displayUploadDialog && <FileUploadPopout displayDialog={displayUploadDialog}
          handleClose={closeUploadDialog}
          fileLimit={1}
          uploadOnButtonCallback={onFilesUpload} acceptedFileformats={[".doc", ".docx"]} />
      }
      {
        displayAddDialog && <AddOrEditSchedule isVersion2={isVersion2} display={displayAddDialog} handleClose={closeAddDialog} isEditing={isEditingSchedule} />
      }
    </Container >
  )
}