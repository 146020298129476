export const homePath = "/";
export const loginPath = "/login";
export const usersPath = "/users";
export const userDetailsPath = "/user/:userId";
export const newUserPath = "/newUser";
export const communicationPath = "/communication";
export const vocationalTrainingPath = "/vocationalTraining";
export const forgotPasswordPath = "/forgotPassword";
export const resetPasswordPath = "/users/resetPassword";
export const grantContestPath = "/planafaceri/:userId";
export const grantContestv1Path = "/planafaceri/v1/:userId";
export const verifyEmailPath = "/users/verifyemail/";
export const manageDocumentsPath = "/manage-documents/:tab/:userId";
