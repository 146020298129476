import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { FileUploadComponent, FormikTextField } from "components";
import { Form, Formik } from "formik"
import { FC, useState, } from "react"
import { requestFinalPhase3EvaluationGrids, selectCurrentUser, uploadFinalPhase3EvaluationGrid, useAppDispatch, useAppSelector } from "store";
import * as yup from "yup";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";

export const UploadPhase3Evaluation: FC<any> = ({ display, handleClose, selectedUserId, isVersion2 }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const [files, setFiles] = useState<Array<File>>([]);

  var initialValues: any = {
    points: null,
    comment: "",
  }

  const validationSchema = yup.object({
    points: yup
      .string()
      .required("Introduceți punctajul.").nullable(true),
  });

  const handleSubmit = (values: any) => {
    dispatch(uploadFinalPhase3EvaluationGrid({
      filter: {
        userId: selectedUserId,
        files: files,
        uploadedByUserId: currentUser?.userId!,
        points: values.points,
        comment: values.comment,
        isVersion2: isVersion2
      },
      token: currentUser?.jwtToken
    })).then(() => {
      handleClose();
      dispatch(requestFinalPhase3EvaluationGrids({
        filter: {
          userId: selectedUserId,
          pageNumber: 0,
          sortingOrder: false,
          columnToSortBy: DataGridColumnNames.UploadTime,
          isVersion2: isVersion2
        },
        token: currentUser?.jwtToken,
      }))
    })
  }


  return (
    <Dialog
      maxWidth="xs"
      open={display}
    >
      <DialogTitle>Grilă evaluare</DialogTitle>
      <Formik enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}>
        {({ values, errors }) => <Form>
          <DialogContent dividers>
            <Grid container direction="column" justifyContent="center" alignItems="center" >
              <Grid marginBottom={3} width={300} >
                <FormikTextField
                  label="Punctaj *"
                  name="points"
                />
              </Grid>
              <Grid marginBottom={3} width={300}>
                <FormikTextField
                  label="Observații"
                  name="comment"
                />
              </Grid>
              <Grid marginBottom={3} width={300}>
                <FileUploadComponent fontSize="1rem" fileLimit={1} acceptedFileformats={[".doc", ".docx"]} onFilesChange={(files: File[]) => {
                  setFiles(files);
                }} />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button variant="contained" color="error" autoFocus onClick={handleClose}>
              Anulează
            </Button>
            <Button variant="contained" color="success" disabled={files?.length === 0} type="submit" >Încarcă</Button>
          </DialogActions>
        </Form>}
      </Formik>
    </Dialog>
  )
}