import { createSlice } from "@reduxjs/toolkit";
import { GrantEvaluationState, ScheduleModel } from "store/models";
import { PhaseNumber } from "store/models/enums/PhaseNumber";
import { PhaseType } from "store/models/enums/PhaseType";
import {
    requestIntermediaryPhase2EvaluationGrids, requestFinalPhase2EvaluationGrids, getScoresForPhase2Intermediary,
    downloadEvaluationGrid, getScoresForPhase2Final, downloadEvaluationTemplateForPhase2, getScoresForPhase3Final,
    downloadEvaluationTemplateForPhase3, requestFinalPhase3EvaluationGrids,
    requestGetGeneratedRankings, getInterviewSchedules, getScheduleDetails, deleteInterviewSchedule
} from "store/thunks/grantEvaluationThunks";


export const grantEvaluationSlice = createSlice({
    name: "grantEvaluationSlice",
    initialState: {
        actions: {},
        phase2TemporarySortModel: { columnToSortBy: "createdAt", sortingOrder: "desc" },
        phase2FinalSortModel: { columnToSortBy: "createdAt", sortingOrder: "desc" },
        userPhase2TemporaryEvaluationGrids: [],
        userPhase2TemporaryEvaluationGridsCount: 0,
        hasAppeal: false,
        scoresPhase2IntermediaryIsLoading: false,
        scoresPhase2Intermediary: [],
        scoresPhase2IntermediaryCount: 0,
        scoresPhase2IntermediarySortModel: { columnToSortBy: "createdAt", sortingOrder: "desc" },
        userPhase2TemporaryEvaluationGridsListIsLoading: false,
        userPhase2TemporaryEvaluationGridsFileIsDownloading: false,

        scoresPhase2FinalIsLoading: false,
        scoresPhase2Final: [],
        scoresPhase2FinalCount: 0,
        scoresPhase2FinalSortModel: { columnToSortBy: "createdAt", sortingOrder: "desc" },

        userPhase2FinalEvaluationGrids: [],
        userPhase2FinalEvaluationGridsCount: 0,
        userPhase2FinalEvaluationGridsListIsLoading: false,
        userPhase2FinalEvaluationGridsFileIsDownloading: false,

        evaluationTemplatePhase2IsDownloading: false,

        scoresPhase3FinalIsLoading: false,
        scoresPhase3Final: [],
        scoresPhase3FinalCount: 0,
        scoresPhase3FinalSortModel: { columnToSortBy: "createdAt", sortingOrder: "desc" },

        userPhase3FinalEvaluationGrids: [],
        userPhase3FinalEvaluationGridsCount: 0,
        userPhase3FinalEvaluationGridsListIsLoading: false,
        userPhase3FinalEvaluationGridsFileIsDownloading: false,
        phase3FinalSortModel: { columnToSortBy: "createdAt", sortingOrder: "desc" },

        evaluationTemplatePhase3IsDownloading: false,
        generatedRankings: [],
        showPhase2IntermediaryRanking: false,
        showPhase2FinallRanking: false,
        showPhase3Ranking: false,
        showPhase2IntermediaryRankingV1: false,
        last19Approved: false,
        last19ApprovedV2: false,
        showPhase2FinallRankingV1: false,
        showPhase3RankingV1: false,
        userIsUploadingAppeal: false,

        interviewSchedules: [],
        displayAddScheduleDialog: false,
        scheduleDetails: {} as ScheduleModel,
        schedulesListIsLoading: false,
        deletingSchedule: false,

        userPhase3EvaluationGridsListIsLoading: false,
        userPhase3EvaluationGridsFileIsDownloading: false,

    } as GrantEvaluationState,
    reducers: {
        setPhase2TemporarySortModel(state, action) {
            state.phase2TemporarySortModel.columnToSortBy = action.payload.columnToSortBy;
            state.phase2TemporarySortModel.sortingOrder = action.payload.sortingOrder;
        },
        setPhase2FinalSortModel(state, action) {
            state.phase2FinalSortModel = action.payload;
        },
        setScoresPhase2IntermediarySortModel(state, action) {
            state.scoresPhase2IntermediarySortModel = action.payload;
        },

        setScoresPhase2FinalSortModel(state, action) {
            state.scoresPhase2FinalSortModel = action.payload;
        },
        setPhase3FinalSortModel(state, action) {
            state.phase3FinalSortModel = action.payload;
        },

        setScoresPhase3FinalSortModel(state, action) {
            state.scoresPhase3FinalSortModel = action.payload;
        },
        setGeneratedRankings(state, action) {
            state.generatedRankings = action.payload.generatedRankings;
        },
        setShowPhase2IntermediaryRanking(state, action) {
            state.showPhase2IntermediaryRanking = action.payload;
        },
        setShowPhase2FinallRanking(state, action) {
            state.showPhase2FinallRanking = action.payload;
        },
        setShowPhase3Ranking(state, action) {
            state.showPhase3Ranking = action.payload;
        },
        setUserIsUploadingAppeal(state, action) {
            state.userIsUploadingAppeal = action.payload;
        },
        setDisplayAddScheduleDialog(state, action) {
            state.displayAddScheduleDialog = action.payload;
        },
        setScheduleDetails(state, action) {
            state.scheduleDetails = action.payload;
        },
        setUserPhase2TemporaryEvaluationGrids(state, action) {
            state.userPhase2TemporaryEvaluationGrids = action.payload;
            state.userPhase2TemporaryEvaluationGridsCount = 0;
        },
        setUserPhase2FinalEvaluationGrids(state, action) {
            state.userPhase2FinalEvaluationGrids = action.payload;
            state.userPhase2FinalEvaluationGridsCount = 0;
        },
        setUserPhase3FinalEvaluationGrids(state, action) {
            state.userPhase3FinalEvaluationGrids = action.payload;
            state.userPhase3FinalEvaluationGridsCount = 0;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(downloadEvaluationTemplateForPhase2.pending, (state, action) => {
            state.evaluationTemplatePhase2IsDownloading = true;
        })
        builder.addCase(downloadEvaluationTemplateForPhase2.fulfilled, (state, action) => {
            state.evaluationTemplatePhase2IsDownloading = true;
        })
        builder.addCase(downloadEvaluationTemplateForPhase2.rejected, (state, action) => {
            state.evaluationTemplatePhase2IsDownloading = true;
        })
        builder.addCase(requestIntermediaryPhase2EvaluationGrids.pending, (state, action: any) => {
            state.userPhase2TemporaryEvaluationGridsListIsLoading = true;

        });
        builder.addCase(requestIntermediaryPhase2EvaluationGrids.fulfilled, (state, action: any) => {
            action.payload.evaluationGrids.forEach((x: any) => {
                x.id = x.grantEvaluationId
            });
            state.userPhase2TemporaryEvaluationGrids = action.payload.evaluationGrids;
            state.userPhase2TemporaryEvaluationGridsCount = action.payload.evaluationGridsCount;
            state.userPhase2TemporaryEvaluationGridsListIsLoading = false;
            state.hasAppeal = action.payload.hasAppeal;

        });
        builder.addCase(requestIntermediaryPhase2EvaluationGrids.rejected, (state, action: any) => {
            state.userPhase2TemporaryEvaluationGridsListIsLoading = false;

        });
        builder.addCase(requestFinalPhase2EvaluationGrids.pending, (state, action: any) => {
            state.userPhase2FinalEvaluationGridsListIsLoading = true;

        });
        builder.addCase(requestFinalPhase2EvaluationGrids.fulfilled, (state, action: any) => {
            action.payload.evaluationGrids.forEach((x: any) => {
                x.id = x.grantEvaluationId
            });
            state.userPhase2FinalEvaluationGrids = action.payload.evaluationGrids;
            state.userPhase2FinalEvaluationGridsCount = action.payload.evaluationGridsCount;
            state.userPhase2FinalEvaluationGridsListIsLoading = false;

        });
        builder.addCase(requestFinalPhase2EvaluationGrids.rejected, (state, action: any) => {
            state.userPhase2FinalEvaluationGridsListIsLoading = false;

        });


        builder.addCase(getScoresForPhase2Intermediary.pending, (state, action) => {
            state.scoresPhase2IntermediaryIsLoading = true;
        });

        builder.addCase(getScoresForPhase2Intermediary.fulfilled, (state, action) => {
            action.payload.scoreBoardUsers.forEach((x: any) => {
                x.id = x.userId
            });
            state.scoresPhase2Intermediary = action.payload.scoreBoardUsers;
            state.scoresPhase2IntermediaryCount = action.payload.scoreBoardUsersCount;
            state.scoresPhase2IntermediaryIsLoading = false;
        })

        builder.addCase(getScoresForPhase2Intermediary.rejected, (state, action) => {
            state.scoresPhase2IntermediaryIsLoading = false;
        });

        builder.addCase(downloadEvaluationGrid.pending, (state, action: any) => {
            state.userPhase2TemporaryEvaluationGridsFileIsDownloading = true;

        });
        builder.addCase(downloadEvaluationGrid.rejected, (state, action: any) => {
            state.userPhase2TemporaryEvaluationGridsFileIsDownloading = false;

        });
        builder.addCase(downloadEvaluationGrid.fulfilled, (state, action: any) => {
            state.userPhase2TemporaryEvaluationGridsFileIsDownloading = false;

        });

        builder.addCase(getScoresForPhase2Final.pending, (state, action) => {
            state.scoresPhase2FinalIsLoading = true;
        });

        builder.addCase(getScoresForPhase2Final.fulfilled, (state, action) => {
            action.payload.scoreBoardUsers.forEach((x: any) => {
                x.id = x.userId
            });
            state.scoresPhase2Final = action.payload.scoreBoardUsers;
            state.scoresPhase2FinalCount = action.payload.scoreBoardUsersCount;
            state.scoresPhase2FinalIsLoading = false;
        })

        builder.addCase(getScoresForPhase2Final.rejected, (state, action) => {
            state.scoresPhase2FinalIsLoading = false;
        });

        builder.addCase(downloadEvaluationTemplateForPhase3.pending, (state, action) => {
            state.evaluationTemplatePhase3IsDownloading = true;
        })
        builder.addCase(downloadEvaluationTemplateForPhase3.fulfilled, (state, action) => {
            state.evaluationTemplatePhase3IsDownloading = true;
        })
        builder.addCase(downloadEvaluationTemplateForPhase3.rejected, (state, action) => {
            state.evaluationTemplatePhase3IsDownloading = true;
        })
        builder.addCase(requestFinalPhase3EvaluationGrids.fulfilled, (state, action: any) => {
            action.payload.evaluationGrids.forEach((x: any) => {
                x.id = x.grantEvaluationId
            });
            state.userPhase3FinalEvaluationGrids = action.payload.evaluationGrids;
            state.userPhase3FinalEvaluationGridsCount = action.payload.evaluationGridsCount;
            state.userPhase3FinalEvaluationGridsListIsLoading = false;

        });
        builder.addCase(requestFinalPhase3EvaluationGrids.rejected, (state, action: any) => {
            state.userPhase3FinalEvaluationGridsListIsLoading = false;

        });

        builder.addCase(getScoresForPhase3Final.pending, (state, action) => {
            state.scoresPhase3FinalIsLoading = true;
        });

        builder.addCase(getScoresForPhase3Final.fulfilled, (state, action) => {
            action.payload.scoreBoardUsers.forEach((x: any) => {
                x.id = x.userId
            });
            state.scoresPhase3Final = action.payload.scoreBoardUsers;
            state.scoresPhase3FinalCount = action.payload.scoreBoardUsersCount;
            state.scoresPhase3FinalIsLoading = false;
        })

        builder.addCase(getScoresForPhase3Final.rejected, (state, action) => {
            state.scoresPhase3FinalIsLoading = false;
        });
        builder.addCase(requestGetGeneratedRankings.fulfilled, (state, action) => {
            action.payload.forEach((x: any) => {
                if (x.phaseNumber === PhaseNumber.Phase2 && x.phaseType === PhaseType.Intermediary && !x.isVersion2) {
                    state.showPhase2IntermediaryRankingV1 = x.generated;
                };

                if (x.phaseNumber === PhaseNumber.Phase2 && x.phaseType === PhaseType.Final && !x.isVersion2) {
                    state.showPhase2FinallRankingV1 = x.generated;
                };

                if (x.phaseNumber === PhaseNumber.Phase3 && x.phaseType === PhaseType.Final && !x.isVersion2) {
                    state.showPhase3RankingV1 = x.generated;
                };
                if (x.phaseNumber === PhaseNumber.Phase2 && x.phaseType === PhaseType.Intermediary && x.isVersion2) {
                    state.showPhase2IntermediaryRanking = x.generated;
                };

                if (x.phaseNumber === PhaseNumber.Phase2 && x.phaseType === PhaseType.Final && x.isVersion2) {
                    state.showPhase2FinallRanking = x.generated;
                };

                if (x.phaseNumber === PhaseNumber.Phase3 && x.phaseType === PhaseType.Final && x.isVersion2) {
                    state.showPhase3Ranking = x.generated;
                };
                if (x.phaseNumber === PhaseNumber.Last19Approved && !x.isVersion2) {
                    state.last19Approved = x.generated;
                }
                if (x.phaseNumber === PhaseNumber.Last19Approved && x.isVersion2) {
                    state.last19ApprovedV2 = x.generated;
                }

            });
        });

        builder.addCase(getInterviewSchedules.pending, (state, action) => {
            state.schedulesListIsLoading = true;
        })
        builder.addCase(getInterviewSchedules.fulfilled, (state, action) => {
            var key = 0;
            action.payload.forEach((schedule: any) => {
                key++
                schedule.id = key;
                state.interviewSchedules.push(schedule);
            })
            state.interviewSchedules = action.payload;
            state.schedulesListIsLoading = false;
        })

        builder.addCase(getInterviewSchedules.rejected, (state, action) => {
            state.schedulesListIsLoading = false;
        })
        builder.addCase(getScheduleDetails.rejected, (state, action) => {

        })
        builder.addCase(getScheduleDetails.pending, (state, action) => {
        })
        builder.addCase(getScheduleDetails.fulfilled, (state, action) => {
            state.scheduleDetails = action.payload;
        })
        builder.addCase(deleteInterviewSchedule.rejected, (state, action) => {
            state.deletingSchedule = false;
        })
        builder.addCase(deleteInterviewSchedule.pending, (state, action) => {
            state.deletingSchedule = true;
        })
        builder.addCase(deleteInterviewSchedule.fulfilled, (state, action) => {
            state.deletingSchedule = false;
        })
    }
});

export const { setScoresPhase2IntermediarySortModel, setPhase2TemporarySortModel, setScoresPhase2FinalSortModel, setPhase2FinalSortModel,
    setScoresPhase3FinalSortModel, setPhase3FinalSortModel, setShowPhase2IntermediaryRanking, setShowPhase2FinallRanking, setShowPhase3Ranking,
    setUserIsUploadingAppeal, setScheduleDetails, setDisplayAddScheduleDialog, setUserPhase2TemporaryEvaluationGrids, setUserPhase2FinalEvaluationGrids, setUserPhase3FinalEvaluationGrids } = grantEvaluationSlice.actions;