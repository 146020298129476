import { Close } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@mui/material";
import { FC, useState } from "react";

export const ValidationNoteDialog: FC<{ onHide: any, handleConfirm: any, node: any, isCompanyUser: any, textRequired: any, anexaNr: any, display: any }> = ({ onHide, handleConfirm, node, isCompanyUser, textRequired, anexaNr, display }) => {
  const [text, setText] = useState<string>("");
  const [date, setDate] = useState<any>("");

  const onDateChange = (date: any) => {
    setDate(date);
  }

  const onTextChange = (text: string) => {
    setText(text);
  }

  return (
    <Dialog
      open={display}
      onClose={() => onHide()}

      disableScrollLock
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        Generează notă validare
        <IconButton onClick={() => onHide()}><Close /></IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }} >
        <Grid  >
          <Grid item sx={{ padding: 1, width: 200, }} >
            <TextField value={text} sx={{ width: "100%", resize: "none", marginBottom: 2 }} variant="outlined" label="Nr. înregistrare" onChange={(e) => onTextChange(e.target.value)} />
          </Grid>
          <Grid item sx={{ padding: 1, width: 200 }}>
            <DatePicker value={date} label="Dată înregistrare" mask="__.__.____" onChange={(e: any) => onDateChange(e)} renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                error={false}
                value={date || ""}
              />
            )} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus size="small" onClick={() => onHide()}>
          Anulează
        </Button>
        {<Button variant="contained" color="success" size="small" onClick={() => handleConfirm({ node: node, date: date.toISOString(), text: text, anexaNr: anexaNr })} autoFocus>
          Generează
        </Button>}
      </DialogActions>
    </Dialog>
  )
}