import { Autocomplete, TextField } from "@mui/material";
import { FC } from "react";



const AutocompleteSearch: FC<any> = (props) => {


  return (
    <Autocomplete
      size="small"
      sx={{
        width: props?.width
      }}
      id={props?.id}
      clearIcon={false}
      forcePopupIcon={false}
      disableCloseOnSelect
      options={props?.options}
      value={props?.value}
      noOptionsText="Fără rezultate"
      getOptionLabel={props?.getOptionLabel}
      renderInput={(params) => <TextField {...params}
        variant="outlined"
      />}
      onInputChange={props?.onInputChange}
      renderOption={props?.renderOption}
      onChange={props?.onType}
      isOptionEqualToValue={props?.isOptionEqualToValue}
      onKeyPress={props?.onKeyPress}
      open={props?.open}
      onOpen={props?.onOpen}
      onClose={props?.onClose}
      clearOnBlur={false}
    />
  )

}

export default AutocompleteSearch;