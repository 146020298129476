import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Grid, Typography, Container, InputAdornment, IconButton, TextField } from "@mui/material";
import { Field, FieldProps, Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { requestUserEmailByVerificationToken, requestVerifyToken, selectUserEmail, selectVerifyEmailSuccess, useAppDispatch, useAppSelector } from "store";
import { FormikTextField } from "../formikInputs/FormikTextField";
import * as yup from "yup";
import { loginPath } from "navigation";

const ConfigurePassword: FC<{ token: string | null }> = ({ token }) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userEmail = useAppSelector(selectUserEmail);
  const verifyEmailSuccess = useAppSelector(selectVerifyEmailSuccess);
  const [showPassword, setShowPassword] = useState<boolean>(false);


  useEffect(() => {
    if (token === null || token === undefined || token === "") {
      navigate(loginPath);
    }
    dispatch(requestUserEmailByVerificationToken(token)).then(response => {
      if (response.payload === null) {
        navigate(loginPath);
      }
    });
  }, []);

  useEffect(() => {
    if (verifyEmailSuccess) {
      navigate(loginPath);
    }
  }, [verifyEmailSuccess])

  var initialValues: any = {
    email: userEmail,
    password: "",
    confirmPassword: "",
  }
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Introduceți email valid")
      .required("Email obligatoriu").nullable(true),
    password: yup.string().nullable(true).when('userId', {
      is: (val: number | null | undefined) => val === undefined || val === null,
      then: (schema: any) => schema.required("Parola este obligatorie")
    }),
    confirmPassword: yup.string().nullable(true).when('userId', {
      is: (val: number | null | undefined) => val === undefined || val === null,
      then: (schema: any) => schema.oneOf([yup.ref<any>("password")], "Parolele nu sunt identice!")
        .required("Introduceti parola din nou!"),
    })
  });

  return (
    <Container
      id="configure-password-page"
      maxWidth="lg"
      sx={{ minHeight: "inherit" }}
      disableGutters
    >
      <Grid
        container
        sx={{
          minHeight: "inherit",
          p: {
            xs: 1,
          },

        }}
        justifyContent="center"
        alignItems="center"
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => {
            dispatch(requestVerifyToken({
              token: token,
              password: values.password,
              confirmPassword: values.confirmPassword,
            })
            );
          }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ initialValues }) =>
            <Form style={{ height: "100%" }}>
              <Grid
                padding={2}

              >
                <Grid marginBottom={10}>
                  <Typography variant='h4' textAlign="center">
                    Verifică email și configurează parola
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid marginBottom={3} sx={{ minWidth: "60%" }} >
                    <FormikTextField
                      id="email"
                      name="email"
                      label="E-mail"
                      readOnly={true}
                    />
                  </Grid>
                  <Grid marginBottom={3} sx={{ minWidth: "60%" }}>
                    <Field id="password" name="password">
                      {({
                        field,
                        meta,
                      }: FieldProps<string, any>) => (
                        <TextField
                          name={field.name}
                          type={
                            showPassword
                              ? "text"
                              : "password"
                          }
                          fullWidth
                          label="Parolă"
                          value={field.value ?? ''}
                          onChange={field.onChange}
                          error={
                            meta.touched &&
                            Boolean(meta.error)
                          }
                          helperText={
                            meta.touched && meta.error
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowPassword(
                                      !showPassword
                                    )
                                  }
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid marginBottom={3} sx={{ minWidth: "60%" }}>
                    <Field
                      id="confirmPassword"
                      name="confirmPassword"
                    >
                      {({
                        field,
                        meta,
                      }: FieldProps<string, any>) => (
                        <TextField
                          name={field.name}
                          type={
                            showPassword
                              ? "text"
                              : "password"
                          }
                          fullWidth
                          label="Confirmă parola"
                          value={field.value ?? ''}
                          onChange={field.onChange}
                          error={
                            meta.touched &&
                            Boolean(meta.error)
                          }
                          helperText={
                            meta.touched && meta.error
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowPassword(
                                      !showPassword
                                    )
                                  }
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid marginBottom={3}>
                    <Button type="submit" variant="contained">
                      Salvează
                    </Button>
                  </Grid>
                </Grid>


              </Grid>
            </Form>}
        </Formik>
      </Grid>
    </Container >
  )
}

export default ConfigurePassword;