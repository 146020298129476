import { Button, Dialog, DialogActions, Grid, Switch, DialogContent, IconButton, DialogTitle } from "@mui/material";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { FC, useEffect, useState } from "react";
import { isNotNullOrUndefined } from "utils";
import { Fieldset } from "primereact/fieldset";
import { DataView } from "primereact/dataview";
import { useAppDispatch } from "store";
import { FileUploadComponent } from "components";
import { Close } from "@mui/icons-material";
export const ManageDocumentFiles: FC<{ requiredWarningText: any, requiredCondition: any, isRequired: boolean, display: boolean, displayMode: string, currentFiles: any, newFiles: any, onFileDownload: Function, onRemoveCurrentFile: Function, onCancelRemoveCurrentFile: Function, onRemoveNewFile: Function, onNewFilesSelect: Function, onSave: Function, onCancel: Function }> = ({ onCancel, onSave, onFileDownload, currentFiles, newFiles, onNewFilesSelect, onRemoveCurrentFile, onCancelRemoveCurrentFile, onRemoveNewFile, displayMode, display, isRequired, requiredCondition, requiredWarningText }) => {
  const [initialCurrentFiles, setInitialCurrentFiles] = useState<any>(currentFiles);
  const [initialNewFiles, setInitialNewFiles] = useState<any>(newFiles);
  const [mergedInitialFiles, setMergedInitialFiles] = useState<any>([]);
  const [initialNewFilesRemoved, setInitialNewFilesRemoved] = useState<any>([]);
  const [newLocalFiles, setNewLocalFiles] = useState<any>();

  const dispatch = useAppDispatch();
  useEffect(() => {
    mergeInitialFilesList();
    return () => {
      setInitialCurrentFiles([]);
      setInitialNewFiles([]);
      setMergedInitialFiles([]);
    }
  }, [])

  const removeInitialNewFile = (fileData: any) => {
    setInitialNewFilesRemoved([...initialNewFilesRemoved, fileData])
  }
  const cancelRemoveInitialNewFile = (fileData: any) => {
    setInitialNewFilesRemoved(initialNewFilesRemoved.filter((file: any) => fileData.name !== file.name));
  }
  const mergeInitialFilesList = () => {
    setMergedInitialFiles([]);
    let newMergedInitialFilesList: any[] = [];
    initialCurrentFiles.forEach((element: any) => {
      newMergedInitialFilesList.push(element);
    })
    initialNewFiles.forEach((element: any) => {
      newMergedInitialFilesList.push(element);
    })
    setMergedInitialFiles(newMergedInitialFilesList);

  }

  const handleDownload = (fileData: any) => {
    onFileDownload(fileData.fileId);
  }


  const removeCurrentFile = (fileData: any) => {
    setMergedInitialFiles(mergedInitialFiles.map((file: any) => {
      if (file.fileId === fileData.fileId) {
        return {
          ...file,
          willBeRemoved: true
        }
      }
      return file;
    }))
    onRemoveCurrentFile(fileData);
  }

  const cancelRemoveCurrentFile = (fileData: any) => {
    setMergedInitialFiles(mergedInitialFiles.map((file: any) => {
      if (file.fileId === fileData.fileId) {
        return {
          ...file,
          willBeRemoved: false
        }
      }
      return file;
    }))
    onCancelRemoveCurrentFile(fileData);
  }
  const getFileTemplate = (item: any): any => {
    if (isNotNullOrUndefined(item.fileTitle)) {
      return (
        <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 1 }}>
          <span>{item.fileTitle}</span>
          <Grid sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}>
            <Button variant="contained" onClick={() => handleDownload(item)}>Descarcă</Button>
            {
              displayMode === "edit" && (
                <Grid sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                  <Switch
                    checked={isNotNullOrUndefined(item.willBeRemoved) && item.willBeRemoved}
                    onChange={(e) => {
                      if (e.target.value) {
                        removeCurrentFile(item);
                      } else {
                        cancelRemoveCurrentFile(item);
                      }
                    }}></Switch>
                  <span>Șterge</span>
                </Grid>
              )
            }
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 1 }}>
          <span>{item.name}</span>
          <Grid sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}>
            <Button disabled variant="contained" size="small" color="warning" onClick={() => handleDownload(item)}>Nesalvat</Button>
            <Grid sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <Switch
                sx={{ marginRight: 1 }}
                checked={initialNewFilesRemoved.some((file: any) => file.name === item.name)}
                onChange={(e) => {
                  if (e.target.checked) {
                    removeInitialNewFile(item);
                  } else {

                    cancelRemoveInitialNewFile(item);
                  }
                }}></Switch>
              <span>Șterge</span>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Dialog open={display} fullWidth >
      {displayMode === "view" &&
        <DialogTitle sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={() => onCancel(initialCurrentFiles, initialNewFiles)}><Close /></IconButton>
        </DialogTitle>
      }

      <DialogContent >
        {mergedInitialFiles.length !== 0 &&
          <Fieldset legend="Fisiere existente">
            <DataView
              value={mergedInitialFiles}
              itemTemplate={getFileTemplate}
              layout="list"
            >
            </DataView>
          </Fieldset>
        }
        {displayMode !== "view" &&
          <Fieldset legend="Fisiere noi">
            <FileUploadComponent onFilesChange={(files: File[]) => {
              if (files.length > 0) {
                onNewFilesSelect(files);
                setNewLocalFiles(files);
              }

            }} />
          </Fieldset>
        }
      </DialogContent>
      {displayMode !== "view" && <DialogActions>
        <Button variant="contained" autoFocus size="small" onClick={() => onCancel(initialCurrentFiles, initialNewFiles)}>
          Anulare
        </Button>
        <Button variant="contained" color="success" size="small" onClick={() => {
          if (isRequired && !requiredCondition(newLocalFiles, mergedInitialFiles, initialNewFilesRemoved)) {
            dispatch(addAppNotification({ message: requiredWarningText, severity: "warning" }
            ))
            return;
          }
          onSave(initialNewFilesRemoved);
        }} autoFocus>
          Salvare
        </Button>
      </DialogActions>}
    </Dialog>
  )
}