import { FC } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useField } from "formik";

interface FormikCheckBoxProps {
    label?: string;
    variant?: "standard" | "filled" | "outlined";
    name: string;
    size?: "medium" | "small";
    apiError?: string | null | undefined;
    id?: string;
    disabled?: boolean;
}

export const FormikCheckBox: FC<FormikCheckBoxProps> = (props) => {
    const [field, meta, helpers] = useField(props);
    return (
        <FormControlLabel control={<Checkbox checked={field.value} style={{ paddingLeft: '15px' }} disabled={props.disabled}
            onChange={(e) => { helpers.setValue(e.target.checked); }} />} label={props.label ?? ""} />
    );
};